import { AuthenticationClient, ITranslationLookups, UserModel } from 'api';
import AuthorizedButton from 'components/inputs/AuthorizedButton';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import Table from 'components/Table';
import { ColumnSetting, makeColumnClassName } from 'components/Table/utils';
import useTranslations from 'contexts/basicData/useTranslations';
import useUsers, { useRefreshUsersCall } from 'contexts/basicData/useUsers';
import { FC, Suspense, useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import UserFormModal from '../UserFormModal';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { booleanCompare, makeBooleanComparator } from 'utils/sorting';
import { formatDateTime } from 'utils/date-helpers';
import Checkbox from 'components/inputs/Checkbox';
import { useLocalStorage } from 'usehooks-ts';
import Input from 'components/inputs/Input';
import { getUserPropsIconArray, userPropsArray } from '../filterableUserFields';

const Wrapper = styled.div``;

const EmojiWrapper = styled.div<{ amount: number }>`
  ${({ amount }) => css`
    display: grid;
    grid-template-columns: repeat(${amount}, 1fr);
    grid-auto-flow: row;
  `}
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

const EnvelopeButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  line-height: 12px;
  width: 100%;

  .fa-icon {
    padding-right: 6px;
  }

  .fa-icon:hover {
    color: ${({ theme }) => theme.colors.foreground.tint};
  }
`;

const MyCheckbox = styled(Checkbox)`
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

const MyTable = styled(Table)`
  .${makeColumnClassName(0)} {
    padding-left: 10px;
  }
` as typeof Table;

const EmployeesPage: FC = () => {
  const translations = useTranslations();
  const users = useUsers();
  const refreshUsersCall = useRefreshUsersCall();
  const [showAddUser, setShowAddUser] = useState(false);
  const [editUser, setEditUser] = useState<UserModel | null>(null);
  const [sentMailTo, setSentMailTo] = useState<number[]>([]);
  const [hideInactive, setHideInactive] = useLocalStorage<boolean>(
    'hide-inactive-users',
    true
  );
  const [search, setSearch] = useState<string>('');

  const toggle = useRef(true);

  const resendCall = useApiCall(AuthenticationClient, (c, id: number) =>
    c.resendConfirmationEmail(id)
  );

  const handleUsersUpdated = useCallback(() => {
    setShowAddUser(false);
    setEditUser(null);
    refreshUsersCall.run();
  }, [refreshUsersCall]);

  const handleResend = useCallback(
    async (id: number) => {
      if (!sentMailTo.includes(id)) {
        const [response, error] = await resendCall.run(id);
        if (response && !error) {
          setSentMailTo([...sentMailTo, id]);
        }
      }
      toggle.current = true;
    },
    [resendCall, sentMailTo]
  );

  const columnSettings: ColumnSetting<UserModel, ITranslationLookups>[] =
    useMemo(
      () => [
        {
          head: 'Namn',
          render: (usr) => usr.name,
          width: 250,
          sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
        },
        {
          head: 'Telefon',
          render: (usr) => usr.phoneNumber,
          width: 100,
          sortFunction: (a, b) => (a.phoneNumber > b.phoneNumber ? 1 : -1),
        },
        {
          head: 'E-post',
          render: (usr) => usr.email,
          width: 250,
          sortFunction: (a, b) => (a.email > b.email ? 1 : -1),
        },
        {
          head: 'Bjud in igen',
          render: (usr) =>
            !usr.emailConfirmed && !sentMailTo.includes(usr.userID) ? (
              <EnvelopeButton type="submit">
                <FontAwesomeIcon
                  className="fa-icon"
                  icon={faEnvelope}
                  onClick={() => {
                    toggle.current = false;
                    handleResend(usr.userID);
                  }}
                  style={{ zIndex: 1000 }}
                />
              </EnvelopeButton>
            ) : (
              ''
            ),
          width: 40,
        },
        {
          head: 'Roller',
          render: (usr, transl) =>
            usr.roles.map((r) => transl.rolesById[r]).join(', '),
          width: 120,
          sortFunction: (a, b) => a.roles.length - b.roles.length,
        },
        {
          head: 'Symboler',
          render: (usr) => (
            <EmojiWrapper amount={Object.keys(userPropsArray).length}>
              {getUserPropsIconArray(usr).map((e) => (
                <div aria-label={e.title} key={e.title} title={e.title}>
                  {e.icon}
                </div>
              ))}
            </EmojiWrapper>
          ),
          width: 100,
        },
        {
          head: 'Autogodkänn uppdrag',
          render: (usr) => (usr.autoApproveAssignment ? 'Ja' : 'Nej'),
          width: 180,
          sortFunction: (a, b) =>
            a.autoApproveAssignment === b.autoApproveAssignment
              ? 0
              : a.autoApproveAssignment
                ? -1
                : 1,
        },
        {
          head: 'Visa i fakturaunderlag',
          render: (usr) => (usr.showInInvoicing ? 'Ja' : 'Nej'),
          width: 180,
          sortFunction: makeBooleanComparator((usr) => usr.showInInvoicing),
        },
        {
          head: 'App-version',
          render: (usr) => usr.currentAppVersion,
          width: 100,
          sortFunction: (a, b) => {
            return `${a.currentAppVersion}`.localeCompare(
              `${b.currentAppVersion}`
            );
          },
        },
        {
          head: 'Senast inloggad',
          render: (usr) => formatDateTime(usr.lastSeen),
          width: 120,
          sortFunction: (a, b) => {
            return (a.lastSeen?.getTime() ?? 0) - (b.lastSeen?.getTime() ?? 0);
          },
        },
        {
          head: 'Betatestare',
          render: (usr) => (usr.isBetaTester ? 'Ja' : 'Nej'),
          width: 40,
          sortFunction: (a, b) =>
            booleanCompare(!!a.isBetaTester, !!b.isBetaTester),
        },
      ],
      [handleResend, sentMailTo]
    );

  const renderContent = useCallback(() => {
    switch (refreshUsersCall.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetched:
      case RequestStatus.Error: {
        let rows = Object.values(users);
        if (hideInactive) {
          rows = rows.filter((r) => r.isActive);
        }
        if (search.length > 0) {
          rows = rows.filter(
            (r) =>
              r.name?.toLowerCase().includes(search.toLowerCase()) ||
              r.email?.toLowerCase().includes(search.toLowerCase()) ||
              r.phoneNumber?.toLowerCase().includes(search.toLowerCase()) ||
              r.employmentID?.toLowerCase().includes(search.toLowerCase())
          );
        }
        return (
          <MyTable
            columnSettings={columnSettings}
            fluid
            onRowClick={(u) => setEditUser(u)}
            renderProps={translations}
            rows={rows}
            useColumnWidthAsFlexAmount={
              // when device width > 768
              window.innerWidth > 768
            }
          />
        );
      }
      case RequestStatus.Fetching:
      default:
        return <LoadingSpinner>Hämtar...</LoadingSpinner>;
    }
  }, [
    columnSettings,
    hideInactive,
    refreshUsersCall.status,
    search,
    translations,
    users,
  ]);

  return (
    <Suspense fallback="Laddar...">
      <Wrapper>
        <Actions>
          <MyCheckbox
            checked={hideInactive}
            onChange={(event) => setHideInactive(event.currentTarget.checked)}
          >
            Dölj inaktiva
          </MyCheckbox>
          <AuthorizedButton
            disabled={refreshUsersCall.status === RequestStatus.Fetching}
            onClick={() => setShowAddUser(true)}
          >
            + Ny Användare
          </AuthorizedButton>

          <Input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Filtrera på namn, anställningsnr, e-post eller telefonnummer"
            value={search}
            width={300}
          />
        </Actions>

        {showAddUser && toggle.current && (
          <UserFormModal
            canEdit
            onCancel={() => setShowAddUser(false)}
            onSaved={handleUsersUpdated}
          />
        )}
        {editUser && toggle.current && (
          <UserFormModal
            canEdit
            onCancel={() => setEditUser(null)}
            onSaved={handleUsersUpdated}
            user={editUser}
          />
        )}

        {renderContent()}
      </Wrapper>
    </Suspense>
  );
};

export default EmployeesPage;
