import { ITranslationLookups } from 'api';

import Button from 'components/inputs/Button';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import Table from 'components/Table';
import { ColumnSetting, makeColumnClassName } from 'components/Table/utils';
import useTranslations from 'contexts/basicData/useTranslations';
import useModalStack from 'contexts/modal/useModalStack';
import { FC } from 'react';
import styled from 'styled-components';
import BusinessUnitFormModal from './BusinessUnitFormModal';
import { useBusinessUnitGetBusinessUnitGroups } from 'api/business-unit/business-unit';
import BusinessUnitGroupFormModal from './BusinessUnitGroupFormModal';
import { BusinessUnitModel } from 'api/model';

const Wrapper = styled.div``;

const GroupTitle = styled.h2`
  font-size: 1.4rem;
  padding: 5px;
  padding-top: 20px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

const MyTable = styled(Table)`
  .${makeColumnClassName(0)} {
    padding-left: 10px;
  }
` as typeof Table;

const BusinessUnits: FC = () => {
  const modalStack = useModalStack();
  const translations = useTranslations();

  const { status, data: businessUnitGroups } =
    useBusinessUnitGetBusinessUnitGroups();

  const columnSettings: ColumnSetting<
    BusinessUnitModel,
    ITranslationLookups
  >[] = [
    {
      head: 'Namn',
      render: (z) => z.name,
      width: 150,
      sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
    },
    {
      head: 'Beskrivning',
      render: (z) => z.description ?? '',
      width: 150,
    },
  ];
  const closeModalAndRefresh = () => {
    modalStack.pop();
  };

  const renderContent = () => {
    switch (status) {
      case 'success':
      case 'error': {
        return businessUnitGroups?.map((group) => (
          <>
            <GroupTitle>{group.name}</GroupTitle>
            <MyTable
              columnSettings={columnSettings}
              onRowClick={(r) => {
                modalStack.push(
                  <BusinessUnitFormModal
                    canEdit
                    onCancel={closeModalAndRefresh}
                    onSaved={closeModalAndRefresh}
                    unit={r}
                  />
                );
              }}
              renderProps={translations}
              rows={group.businessUnits ?? []}
              useColumnWidthAsFlexAmount
            />
          </>
        ));
      }
      case 'loading':
      default:
        return <LoadingSpinner>Hämtar...</LoadingSpinner>;
    }
  };

  const handleAddUnit = () => {
    modalStack.push(
      <BusinessUnitFormModal
        canEdit
        onCancel={closeModalAndRefresh}
        onSaved={closeModalAndRefresh}
      />
    );
  };

  const handleAddGoup = () => {
    console.log('HELLOOO');

    modalStack.push(
      <BusinessUnitGroupFormModal
        canEdit
        onCancel={closeModalAndRefresh}
        onSaved={closeModalAndRefresh}
      />
    );
  };

  return (
    <Wrapper>
      <Actions>
        <Button disabled={status === 'loading'} onClick={handleAddGoup}>
          + Ny grupp
        </Button>
        <Button disabled={status === 'loading'} onClick={handleAddUnit}>
          + Ny enhet
        </Button>
      </Actions>
      {renderContent()}
    </Wrapper>
  );
};

export default BusinessUnits;
