/* eslint-disable no-bitwise */
/* eslint-disable import/prefer-default-export */
import { AssignmentModel } from 'api/model';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';

export const formatTime = (date: Date | undefined, seconds = false): string => {
  if (date) {
    const hString = date.getHours().toString().padStart(2, '0');
    const mString = date.getMinutes().toString().padStart(2, '0');
    const sString = date.getSeconds().toString().padStart(2, '0');

    if (seconds) {
      return `${hString}:${mString}:${sString}`;
    }
    return `${hString}:${mString}`;
  }

  return ' ';
};

/**
 * Converts timestringsof type 08:00 AM, 1:30 PM to 08:00, 13:30
 * @param timeString
 * @returns
 */
export const convertAMPMTimeStringTo24HTimeString = (timeString: string) => {
  // remove any non digit characters that is not a :
  const originalTime = timeString.replace(/[^0-9:]/g, '');
  const date = new Date(`1970-01-01 ${originalTime}`);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const formatDate = (date: Date, excludeYear?: boolean): string => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const dateStr = date.getDate().toString().padStart(2, '0');

  if (excludeYear) return `${month}-${dateStr}`;

  return `${year}-${month}-${dateStr}`;
};

export const formatMonth = (date: Date): string => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  return `${year}-${month}`;
};

export const formatYear = (date: Date): string => {
  return formatDate(date).slice(0, 4);
};

export const formatDateTime = (date?: Date, seconds = false): string => {
  return `${date ? formatDate(date) : '-'} ${formatTime(date, seconds)}`;
};

export const calculateTimeBetweenRoundedToCeilQuarter = (
  start: Date,
  end: Date
): string => {
  const num = Number(((end.valueOf() - start.valueOf()) / 3600000).toFixed(2));
  const hours = Math.floor(num);

  const minutes = num - hours;
  const m = ((((minutes + 7.5) / 15) | 0) * 15) % 60;
  const h = (((minutes / 105 + 0.5) | 0) + hours) % 24;

  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
};

export const getHoursAndMinutesFromMilliseconds = (
  milliseconds: number
): [hours: number, minutes: number] => {
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  return [hours, minutes];
};

export const getHoursAndMinutesFromMillisecondsString = (
  milliseconds: number
): string => {
  const [hours, minutes] = getHoursAndMinutesFromMilliseconds(milliseconds);
  return `${hours}h ${minutes}m`;
};

export const getHoursAndMinutesFromMinutesString = (
  minutes: number
): string => {
  return getHoursAndMinutesFromMillisecondsString(minutes * 60 * 1000);
};

export const toInputDateString = (d: Date) => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();

  return `${year.toString().padStart(4, '0')}-${month
    .toString()
    .padStart(2, '0')}-${date.toString().padStart(2, '0')}`;
};

export const toInputDateTimeString = (d: Date) => {
  try {
    const hour = d.getHours().toString().padStart(2, '0');
    const minute = d.getMinutes().toString().padStart(2, '0');
    return `${toInputDateString(d)}T${hour}:${minute}`;
  } catch (error) {
    return '';
  }
};

export const toInputTimeString = (d: Date) => {
  const hour = d.getHours().toString().padStart(2, '0');
  const minute = d.getMinutes().toString().padStart(2, '0');
  return `${hour}:${minute}`;
};

export const getDaysAgo = (numberOfDays: number) => {
  const theDate = new Date(Date.now());
  theDate.setDate(theDate.getDate() - numberOfDays);
  return theDate;
};

export const isSameDay = (a: Date, b: Date) =>
  a.getFullYear() === b.getFullYear() &&
  a.getMonth() === b.getMonth() &&
  a.getDate() === b.getDate();

export const isLessThanXSecondsAgo = (date: Date, seconds: number) => {
  const milliseconds = seconds * 1000;
  const now = new Date();
  const isLess = now.getTime() - date.getTime() <= milliseconds;
  return isLess;
};

export const toDateFromHHmm = (timeString?: string) => {
  if (!timeString) return undefined;
  const [hours, minutes] = timeString.split(':').map((n) => parseInt(n, 10));
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
};

export const setSwedishTimeZone = (date: Date) => {
  const localDate = moment(date);
  const timeZoneDate = localDate.tz('Europe/Berlin');
  return timeZoneDate.toDate();
};

/**
 * replaces all non-numeric characters with empty string
 * formats the result to YYYY, YYYY-, YYYY-MM, YYYY-MM-, or YYYY-MM-DD depending on how many characters are in the string
 * @param date string
 */
export const convertToDateOfBirthString = (date: string) => {
  const endsWithDash = date.endsWith('-');
  const cleanedDate = date.replace(/\D/g, '');
  if (cleanedDate.length > 4) {
    const year = cleanedDate.slice(0, 4);
    const month = cleanedDate.slice(4, 6);
    if (cleanedDate.length > 6) {
      const day = cleanedDate.slice(6, 8);
      return `${year}-${month}-${day}`;
    }
    return `${year}-${month}${endsWithDash ? '-' : ''}`;
  }
  return `${cleanedDate}${endsWithDash ? '-' : ''}`;
};

/**
 * Validates that the date in the format YYYY-MM-DD is a valid date
 */
export const isValidDateString = (date: string) => {
  const [year, month, day] = date.split('-').map((n) => parseInt(n, 10));
  const d = new Date(year, month - 1, day);
  return (
    d.getFullYear() === year &&
    d.getMonth() + 1 === month &&
    d.getDate() === day &&
    date.length === 10
  );
};

/**
 * Adjusts date to noon
 */
export const adjustDateToNoon = (date: Date | null) => {
  if (!date) return null;
  const newDate = new Date(date);
  newDate.setHours(12, 0, 0, 0);
  return newDate;
};

/*
 * Removes milliseconds from date
 * */
export const removeMilliseconds = (date: Date, millis: number) => {
  if (!date) return undefined;
  if (Number.isNaN(millis)) return undefined;
  const newDate = new Date(date);
  return new Date(newDate.getTime() - millis);
};

export const getStartOfDay = (date: Date): Date => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const getEndOfDay = (date: Date): Date => {
  const d = new Date(date);
  d.setHours(23, 59);
  return d;
};

// return start and end of week (monday is start of week, sunday is end of week)
export const getStartAndEndOfWeek = (date: Date) => {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  const start = new Date(d.setDate(diff));
  const end = new Date(d.setDate(diff + 6));
  return [start, end];
};

// return number of days between two dates
export const getDaysBetween = (date1: Date, date2: Date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  return Math.round(Math.abs((date1.getTime() - date2.getTime()) / oneDay));
};

export const getHoursAndMinutesBetween = (date1: Date, date2: Date) => {
  const milliseconds = Math.abs(date1.getTime() - date2.getTime());
  const [hours, minutes] = getHoursAndMinutesFromMilliseconds(milliseconds);
  return `${hours}h ${minutes}m`;
};

export const getTimeFromNowInMillis = (date?: Date): number | undefined => {
  if (!date) return undefined;
  return Date.now() - date.getTime();
};

export const formatEstimatedStartAndEnd = (assignment: AssignmentModel) => {
  const { estimatedStartTime, estimatedDuration } = assignment;
  const startTime =
    typeof estimatedStartTime === 'string'
      ? new Date(estimatedStartTime)
      : estimatedStartTime;
  const duration =
    typeof estimatedDuration === 'string'
      ? parseInt(estimatedDuration, 10)
      : estimatedDuration;

  if (!startTime || !duration) return '';
  const start = startTime !== null ? formatDateTime(startTime) : '-';
  const end =
    startTime && duration
      ? formatDateTime(new Date(startTime.getTime() + duration))
      : '';
  return `${start} - ${end}`;
};

export const dayNames = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];
export const getDayName = (date: Date) => dayNames[date.getDay()];
