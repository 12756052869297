const orange = 'rgb(204,102,51)';
const orangePale = 'rgba(204,102,51,0.8)';
const white = 'rgb(255,255,255)';
export const black = 'rgb(0,0,0)';
export const lightGrey = 'rgb(235,235,235)';
const darkGrey = 'rgb(190,190,190)';
export const green = 'rgb(0, 187, 0)';
export const oceanBlue = 'rgb(0, 122, 255)';
export const lightGreen = 'rgba(0, 187, 0, 0.4)';
export const red = 'rgb(255, 0, 0)';
export const lightRed = 'rgba(255, 0, 0, 0.4)';

export type DeepReadonly<T> = {
  [P in keyof T]: T[P] extends Record<string, unknown>
    ? T[P] extends Date
      ? T[P]
      : DeepReadonly<T[P]>
    : T[P];
};

export interface ThemeColors {
  background: {
    primary: string;
    secondary: string;
    tertiary: string;
    button: string;
    buttonPale: string;
    negative: string;
    positive: string;

    /** used for modal overlay color for example */
    opacityOverlay: string;
    highlight: string;
    selection: string;
  };
  foreground: {
    primary: string;
    secondary: string;
    tertiary: string;
    button: string;
    tint: string;
    selection: string;

    newMessage: string;
    error: string;
    warning: string;
    good: string;
  };
  border: {
    primary: string;
    light: string;
  };
  shadow: {
    dropdown: string;
  };
  calendar: {
    sick: string;
    available: string;
    vaccation: string;
    unscheduled: string;
    unavailable: string;
    worked: string;
    didntwork: string;
  };
}

const light: DeepReadonly<ThemeColors> = {
  background: {
    primary: white,
    secondary: lightGrey,
    tertiary: darkGrey,
    button: orange,
    buttonPale: orangePale,
    opacityOverlay: 'rgba(0,0,0,0.3)',
    highlight: '#ecf6ff',
    selection: '#1E90FF',
    negative: red,
    positive: green,
  },
  foreground: {
    primary: black,
    secondary: black,
    tertiary: darkGrey,
    button: white,
    tint: orange,
    selection: '#fff',

    newMessage: '#5398ff',
    error: red,
    warning: orange,
    good: green,
  },
  border: {
    primary: darkGrey,
    light: '#b5b3b35c',
  },
  shadow: {
    dropdown: '0 3px 4px 1px rgb(0 0 0 / 25%)',
  },
  calendar: {
    sick: 'yellow',
    available: 'green',
    vaccation: 'lightgreen',
    unscheduled: 'blue',
    unavailable: 'white',
    worked: 'lightgrey',
    didntwork: 'white',
  },
};

const dark: DeepReadonly<ThemeColors> = {
  background: {
    primary: black,
    secondary: darkGrey,
    tertiary: lightGrey,
    button: orange,
    buttonPale: orangePale,
    opacityOverlay: 'rgba(0,0,0,0.3)',
    highlight: '##0086ff',
    selection: '#1E90FF',
    negative: red,
    positive: green,
  },
  foreground: {
    primary: white,
    secondary: lightGrey,
    tertiary: darkGrey,
    button: white,
    tint: orange,
    selection: '#fff',

    newMessage: '#5398ff',
    error: red,
    warning: 'orange',
    good: green,
  },
  border: {
    primary: lightGrey,
    light: '#646464',
  },
  shadow: {
    dropdown: '0 3px 4px 1px rgb(0 0 0 / 25%)',
  },
  calendar: {
    sick: 'yellow',
    available: 'green',
    vaccation: 'lightgreen',
    unscheduled: 'blue',
    unavailable: 'grey',
    worked: 'lightgrey',
    didntwork: 'white',
  },
};

const themes = {
  light,
  dark,
} as const;

export const getThemeColors = (
  theme: 'light' | 'dark' = 'light'
): DeepReadonly<ThemeColors> => themes[theme];

export default themes;
