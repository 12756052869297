import {
  faBolt,
  faDiamond,
  faGear,
  faHouse,
  faRoad,
  faTableList,
  faTrailer,
  faTrophy,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { EmployeeScheduleResponse, UserModel } from 'api';
import { black, lightGrey } from 'constants/Colors';
import { UserFormField } from 'pages/Employees/useUserForm';

const iconDictionary = {
  [UserFormField.canTestTrucks]: {
    icon: (enabled?: boolean) => (
      <span className="fa-layers fa-fw" key="canTestTrucksIcon">
        <FontAwesomeIcon color={enabled ? black : lightGrey} icon={faTruck} />
        <FontAwesomeIcon
          color="white"
          icon={faTableList}
          transform="shrink-8 "
        />
      </span>
    ),
    title: 'Godkänd för test av transportbilar (vid inköp/inbyte)',
  },
  [UserFormField.canDoHomeDelivery]: {
    icon: (enabled?: boolean) => (
      <FontAwesomeIcon color={enabled ? black : lightGrey} icon={faHouse} />
    ),
    title: 'Godkänd för hemleverans',
  },
  [UserFormField.canDriveElectric]: {
    icon: (enabled?: boolean) => (
      <FontAwesomeIcon color={enabled ? black : lightGrey} icon={faBolt} />
    ),
    title: 'Godkänd för elbilar',
  },
  [UserFormField.canDriveManual]: {
    icon: (enabled?: boolean) => (
      <FontAwesomeIcon color={enabled ? black : lightGrey} icon={faGear} />
    ),
    title: 'Körkort för manuell växel',
  },
  [UserFormField.canTestPremiumCars]: {
    icon: (enabled?: boolean) => (
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon color={enabled ? black : lightGrey} icon={faTrophy} />
        <FontAwesomeIcon
          color="white"
          icon={faTableList}
          transform="shrink-8"
        />
      </span>
    ),
    title: 'Godkänd för test av premiumbilar (vid inköp/inbyte)',
  },
  [UserFormField.canDriveTrucks]: {
    icon: (enabled?: boolean) => (
      <FontAwesomeIcon color={enabled ? black : lightGrey} icon={faTruck} />
    ),
    title: 'C-KORT',
  },
  [UserFormField.canDriveTrailers]: {
    icon: (enabled?: boolean) => (
      <FontAwesomeIcon color={enabled ? black : lightGrey} icon={faTrailer} />
    ),
    title: 'Godkänd för släpvagn (BE-KORT)',
  },
  [UserFormField.canDrivePremium]: {
    icon: (enabled?: boolean) => (
      <FontAwesomeIcon color={enabled ? black : lightGrey} icon={faTrophy} />
    ),
    title: 'Godkänd för premiumbilar',
  },
  [UserFormField.canDoLongDrives]: {
    icon: (enabled?: boolean) => (
      <FontAwesomeIcon color={enabled ? black : lightGrey} icon={faRoad} />
    ),
    title: 'Godkänd för långkörning',
  },
  [UserFormField.isFullTime]: {
    icon: (enabled?: boolean) => (
      <FontAwesomeIcon color={enabled ? black : lightGrey} icon={faDiamond} />
    ),
    title: 'Heltidsanställd',
  },
  [UserFormField.certifiedToCallCustomers]: {
    icon: (enabled?: boolean) => (
      <div
        style={{
          width: 20,
          height: 20,
        }}
      >
        <img
          alt="Telefon"
          height={20}
          src={
            enabled
              ? '/images/rotary-phone-red.png'
              : '/images/rotary-phone-grey.png'
          }
          width={20}
        />
      </div>
    ),
    title: 'Certifierad för att ringa kunder',
  },
};

type UserPropsIcon = {
  enabled?: boolean;
  icon: React.ReactElement<FontAwesomeIconProps>;
  title: string;
  field: UserFormField;
};

export const userPropsArray = [
  UserFormField.isFullTime,
  UserFormField.canTestTrucks,
  UserFormField.canTestPremiumCars,
  UserFormField.canDoHomeDelivery,
  UserFormField.canDriveElectric,
  UserFormField.canDriveManual,
  UserFormField.canDriveTrucks,
  UserFormField.canDriveTrailers,
  UserFormField.canDrivePremium,
  UserFormField.canDoLongDrives,
  UserFormField.certifiedToCallCustomers,
];

export const getUserPropsIconArray = (
  user: UserModel | EmployeeScheduleResponse
) => {
  const icons: UserPropsIcon[] = [
    {
      enabled: user.canTestPremiumCars,

      icon: iconDictionary[UserFormField.canTestPremiumCars].icon(
        user.canTestPremiumCars
      ),
      title: iconDictionary[UserFormField.canTestPremiumCars].title,
      field: UserFormField.canTestPremiumCars,
    },
    {
      enabled: user.canTestTrucks,
      icon: iconDictionary[UserFormField.canTestTrucks].icon(
        user.canTestTrucks
      ),
      title: iconDictionary[UserFormField.canTestTrucks].title,
      field: UserFormField.canTestTrucks,
    },
    {
      enabled: user.canDoHomeDelivery,
      icon: iconDictionary[UserFormField.canDoHomeDelivery].icon(
        user.canDoHomeDelivery
      ),
      title: iconDictionary[UserFormField.canDoHomeDelivery].title,
      field: UserFormField.canDoHomeDelivery,
    },
    {
      enabled: user.canDriveElectric,
      icon: iconDictionary[UserFormField.canDriveElectric].icon(
        user.canDriveElectric
      ),
      title: iconDictionary[UserFormField.canDriveElectric].title,
      field: UserFormField.canDriveElectric,
    },
    {
      enabled: user.canDriveManual,
      icon: iconDictionary[UserFormField.canDriveManual].icon(
        user.canDriveManual
      ),
      title: iconDictionary[UserFormField.canDriveManual].title,
      field: UserFormField.canDriveManual,
    },
    {
      enabled: user.canDriveTrucks,

      icon: iconDictionary[UserFormField.canDriveTrucks].icon(
        user.canDriveTrucks
      ),
      title: iconDictionary[UserFormField.canDriveTrucks].title,
      field: UserFormField.canDriveTrucks,
    },
    {
      enabled: user.canDriveTrailers,
      icon: iconDictionary[UserFormField.canDriveTrailers].icon(
        user.canDriveTrailers
      ),
      title: iconDictionary[UserFormField.canDriveTrailers].title,
      field: UserFormField.canDriveTrailers,
    },
    {
      enabled: user.canDrivePremium,
      icon: iconDictionary[UserFormField.canDrivePremium].icon(
        user.canDrivePremium
      ),
      title: iconDictionary[UserFormField.canDrivePremium].title,
      field: UserFormField.canDrivePremium,
    },
    {
      enabled: user.canDoLongDrives,
      icon: iconDictionary[UserFormField.canDoLongDrives].icon(
        user.canDoLongDrives
      ),
      title: iconDictionary[UserFormField.canDoLongDrives].title,
      field: UserFormField.canDoLongDrives,
    },
    {
      enabled: user.isFullTime,
      icon: iconDictionary[UserFormField.isFullTime].icon(user.isFullTime),
      title: iconDictionary[UserFormField.isFullTime].title,
      field: UserFormField.isFullTime,
    },
    {
      enabled: user.certifiedToCallCustomers,
      icon: iconDictionary[UserFormField.certifiedToCallCustomers].icon(
        user.certifiedToCallCustomers
      ),
      title: iconDictionary[UserFormField.certifiedToCallCustomers].title,
      field: UserFormField.certifiedToCallCustomers,
    },
  ];
  return icons;
};

export default iconDictionary;
