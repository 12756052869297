import ReactDatepicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import styled, { createGlobalStyle, css } from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { formatDate, formatDateTime } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useApiCall } from 'swaggerhooks/lib';
import { HolidaysClient } from 'api';

export const holidaysClassName = 'react-datepicker__day--holidays';

const DatePickerGlobalStyles = createGlobalStyle<{
  isSelectingSpan: boolean;
  isDefaultRed?: boolean;
  isWeekSelect?: boolean;
}>`
  .react-datepicker__triangle {
    left: 0 !important;
    margin-left: 0 !important;
  }

  .react-datepicker__input-container {
    height: 100%;
    padding: 0 0px;
  }

  .react-datepicker__day {
    border-radius: ${({ theme }) => theme.sizes.radius.input}px !important;

    &, &.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--in-range) {
      background: ${({ theme }) => theme.colors.background.primary};
      color: ${({ theme }) => theme.colors.foreground.primary};
    }

    ${({ isSelectingSpan }) =>
      isSelectingSpan &&
      css`
        &.react-datepicker__day--keyboard-selected {
          outline: 2px solid ${({ theme }) => theme.colors.background.selection};
          font-weight: 600;
        }
      `}

    &.react-datepicker__day--selected,
    &.react-datepicker__day--selected:hover,
    &.react-datepicker__day--in-range,
    &.react-datepicker__day--in-range:hover {
      background: ${({ theme }) => theme.colors.background.button};
      color: ${({ theme }) => theme.colors.foreground.button};
      font-weight: 600;
    }
  }

  // .react-datepicker__day--in-range:not(.availability_reported):not(.availability_unsaved) {
  //   color: darkred !important;
  // }

  // .react-datepicker__day:not(.react-datepicker__day--disabled):not(.availability_reported):not(.availability_unsaved):not(.react-datepicker__day--in-range) {
  //   color: red !important;
  //   font-weight: 600;
  // }

  .react-datepicker__day--disabled {
    color: #ccc;
  }

  .react-datepicker__week-number {
    color: ${({ theme }) => theme.colors.background.button};
  }

  .react-datepicker__week-number--selected, .react-datepicker__week-number:hover {
    background: none;
  }

  ${({ isWeekSelect }) =>
    isWeekSelect &&
    css`
      /* Week number */
      .react-datepicker__week-number--selected {
        color: ${({ theme }) => theme.colors.foreground.button};
        background: ${({ theme }) => theme.colors.background.button};
      }
      .react-datepicker__week:hover .react-datepicker__week-number {
        color: ${({ theme }) => theme.colors.foreground.button};
      }

      /* Week row */
      .react-datepicker__week--selected,
      .react-datepicker__week:hover,
      .react-datepicker__week:hover .react-datepicker__day,
      .react-datepicker__week--selected .react-datepicker__day {
        color: ${({ theme }) => theme.colors.foreground.button};
        background: ${({ theme }) => theme.colors.background.button};
        font-weight: 600;
      }
    `}
  // .availability_reported {
  //   color: green !important;
  //   font-weight: 600;
  // }

  // .availability_unsaved {
  //   color: blue !important;
  //   font-weight: 600;
  // }
  .react-datepicker__year-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .react-datepicker__year-text {
    font-size: 14px;
    padding: 5px;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;

    &.react-datepicker__year-text--today {
      font-weight: 600;
      background: ${({ theme }) => theme.colors.background.primary};
    }
    &.react-datepicker__year-text--selected {
      font-weight: 600;
      background: ${({ theme }) => theme.colors.background.button};
    }
  }

  .react-datepicker__month-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .react-datepicker__month-text {
    font-size: 14px;
    padding: 5px;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;

    &.react-datepicker__month-text--selected {
      font-weight: 600;
      background: ${({ theme }) => theme.colors.background.button};
      color: ${({ theme }) => theme.colors.foreground.button};
    }
  }

  .${holidaysClassName}  {
    color: rgb(228, 10, 45)!important;
    font-weight: 600;
    &.react-datepicker__day--selected, &.react-datepicker__day.react-datepicker__day--in-range {
      color: rgb(255, 179, 182)!important;
    }
    &.react-datepicker__day--disabled {
      color: rgb(255, 179, 182)!important;
    }
  }
  .react-datepicker-popper {
    z-index: 1000;
  }
`;

registerLocale('sv', sv);
interface Props<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
> extends ReactDatePickerProps<CustomModifierNames, WithRange> {
  isDefaultRed?: boolean;
  populateHolidays?: boolean;
  isWeekSelect?: boolean;
}

export const DatePickerButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 30px;

  color: ${({ theme }) => theme.colors.foreground.tint};
  background-color: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  font: inherit;

  div {
    white-space: nowrap;
  }

  span {
    display: block;
    margin-left: auto;
    padding-left: 10px;
    font-size: 0.5em;
    color: ${({ theme }) => theme.colors.foreground.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

export const CustomInput = forwardRef<
  HTMLButtonElement,
  { date: Date; showTime?: boolean }
>(({ date, showTime, ...props }, ref) => (
  <DatePickerButton ref={ref} type="button" {...props}>
    <div>{showTime ? formatDateTime(date) : formatDate(date)}</div>
    <span>
      <FontAwesomeIcon icon={faCalendarAlt} size="2x" />
    </span>
  </DatePickerButton>
));

const DatePicker = <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(
  props: Props<CustomModifierNames, WithRange>
) => {
  const holidayFetchTimeout = useRef<NodeJS.Timeout>();
  const [weekendsAndHolidays, setWeekendsAndHolidays] = useState<Date[]>([]);

  const getHolidaysCall = useApiCall(
    HolidaysClient,
    (client, from: Date, to: Date) => client.getHolidays(from, to)
  );

  // fetch holidays
  useEffect(() => {
    const populateHolidays = async () => {
      const currentYear =
        props.selected?.getFullYear() ??
        props.startDate?.getFullYear() ??
        new Date().getFullYear();
      if (currentYear) {
        const fromDate = new Date(currentYear - 1, 0, 1);
        const toDate = new Date(currentYear + 1, 11, 31);
        const [result] = await getHolidaysCall.run(fromDate, toDate);
        if (result) {
          // convert holidays to dates
          const dates = result.map((h) => new Date(h.date));
          // add weekends
          while (fromDate < toDate) {
            fromDate.setDate(fromDate.getDate() + 1);
            const currentDate = new Date(fromDate);
            if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
              dates.push(currentDate);
            }
          }
          // update state
          setWeekendsAndHolidays(dates);
        }
      }
    };

    if (props.populateHolidays === true) {
      clearTimeout(holidayFetchTimeout.current);
      holidayFetchTimeout.current = setTimeout(() => {
        populateHolidays();
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps, react/destructuring-assignment
  }, [props.selected, props.startDate, props.endDate, props.populateHolidays]);

  return (
    <>
      <ReactDatepicker
        {...props}
        highlightDates={[
          {
            [holidaysClassName]: weekendsAndHolidays,
          },
        ]}
      />
      <DatePickerGlobalStyles
        isDefaultRed={!!props.isDefaultRed}
        isSelectingSpan={props.selectsRange ? !props.endDate : false}
        isWeekSelect={props.isWeekSelect}
      />
    </>
  );
};

export default DatePicker;
