import styled from 'styled-components';
import {
  AssignmentRequestModel,
  AssignmentRequestStatusEnum,
  CompanyModel,
  ExtendedAssignmentRequestModel,
} from 'api/model';
import { useForm } from 'react-hook-form';
import Modal from 'components/Modal';
import KeyValueList, { KeyListRow } from 'components/KeyValueList';
import Input from 'components/inputs/Input';
import {
  formatDateTime,
  getHoursAndMinutesFromMillisecondsString,
  toInputDateTimeString,
} from 'utils/date-helpers';
import useCompanies from 'contexts/basicData/useCompanies';
import PrioritizedCaseAssignmentUserPicker from 'components/PrioritizedCaseAssignmentUserPicker';
import { AssignmentTypeEnum, CaseModel } from 'api';
import useUsers from 'contexts/basicData/useUsers';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import {
  useAssignmentRequestsCreateAssignmentRequest,
  useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation,
  useAssignmentRequestsUpdateAssignmentRequest,
} from 'api/assignment-requests/assignment-requests';
import { arraySpreadIf } from 'utils/spreading';
import AssignmentRequestAssignmentPicker from './AssignmentRequestAssignmentPicker';
import AssignmentRequestEvents from './AssignmentRequestEvents';
import SectionHeader from 'components/SectionHeader';
import TextButton from 'components/inputs/TextButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import AssignmentRequestActions from './AssignmentRequestActions';
import CompanyPicker, {
  StyledCompanySelectItem,
} from 'components/CompanyPicker';
import { SelectOption } from 'components/inputs/SearchableSelect';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ExtraInfoInput = styled(AutoSizedTextArea)`
  resize: vertical;
  width: 395px;
  max-width: 395px;
`;

const NumberOfCarsInput = styled(Input)`
  width: 50px;
`;

const EventSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  width: 250px;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledKVList = styled(KeyValueList)`
  margin-top: 5px;
  flex: 1;
`;

const NoWrap = styled.span<{ tint?: boolean }>`
  white-space: nowrap;
  ${({ tint, theme }) =>
    tint &&
    `
    color: ${theme.colors.foreground.tint};
  `}
`;

const StyledLink = styled.a<{ tint?: boolean }>`
  ${({ tint, theme }) =>
    tint &&
    `
    color: ${theme.colors.foreground.tint} !important;
    text-decoration: none; // Adjust as needed
  `}
`;

const getDefaultRequestValues = (
  request?: AssignmentRequestModel,
  defaultFromCompanyId?: number
) => {
  if (request !== undefined) {
    return {
      ...request,
    };
  }

  return {
    targetStartTime: new Date(),
    assignmentRequestStatusID: AssignmentRequestStatusEnum.Created,
    numberOfCars: 1,
    fromCompanyID: defaultFromCompanyId,
  } as AssignmentRequestModel;
};

interface Props {
  request?: ExtendedAssignmentRequestModel;
  onClose?: () => void;
  onUpdated?: () => void;
}

const AssignmentRequestModal: React.FC<Props> = ({
  onClose,
  onUpdated,
  request,
}) => {
  const users = useUsers();
  const dateRef = useRef(new Date());
  const [expiryTimeInMinutes, setExpiryTimeInMinutes] = useState<number>(60);
  const expiryTimeInDate = useMemo(() => {
    const t = new Date();
    return new Date(t.getTime() + expiryTimeInMinutes * 60 * 1000);
  }, [expiryTimeInMinutes]);

  const canEdit =
    request?.assignmentRequest.assignmentRequestStatusID !==
      AssignmentRequestStatusEnum.Removed &&
    request?.assignmentRequest.assignmentRequestStatusID !==
      AssignmentRequestStatusEnum.Denied;

  const isNew = request?.assignmentRequest.requestID === undefined;
  const isCreatedState =
    request?.assignmentRequest.assignmentRequestStatusID ===
    AssignmentRequestStatusEnum.Created;

  const hasOriginatingAssignment =
    !!request?.assignmentRequest.originatingAssignmentID;

  const { companies } = useCompanies();
  const { assignmentRequest, assignmentRequestEvents } = request ?? {};
  // api calls
  const createAssignmentRequest =
    useAssignmentRequestsCreateAssignmentRequest();
  const updateAssignmentRequest =
    useAssignmentRequestsUpdateAssignmentRequest();
  const getNumberOfAssignmentsPerToLocationCall =
    useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation({
      date: request?.assignmentRequest.targetStartTime ?? dateRef.current,
    });

  const defaultFromCompanyId = useMemo(() => {
    // return Strängnäs Logistik as default
    return companies.find(
      (c) => c.name === 'Strängnäs Logistik' && c.isLogisticsCenter
    )?.companyID;
  }, [companies]);

  // form
  const {
    setValue,
    watch,
    getValues,
    handleSubmit,
    reset,

    formState: { isDirty },
  } = useForm<AssignmentRequestModel>({
    defaultValues: getDefaultRequestValues(
      request?.assignmentRequest,
      defaultFromCompanyId
    ),
  });

  const allocatedAssignmentsWatch = watch('allocatedAssignments');

  useEffect(() => {
    // reset form to update the default values
    reset(
      getDefaultRequestValues(request?.assignmentRequest, defaultFromCompanyId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const { fromCompanyID, toCompanyID } = watch();

  const timeBetweenCompanies = useMemo(() => {
    const fromCompany = companies.find((c) => c.companyID === fromCompanyID);

    const result = fromCompany?.pricesFromHere.find(
      (p) => p.toCompanyId === toCompanyID
    )?.fixedTimeInMillseconds;

    return result;
  }, [fromCompanyID, toCompanyID, companies]);

  const onCreate = handleSubmit(async (data) => {
    await createAssignmentRequest.mutateAsync({
      data,
    });
    onClose?.();
  });

  const onUpdate = handleSubmit(async (data) => {
    try {
      await updateAssignmentRequest.mutateAsync({
        data,
        params: {
          expires: expiryTimeInDate,
        },
      });
      onUpdated?.();
      onClose?.();
    } catch (e) {
      console.error(e);
    }
  });

  const requestLoading = useMemo(
    () =>
      createAssignmentRequest.isLoading || updateAssignmentRequest.isLoading,
    [createAssignmentRequest.isLoading, updateAssignmentRequest.isLoading]
  );

  const isTruckTransport = users[watch('requestedForID')!]?.isTruckTransport;

  const requestedForId = getValues('requestedForID');
  // cap the numberOfCars to 1 if requestedForID changes to someone who is not a truck driver
  useMemo(() => {
    if (requestedForId !== undefined) {
      const user = users[requestedForId];
      if (user && !user.isTruckTransport) {
        setValue('numberOfCars', 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedForId]);

  const fromCompanyFilter = (company: CompanyModel) => {
    if (company.isLogisticsCenter) {
      return true;
    }
    return false;
  };

  const needsExpiryInfo = useMemo(() => {
    if (isTruckTransport) return false;
    // check if the allocated assignments have changed
    const previousAllocatedAssignmentIds =
      request?.assignmentRequest.allocatedAssignments.map(
        (x) => x.assignment.assignmentID
      );
    const currentAllocatedAssignmentIds =
      allocatedAssignmentsWatch?.map((x) => x.assignment.assignmentID) ?? [];
    const newAllocatedAssignmentIds = currentAllocatedAssignmentIds.filter(
      (x) => !previousAllocatedAssignmentIds?.includes(x)
    );
    return newAllocatedAssignmentIds.length > 0;
  }, [
    allocatedAssignmentsWatch,
    isTruckTransport,
    request?.assignmentRequest.allocatedAssignments,
  ]);

  const toCompanyFilter = (company: CompanyModel) => {
    if (company.showInInternalDeliveryOverview) {
      return true;
    }
    return false;
  };

  const renderToCompanyOption = useCallback(
    (
      key: string,
      option: SelectOption<number | null, CompanyModel | null>,
      onClick: () => void,
      isSelected: boolean
    ) => {
      return (
        <StyledCompanySelectItem
          isNoneItem={option.value === null}
          key={key}
          onClick={onClick}
          selected={isSelected}
        >
          {option.label}
          {option.value !== null &&
            getNumberOfAssignmentsPerToLocationCall.data?.[option.value] !==
              undefined &&
            ` (${getNumberOfAssignmentsPerToLocationCall.data[option.value]})`}
        </StyledCompanySelectItem>
      );
    },
    [getNumberOfAssignmentsPerToLocationCall.data]
  );

  const assignmentRows: KeyListRow[] = [
    ...arraySpreadIf(
      assignmentRequest?.originatingAssignmentID !== undefined &&
        assignmentRequest?.originatingAssignmentID !== null,
      {
        key: 'Följande uppdrag',
        value: (
          <>
            <TextButton
              disabled={!canEdit}
              onClick={() => {
                window.open(
                  `/sok/${assignmentRequest!.originatingCaseID}`,
                  assignmentRequest!.originatingCaseID?.toString()
                );
              }}
            >
              {`${assignmentRequest?.originatingCaseRegistrationNumber} `}
              <FontAwesomeIcon icon={faExternalLink} />
            </TextButton>
            <span>{`
        (Starttid: ${assignmentRequest?.originatingAssignment?.assignment.estimatedStartTime ? formatDateTime(assignmentRequest!.originatingAssignment.assignment.estimatedStartTime) : ''}) 
        `}</span>
          </>
        ),
      }
    ),
    ...arraySpreadIf(!!assignmentRequest, {
      key: 'Uppdrag',
      value: (
        <AssignmentRequestAssignmentPicker
          disabled={!canEdit}
          onSelected={(assignments) => {
            setValue('allocatedAssignments', assignments, {
              shouldDirty: true,
            });
          }}
          selected={watch('allocatedAssignments')}
          request={getValues()!}
          defaultSelected={
            getDefaultRequestValues(request?.assignmentRequest)
              .allocatedAssignments
          }
        />
      ),
    }),
  ];

  const standardRows: KeyListRow[] = [
    {
      key: 'Hämtas tid',
      value: (
        <>
          <Input
            disabled={!canEdit}
            onChange={(e) =>
              setValue('targetStartTime', new Date(e.currentTarget.value), {
                shouldDirty: true,
              })
            }
            small
            type="datetime-local"
            value={toInputDateTimeString(watch('targetStartTime'))}
          />
          <span>
            {((request?.assignmentRequest.originatingAssignment &&
              request?.assignmentRequest.originatingAssignment?.assignment
                .estimatedStartTime) ||
              request?.assignmentRequest.originatingAssignment?.assignment
                .bookedTo) &&
              toInputDateTimeString(
                new Date(
                  request?.assignmentRequest.originatingAssignment!.assignment
                    .estimatedStartTime ??
                    request?.assignmentRequest.originatingAssignment!.assignment
                      .bookedTo
                )
              )}
          </span>
        </>
      ),
    },
    {
      key: 'Från anläggning',
      value: (
        <Horizontal>
          <CompanyPicker
            disabled={!canEdit}
            filter={fromCompanyFilter}
            selectedCompanyId={watch('fromCompanyID')}
            onCompanySelected={(company: CompanyModel | null) => {
              if (company) {
                setValue('fromCompanyID', company.companyID!, {
                  shouldDirty: true,
                });
              }
            }}
          />
        </Horizontal>
      ),
    },
    {
      key: 'Till anläggning',
      value: (
        <Horizontal>
          <CompanyPicker
            disabled={!canEdit}
            filter={toCompanyFilter}
            selectedCompanyId={watch('toCompanyID')}
            renderItem={renderToCompanyOption}
            onCompanySelected={(company: CompanyModel | null) => {
              if (company) {
                setValue('toCompanyID', company.companyID!, {
                  shouldDirty: true,
                });
              }
            }}
          />
          {timeBetweenCompanies !== undefined && (
            <div>
              Fast tid:{' '}
              {getHoursAndMinutesFromMillisecondsString(
                timeBetweenCompanies ?? 0
              )}
            </div>
          )}
        </Horizontal>
      ),
    },
    {
      key: 'Fälttestare',
      value: (
        <Horizontal>
          <PrioritizedCaseAssignmentUserPicker
            disabled={
              !canEdit ||
              (!isNew && !isCreatedState) ||
              hasOriginatingAssignment
            }
            caseModel={new CaseModel()}
            bookedTo={
              watch('targetStartTime')
                ? toInputDateTimeString(watch('targetStartTime'))
                : ''
            }
            selectedUserId={watch('requestedForID') ?? null}
            estimatedStartTime={
              watch('targetStartTime')
                ? toInputDateTimeString(watch('targetStartTime'))
                : ''
            }
            estimatedDuration={timeBetweenCompanies ?? 0}
            assignmentType={AssignmentTypeEnum.Normal}
            onUserPicked={(userModel) => {
              setValue('requestedForID', userModel?.userID, {
                shouldDirty: true,
              });
            }}
            assignmentId={-1}
            requireExistingAssignmentToSelectFT={false}
          />
          {watch('requestedForID') !== undefined && (
            <>
              <NoWrap>
                {users[watch('requestedForID')!]?.isTruckTransport
                  ? 'Lastbilstransportör'
                  : users[watch('requestedForID')!]?.canDriveManual
                    ? 'Godkänd för manuell växel.'
                    : 'Godkänd för automat växel.'}
              </NoWrap>
              {!users[watch('requestedForID')!]?.phoneNumber ? null : (
                <NoWrap tint>
                  <StyledLink
                    href={`tel:${users[watch('requestedForID')!]?.phoneNumber}`}
                  >
                    <FontAwesomeIcon icon={faPhone} />{' '}
                    {users[watch('requestedForID')!]?.phoneNumber}
                  </StyledLink>
                </NoWrap>
              )}
            </>
          )}
        </Horizontal>
      ),
    },
    {
      key: 'Antal bilar',
      value: (
        <Horizontal>
          <NumberOfCarsInput
            disabled={!canEdit || !isTruckTransport}
            type="number"
            value={watch('numberOfCars')}
            onChange={(e) =>
              setValue('numberOfCars', Number(e.currentTarget.value), {
                shouldDirty: true,
              })
            }
          />
          (Endast för lastbil)
        </Horizontal>
      ),
    },
    {
      key: 'Extra info',
      value: (
        <ExtraInfoInput
          disabled={!canEdit}
          onChange={(e) =>
            setValue('extraInfo', e.currentTarget.value, { shouldDirty: true })
          }
          value={watch('extraInfo')}
        />
      ),
    },
    ...arraySpreadIf(needsExpiryInfo, {
      key: 'Acceptera inom',
      value: (
        <>
          <Input
            style={{ width: 60 }}
            disabled={!canEdit}
            onChange={(e) => {
              setExpiryTimeInMinutes(Number(e.currentTarget.value));
            }}
            small
            type="number"
            value={expiryTimeInMinutes}
            min={0}
            max={24 * 60}
          />
          <span> minuter ({formatDateTime(expiryTimeInDate)})</span>
        </>
      ),
    }),
  ];

  return (
    <Modal
      buttons={
        assignmentRequest?.assignmentRequestStatusID !==
        AssignmentRequestStatusEnum.Denied
          ? [
              {
                onClick:
                  assignmentRequest?.requestID &&
                  assignmentRequest?.requestID > 0
                    ? onUpdate
                    : onCreate,
                disabled:
                  !isDirty ||
                  !watch('requestedForID') ||
                  !watch('toCompanyID') ||
                  requestLoading ||
                  !canEdit,
                label: 'Spara',
              },
              {
                onClick: onClose,
                disabled: requestLoading,
                label: 'Stäng',
              },
            ]
          : []
      }
      title={request ? 'Redigera förfrågan/bokning' : 'Ny förfrågan/bokning'}
      onClose={onClose}
    >
      <Wrapper>
        {Object.keys(users).length === 0 ? (
          <LoadingSpinner />
        ) : (
          <>
            <EventSection>
              <SectionHeader>Händelser</SectionHeader>
              <AssignmentRequestEvents
                events={assignmentRequestEvents ?? []}
                assignmentRequestId={assignmentRequest?.requestID}
                onUpdate={() => {
                  onUpdated?.();
                }}
              />
            </EventSection>
            <FormSection>
              <SectionHeader>Information</SectionHeader>
              <FormContent>
                <StyledKVList
                  colonKey
                  keyWidth="100"
                  rows={[...assignmentRows, ...standardRows]}
                />
                <AssignmentRequestActions
                  request={request}
                  onActionComplete={(close: boolean) => {
                    onUpdated?.();
                    if (close) {
                      onClose?.();
                    }
                  }}
                />
              </FormContent>
            </FormSection>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default AssignmentRequestModal;
