import { AssignmentClient } from 'api';
import Checkbox from 'components/inputs/Checkbox';
import {
  CustomMarker,
  MapIcons,
  useGoogleMapsContext,
} from 'components/map/GoogleMapsContext';
import Map from 'components/map/Map';
import { Suspense, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useResponse } from 'swaggerhooks/lib';
import { isLessThanXSecondsAgo } from 'utils/date-helpers';
import MapSearchInput from './MapSearchInput';
import Button from 'components/inputs/Button';
import { useLocalStorage } from 'usehooks-ts';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { swedenCenterCoords } from 'constants/AppConstants';

const MapPageContainer = styled.div`
  padding: 20px;
`;
const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: baseline;
  gap: 5px;
  margin: 10px 0px 10px;
`;

const MyCheckbox = styled(Checkbox)`
  padding: 5px;
  justify-content: center;
  align-items: center;
`;

const MyButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  margin-left: 5px;
  white-space: nowrap;
`;

const POSITIONS_UPDATE_INTERVAL_MS = 10000;

const MapPage = () => {
  const [hasFittedBounds, setHasFittedBounds] = useState<boolean>(false);
  const [useClustering, setUseClustering] = useLocalStorage(
    'use-clustering',
    true
  );
  const searchStr = useRef<string | undefined>();

  const liveLocations = useResponse(
    AssignmentClient,
    (c) => c.getAllLivePositions(searchStr.current),
    [searchStr]
  );

  // Refresh live locations every x ms
  useEffect(() => {
    const interval = setInterval(() => {
      liveLocations.update();
    }, POSITIONS_UPDATE_INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { markers, fitBounds, addCustomMarkers, clearMarkers } =
    useGoogleMapsContext();

  // Update the map markers when the live locations update
  useEffect(() => {
    clearMarkers();
    if (liveLocations.response) {
      console.log('adding custom markers from map page');

      addCustomMarkers(
        liveLocations.response.map(
          (l) =>
            ({
              position: {
                lat: l.latitude,
                lng: l.longitude,
              },
              label: l.registrationNumber,
              icon: isLessThanXSecondsAgo(l.timestamp, 15 * 60) // 15 minutes
                ? (l.speed ?? 0) > 1
                  ? MapIcons.vehicleLive
                  : MapIcons.vehicleStill
                : MapIcons.vehicleInactive,
              link: `sok/${l.caseId}`,
              timestamp: l.timestamp,
            } as CustomMarker)
        ),
        useClustering
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveLocations.response, useClustering]);

  useEffect(() => {
    if (markers.length > 0 && !hasFittedBounds) {
      fitBounds();
      setHasFittedBounds(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers, hasFittedBounds]);

  const handleSearch = (text: string) => {
    searchStr.current = text;
    liveLocations.update();
  };

  return (
    <Suspense fallback="Laddar...">
      <MapPageContainer>
        <FilterWrapper>
          <MapSearchInput onSearch={handleSearch} />
          <MyButton
            icon={<FontAwesomeIcon icon={faExpand} />}
            onClick={() => setHasFittedBounds(false)}
          >
            Centrera
          </MyButton>
          <MyCheckbox
            checked={useClustering}
            onChange={(event) => setUseClustering(event.currentTarget.checked)}
          >
            Gruppera överlappande ärenden
          </MyCheckbox>
        </FilterWrapper>
        <Map
          containerHeight={1000}
          initialCenter={swedenCenterCoords}
          zoom={6}
        />
      </MapPageContainer>
    </Suspense>
  );
};

export default MapPage;
