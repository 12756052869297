import useCompanies from 'contexts/basicData/useCompanies';
import { BasicFormState } from 'hooks/useForm';
import { AssignmentField } from '../DetailedCaseFormProvider/useCaseAssignmentForms';

function useGetFixedAssignmentTimeFromAddresses() {
  const { companies } = useCompanies();

  return function getFixedAssignmentTimeFromAddresses(
    formState: BasicFormState<AssignmentField>
  ) {
    const fromName = formState[AssignmentField.fromName];
    const fromAddress = formState[AssignmentField.fromAddress];
    const fromCity = formState[AssignmentField.fromCity];
    const fromZip = formState[AssignmentField.fromZip];

    const toName = formState[AssignmentField.toName];
    const toAddress = formState[AssignmentField.toAddress];
    const toCity = formState[AssignmentField.toCity];
    const toZip = formState[AssignmentField.toZip];

    const eligibleCompanies = companies.filter((c) => !!c.active);

    const fromCompany = eligibleCompanies.find(
      (c) =>
        c.name === fromName &&
        c.address === fromAddress &&
        c.city === fromCity &&
        c.zip === fromZip
    );
    const toCompany = eligibleCompanies.find(
      (c) =>
        c.name === toName &&
        c.address === toAddress &&
        c.city === toCity &&
        c.zip === toZip
    );

    if (fromCompany && toCompany) {
      let result = fromCompany.pricesFromHere.find(
        (price) => price.toCompanyId === toCompany.companyID
      );
      if (!result) {
        // if we didn't find a price from the fromCompany to the toCompany, we try to find a price from the toCompany to the fromCompany
        result = fromCompany.pricesToHere.find(
          (price) => price.fromCompanyId === toCompany.companyID
        );
      }

      return result;
    }

    return null;
  };
}

export default useGetFixedAssignmentTimeFromAddresses;
