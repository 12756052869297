import { UserCardMappingDTO, UserModel } from 'api/model';
import CSVReader from 'react-csv-reader';
import { usePaymentCardUploadCardMapping } from 'api/payment-card/payment-card';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';
import useModalStack from 'contexts/modal/useModalStack';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import { ColumnRenderProps } from 'pages/GlobalSearch/BulkApproveAssignmentsList/useColumnSettings';
import useUsers from 'contexts/basicData/useUsers';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from 'components/spinners/LoadingSpinner';

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  min-width: 1200px;
  width: 100%;
  flex-direction: column;
  flex: 1;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TableWrapper = styled.div`
  max-height: 70vh;
  overflow: auto;
`;

interface MappingRow extends UserCardMappingDTO {
  employmentId: string;
  userName: string;
  originalEmploymentId?: string;
  originalCardOwnerUserId?: number;
  originalUserName?: string;
  verified: boolean;
}
interface Props {
  onUpdated?: () => void;
}

const PaymentCardMappingModal: FC<Props> = ({ onUpdated }) => {
  const users = useUsers();
  const activeUsersById = useMemo(() => {
    const userMap: Record<string, UserModel> = {};
    Object.values(users).forEach((u) => {
      if (u.isActive) {
        userMap[u.userID] = u;
      }
    });
    return userMap;
  }, [users]);
  const activeUsersByEmploymentId = useMemo(() => {
    const userMap: Record<string, UserModel> = {};
    Object.values(users).forEach((u) => {
      if (u.employmentID !== undefined && u.isActive) {
        userMap[u.employmentID!] = u;
      }
    });
    return userMap;
  }, [users]);
  const modalStack = useModalStack();
  // api
  const uploadMappingCall = usePaymentCardUploadCardMapping();

  const [rows, setRows] = useState<MappingRow[]>([]);

  const handleFile = (data: any[]) => {
    // identify header row
    const header = data.shift().map((h: string) => h.trim());
    const cardOwnerUserIdIndex = header.indexOf('AnvändarID');
    const employmentIdIndex = header.indexOf('Anställningsnummer');
    const cardNumberIndex = header.indexOf('Kortnummer');
    const nameIndex = header.indexOf('Namn');

    const updatedRows: MappingRow[] = data.map((d) => {
      const originalUserId = d[cardOwnerUserIdIndex];
      const originalEmploymentId = d[employmentIdIndex];
      const originalUserName = d[nameIndex];
      const activeUserById = originalUserId
        ? activeUsersById[originalUserId]
        : null;
      const activeUserByEmploymentId = originalEmploymentId
        ? activeUsersByEmploymentId[originalEmploymentId]
        : null;

      const user = activeUserById ?? activeUserByEmploymentId;

      const row: MappingRow = {
        verified: !!user,
        cardOwnerUserId: user?.userID ?? -1,
        employmentId: user?.employmentID ?? '-',
        cardNumber: d[cardNumberIndex],
        userName: user?.name ?? '-',
        originalCardOwnerUserId: originalUserId,
        originalEmploymentId,
        originalUserName,
      };
      return row;
    });
    setRows(updatedRows);
  };

  const onSave = async () => {
    try {
      await uploadMappingCall.mutateAsync({
        data: rows
          .filter(
            (r) =>
              typeof r.cardOwnerUserId === 'number' && r.cardOwnerUserId > 0
          )
          .map((r) => ({
            cardNumber: r.cardNumber,
            cardOwnerUserId: r.cardOwnerUserId,
          })),
      });
      modalStack.pop();
      if (onUpdated) {
        onUpdated();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const columnSettings = useMemo((): ColumnSetting<
    MappingRow,
    ColumnRenderProps
  >[] => {
    return [
      {
        head: 'Nr',
        render: (item, renderProps, focused, index) => index + 1,
        width: 30,
      },
      {
        head: 'Kortnummer',
        render: (item) => item.cardNumber,
        width: 160,
      },
      {
        head: 'Användar-ID',
        render: (item) => item.originalCardOwnerUserId ?? '-',
        width: 120,
      },
      {
        head: 'Anställningsnummer',
        render: (item) => item.originalEmploymentId ?? '-',
        width: 120,
      },
      {
        head: 'Namn',
        render: (item) => item.originalUserName ?? '-',
        width: 150,
      },
      {
        head: 'Hittad',
        render: (item) =>
          !item.verified ? (
            '-'
          ) : (
            <span>
              <FontAwesomeIcon icon={faCheck} color="green" />
              {` ${item.userName}`}
            </span>
          ),
        width: 220,
      },
    ];
  }, []);

  return (
    <Modal
      fullscreen
      buttons={[
        {
          label: 'Spara',
          loading: uploadMappingCall.isLoading,
          disabled: uploadMappingCall.isLoading,
          onClick: () => {
            modalStack.push(
              <ConfirmModal
                onConfirm={() => {
                  modalStack.pop();
                  onSave();
                }}
                onCancel={() => modalStack.pop()}
                title="Är du säker?"
                content="Alla kort i listan som hittas i databasen kommer att skrivas över med sina nya ägare."
              />
            );
          },
        },
        {
          label: 'Avbryt',
          disabled: uploadMappingCall.isLoading,
          onClick: () => {
            modalStack.pop();
          },
        },
      ]}
    >
      <Wrapper>
        <Section>
          <SectionHeader>Ladda upp CSV</SectionHeader>
          <CSVReader
            disabled={uploadMappingCall.isLoading}
            cssClass="csv-input"
            inputId="csv-file-input"
            inputName="csv-file-input"
            onError={(err) => {
              console.log('error', err);
            }}
            onFileLoaded={handleFile}
            parserOptions={{}}
          />
        </Section>
        {uploadMappingCall.isLoading && <LoadingSpinner />}
        {rows.length > 0 && !uploadMappingCall.isLoading && (
          <TableWrapper>
            <Table
              columnSettings={columnSettings}
              rows={rows}
              useColumnWidthAsFlexAmount
            />
          </TableWrapper>
        )}
      </Wrapper>
    </Modal>
  );
};

export default PaymentCardMappingModal;
