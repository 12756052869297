import {
  matchRoutes,
  NavLink,
  NavLinkProps,
  useLocation,
} from 'react-router-dom';
import styled, { css } from 'styled-components';
import Routes from 'constants/Routes';
import RightHandNavBar from './RightHandNavBar';
import useAuthenticationState from 'contexts/authentication/useAuthentication';
import { useCallback, useMemo, useState } from 'react';
import useRideUpdates from 'pages/CasesWithRideUpdates/useRideUpdates';
import { useDevMenuState } from 'components/DevMenu';
import useAbsenceRequests from 'pages/Employees/useUnhandledCount';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import { useRecoilState } from 'recoil';
import AssignedTimesDebug, {
  showAssignedTimesAtom,
} from 'components/AssignedTimesDebug';
import { useCaseGetCaseAssignmentRequestsCount } from 'api/case/case';
import WavingHand from 'components/WavingHand/WavingHand';
import { useAssignmentRequestsGetCounts } from 'api/assignment-requests/assignment-requests';
import { useReadLocalStorage } from 'usehooks-ts';
import { NavBarState, NavBarStateKey } from 'constants/AppConstants';
import Dropdown from 'components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import TextButton from 'components/inputs/TextButton';
// import useBetaFeatures from 'contexts/basicData/useBetaFeatures';

const navLinkClassName = 'nav-link';
const activeNavLinkClassName = 'active-nav-link';
const unAuthenticatedNavLinkClassName = 'unauthenticated-nav-link';

const NavBarContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 30px;
    padding: 10px 10px;

    border-bottom: 1px solid ${theme.colors.border.primary};
  `}
`;

const NavLinks = styled.div<{ direction?: 'row' | 'column' | undefined }>`
  ${({ theme, direction = 'row' }) => css`
    display: flex;
    flex-direction: ${direction};
    gap: 30px;
    align-items: baseline;

    .${navLinkClassName} {
      border: none;
      background-color: transparent;
      text-transform: capitalize;
      color: ${theme.colors.foreground.primary};
      text-decoration: none;

      &:hover {
        color: ${theme.colors.foreground.tint};
      }
    }

    .${unAuthenticatedNavLinkClassName} {
      border: none;
      background-color: transparent;
      text-transform: capitalize;
      color: ${theme.colors.foreground.tertiary};
      text-decoration: none;
      cursor: default;
    }

    .${activeNavLinkClassName} {
      padding-bottom: 1px;
      border-bottom: 2px solid ${theme.colors.background.button};
      font-weight: bold;
    }
  `}
`;

const DropdownNavLinks = styled(NavLinks)`
  ${({ theme }) => css`
    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.border.primary};
    padding: 10px;
    gap: 10px;
  `}
`;

interface NavBarDropDownProps {
  navItems: NavRule[];
  makeActiveClassname: (props: { isActive: boolean }) => string;
  defaultValue: NavRule;
}

interface AuthorizedNavLinkProps extends NavLinkProps {
  allow?: number[];
  deny?: number[];
}

const AuthorizedNavLink: React.FC<AuthorizedNavLinkProps> = ({
  allow = [],
  deny = [],
  ...rest
}) => {
  const me = useMe();
  const show = useMemo((): boolean => {
    const roles = me?.roles ?? [];
    return deny.length > 0
      ? !deny.some((role) => roles.includes(role))
      : allow.length > 0
        ? allow.some((role) => roles.includes(role))
        : true;
  }, [me?.roles, deny, allow]);

  return show ? <NavLink {...rest} style={{ whiteSpace: 'nowrap' }} /> : null;
};

const Img = styled.img`
  margin-top: 10px;
`;
type NavRule = {
  content: React.ReactNode;
  allow?: number[];
  deny?: number[];
  route: string;
};

const NavBarDropDown: React.FC<NavBarDropDownProps> = ({
  navItems,
  makeActiveClassname,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();

  const paths = navItems.map((rule) => ({ path: `${rule.route}/*` }));

  const routematch = useMemo(
    () => matchRoutes(paths, location.pathname),
    [paths, location.pathname]
  );

  const activeRoute = useMemo(() => {
    return navItems.find((rule) => {
      return rule.route === routematch?.[0].pathnameBase;
    });
  }, [navItems, routematch]);

  return (
    <Dropdown
      onLostFocus={() => setIsOpen(false)}
      content={
        isOpen && (
          <DropdownNavLinks direction="column" style={{ gap: 10 }}>
            <strong>Uppdragslistor</strong>
            {navItems.map((rule) => (
              <AuthorizedNavLink
                key={rule.route}
                className={makeActiveClassname}
                to={rule.route}
                allow={rule.allow}
                deny={rule.deny}
                onClick={() => setIsOpen(false)}
              >
                {rule.content}
              </AuthorizedNavLink>
            ))}
          </DropdownNavLinks>
        )
      }
    >
      <NavLinks>
        {!activeRoute ? (
          <TextButton
            onClick={() => setIsOpen(!isOpen)}
            style={{
              whiteSpace: 'nowrap',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '200%',
              gap: '5px',
              fontWeight: 'normal',
              color: 'black',
              padding: 2,
            }}
          >
            <span>Uppdragslistor</span>
            <FontAwesomeIcon icon={isOpen ? faCaretDown : faCaretRight} />
          </TextButton>
        ) : (
          <NavLink
            className={makeActiveClassname({ isActive: !!activeRoute })}
            to={activeRoute?.route ?? '/'}
            onClick={() => setIsOpen(!isOpen)}
            style={{
              whiteSpace: 'nowrap',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px',
            }}
          >
            <span>Uppdragslista: {activeRoute.content}</span>
            <FontAwesomeIcon icon={isOpen ? faCaretDown : faCaretRight} />
          </NavLink>
        )}
      </NavLinks>
    </Dropdown>
  );
};

const NavBar: React.FC = () => {
  const {
    state: { isAuthenticated },
  } = useAuthenticationState();
  // const [showFolded, setShowFolded] = useLocalStorage('showFolded', false);
  const navbarState = useReadLocalStorage<NavBarState>(NavBarStateKey);

  const [, setDevMenuState] = useDevMenuState();
  const [showAssignedTimes, set] = useRecoilState(showAssignedTimesAtom);
  const { rideUpdatesCount } = useRideUpdates();
  const { data: caseAssignmentRequestsCount } =
    useCaseGetCaseAssignmentRequestsCount({
      query: {
        refetchInterval: 10000,
        enabled: isAuthenticated,
      },
    });
  const { data: assignmentRequestsCountData } = useAssignmentRequestsGetCounts({
    query: {
      staleTime: 15000,
      refetchInterval: 60000,
    },
  });

  const { unhandledTotalCount } = useAbsenceRequests();
  // const [betaFeatures] = useBetaFeatures();

  const assignmentNavItems: NavRule[] = useMemo(
    () => [
      {
        content: 'Alla',
        deny: [Roles.Riddermark],
        route: Routes.search.index,
      },
      {
        content: 'Inköp',
        deny: [Roles.Riddermark],
        route: Routes.purchases.index,
      },
      {
        content: 'Extrajobb',
        deny: [Roles.Riddermark],
        route: Routes.extrajobs.index,
      },
      {
        content: 'Hemleveranser',
        deny: [Roles.Riddermark],
        route: Routes.homeDeliveries.index,
      },
      {
        content: 'Annons',
        deny: [Roles.Riddermark],
        route: Routes.internalDeliveries.index,
      },
      {
        content: 'Annons Sälj',
        deny: [Roles.Riddermark],
        route: Routes.internalDeliverySales.index,
      },
      {
        content: 'Butik till Butik',
        deny: [Roles.Riddermark],
        route: Routes.facilityToFacility.index,
      },
      {
        content: 'Påkostnad extern verkstad ut',
        deny: [Roles.Riddermark],
        route: Routes.extraCostExternalWorkshopOut.index,
      },
      {
        content: 'Påkostnad extern verkstad retur',
        deny: [Roles.Riddermark],
        route: Routes.extraCostExternalWorkshopReturn.index,
      },
      {
        content: 'Anläggningsinbyte',
        deny: [Roles.Riddermark],
        route: Routes.facilityExchange.index,
      },
      {
        content: 'Avvikande jobb',
        deny: [Roles.Riddermark],
        route: Routes.rmOther.index,
      },
      {
        content: 'Sumpen',
        deny: [Roles.Riddermark],
        route: Routes.swamp.index,
      },
      {
        content: 'Mellanlandade',
        deny: [Roles.Riddermark],
        route: Routes.pendingAssignments.index,
      },
    ],
    []
  );

  const otherNavItems: NavRule[] = useMemo(
    () => [
      {
        content: (
          <>
            Annons Strängnäs
            {(assignmentRequestsCountData?.numberOfUnhandledRequests ?? 0) >
              0 && <WavingHand />}
          </>
        ),
        allow: [Roles.Riddermark, Roles.Annons, Roles.Admin, Roles.SuperAdmin],
        route: Routes.internalDeliveryGroup.index,
      },
      {
        content: (
          <>
            Uppdateringar {rideUpdatesCount > 0 ? `(${rideUpdatesCount})` : ''}
          </>
        ),
        deny: [Roles.Riddermark],
        route: Routes.rideUpdates.index,
      },
      {
        content: (
          <>
            Förfrågningar{' '}
            {caseAssignmentRequestsCount
              ? caseAssignmentRequestsCount > 0
                ? `(${caseAssignmentRequestsCount})`
                : ''
              : ''}
            {caseAssignmentRequestsCount && caseAssignmentRequestsCount > 0 && (
              <WavingHand />
            )}
          </>
        ),
        allow: [Roles.Admin],
        deny: [Roles.Riddermark],
        route: Routes.caseAssignmentRequests.index,
      },
      {
        content: 'Underlag',
        allow: [Roles.Admin],
        deny: [Roles.Riddermark],
        route: Routes.excelExports.index,
      },
      {
        content: (
          <>
            Personal {unhandledTotalCount > 0 ? `(${unhandledTotalCount})` : ''}
          </>
        ),
        allow: [Roles.Admin],
        deny: [Roles.Riddermark],
        route: Routes.employees.index,
      },
      {
        content: 'Adresser',
        allow: [Roles.Admin],
        deny: [Roles.Riddermark],
        route: Routes.companies.index,
      },
      {
        content: 'Karta',
        deny: [Roles.Riddermark],
        route: Routes.map.index,
      },
      {
        content: 'Inställningar',
        allow: [Roles.Admin],
        deny: [Roles.Riddermark],
        route: Routes.settings.index,
      },
    ],
    [
      unhandledTotalCount,
      rideUpdatesCount,
      caseAssignmentRequestsCount,
      assignmentRequestsCountData,
    ]
  );

  const makeActiveClassname = useCallback(
    (props: { isActive: boolean }): string => {
      return props.isActive
        ? `${navLinkClassName} ${activeNavLinkClassName}`
        : isAuthenticated
          ? navLinkClassName
          : unAuthenticatedNavLinkClassName;
    },
    [isAuthenticated]
  );

  return (
    <NavBarContainer>
      <Img
        alt="logo"
        height={29}
        onClick={(eve) => {
          if (eve.ctrlKey || eve.altKey)
            setDevMenuState((s) => ({ ...s, showMenu: true }));
          if (eve.shiftKey) set(!showAssignedTimes);
        }}
        src={`${process.env.PUBLIC_URL}/Zakra_Logo.svg`}
        width={140}
      />

      {navbarState === NavBarState.Horizontal ? (
        <NavLinks>
          {[...assignmentNavItems, ...otherNavItems].map((rule) => (
            <AuthorizedNavLink
              key={rule.route}
              className={makeActiveClassname}
              to={rule.route}
              allow={rule.allow}
              deny={rule.deny}
            >
              {rule.content}
            </AuthorizedNavLink>
          ))}
        </NavLinks>
      ) : (
        <>
          <NavBarDropDown
            navItems={assignmentNavItems}
            makeActiveClassname={makeActiveClassname}
            defaultValue={assignmentNavItems[0]}
          />
          <NavLinks>
            {otherNavItems.map((rule) => (
              <AuthorizedNavLink
                key={rule.route}
                className={makeActiveClassname}
                to={rule.route}
                allow={rule.allow}
                deny={rule.deny}
              >
                {rule.content}
              </AuthorizedNavLink>
            ))}
          </NavLinks>
        </>
      )}

      <RightHandNavBar />
      {showAssignedTimes && <AssignedTimesDebug />}
    </NavBarContainer>
  );
};

export default NavBar;
