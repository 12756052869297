import { AssignmentViewModel } from 'api';
import useAssignmentSearch from 'contexts/assignmentSearch/useAssignmentSearch';
import { forwardRef } from 'react';
import styled from 'styled-components';
import GlobalSearchInput from './GlobalSearchInput';
import LabelWrap from 'components/inputs/LabelWrap';
import { formatDate } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { inputStyle } from 'components/inputs/Input';
import TextButton from 'components/inputs/TextButton';
import useModalStack from 'contexts/modal/useModalStack';
import Modal from 'components/Modal';
import SearchFilterPicker from '../SearchFilterPicker';
import DatePicker from 'components/inputs/DatePicker';
import DateSpanPresetsSelector from './DateSpanPresetsSelector';
import MediaQuery from 'constants/MediaQuery';
import AssignmentCountWidget from 'components/AssignmentsList/AssignmentCountWidget';
import GlobalSearchFilterDropdown from './GlobalSearchFilterDropdown';

const Wrapper = styled.div`
  padding: 10px 10px;
  gap: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  display: grid;
  grid-template-columns: 1fr;
  ${MediaQuery.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const MyLabelWrap = styled(LabelWrap)<{ width?: number }>`
  height: 100%;
`;

const DatePickerButton = styled.button`
  ${inputStyle}
  display: flex;
  align-items: center;
  width: auto;
  height: 30px;
  color: ${({ theme }) => theme.colors.foreground.tint};

  div {
    white-space: nowrap;
  }

  span {
    display: block;
    margin-left: auto;
    padding-left: 10px;
    font-size: 0.9em;
    color: ${({ theme }) => theme.colors.foreground.primary};
  }
`;

const DatePickerWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Flex1 = styled.div<{ direction?: 'row' | 'column' }>`
  flex: 1;
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  align-items: flex-end;
  padding-bottom: 5px;
  gap: 5px;
`;

const DateRangeInput = forwardRef<
  HTMLButtonElement,
  { daterange: [Date | null, Date | null] }
>(({ daterange, ...props }, ref) => (
  <DatePickerButton ref={ref} type="button" {...props}>
    <div>{daterange[0] && formatDate(daterange[0])}</div>
    <div> — </div>
    <div>{daterange[1] && formatDate(daterange[1])}</div>
    <span>
      <FontAwesomeIcon icon={faCalendarAlt} />
    </span>
  </DatePickerButton>
));

interface Props {
  assignments: AssignmentViewModel[];
  total: number;
  skip: number;
  size: number;
}

const SearchBar: React.FC<Props> = ({ assignments }) => {
  const modalStack = useModalStack();
  const {
    state: { daterange },
    setDateRange,
  } = useAssignmentSearch();

  return (
    <Wrapper>
      <Flex1>
        <DatePickerWrap>
          <MyLabelWrap label="Datumspann">
            <DatePicker
              calendarStartDay={1}
              customInput={<DateRangeInput daterange={daterange} />}
              dateFormat="yyyy-MM-dd"
              endDate={daterange[1]}
              locale="sv"
              onChange={setDateRange}
              populateHolidays
              selectsRange
              startDate={daterange[0]}
            />
          </MyLabelWrap>

          <DateSpanPresetsSelector
            dateRange={daterange}
            onPresetSelected={setDateRange}
          />
        </DatePickerWrap>
        <AssignmentCountWidget assignments={assignments} />
      </Flex1>
      <Flex1>
        <SearchFilterPicker />
      </Flex1>

      <Flex1 style={{ marginLeft: 'auto' }}>
        <MyLabelWrap
          label={
            <>
              Sök{' '}
              <TextButton
                onClick={() => {
                  modalStack.push(
                    <Modal
                      buttons={[
                        { label: 'Ok', onClick: () => modalStack.pop() },
                      ]}
                      onClose={() => modalStack.pop()}
                      title="Vad kan jag söka på?"
                    >
                      Det går att söka på:
                      <br />
                      <br />
                      <ul>
                        <li>- Registreringsnummer</li>
                        <li>- Namn</li>
                        <li>- Adress</li>
                      </ul>
                    </Modal>
                  );
                }}
                unpadded
              >
                <FontAwesomeIcon icon={faCircleQuestion} />
              </TextButton>
            </>
          }
        >
          <GlobalSearchInput />
        </MyLabelWrap>
        <GlobalSearchFilterDropdown />
      </Flex1>
    </Wrapper>
  );
};

export default SearchBar;
