import { FC } from 'react';
import Modal from 'components/Modal';
import { pdfjs } from 'react-pdf';
import { GenericFileViewer } from 'components/Files/FileViewer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  title?: string;
  fileEnding: string;
  fileGuid: string;
  maxHeight?: number;
  onClose(): void;
}

const GenericFileViewerModal: FC<Props> = ({
  title,
  fileEnding,
  fileGuid,
  onClose,
  maxHeight,
}) => {
  return (
    <Modal
      buttons={[{ label: 'Stäng', onClick: onClose }]}
      onClose={onClose}
      title={title}
    >
      <GenericFileViewer
        browsable
        fileEnding={fileEnding}
        fileGuid={fileGuid}
        maxHeight={maxHeight}
      />
    </Modal>
  );
};

export default GenericFileViewerModal;
