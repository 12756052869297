import { faAngleDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown, { DropdownPosition } from 'components/Dropdown';
import { ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Input, { inputStyle } from './Input';

export const FakeSelect = styled.button<{
  small: boolean;
}>`
  ${inputStyle}
  min-width: 100%;
  text-align: start;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  padding-right: 5px;
  ${({ small }) =>
    small &&
    css`
      height: 24px;
    `}
`;

const StyledInput = styled(Input)`
  margin-bottom: 5px;
  width: 100%;
  padding: 5px;
  border-left: none;
  border-top: none;
  border-right: none;
`;

const SelectedValue = styled.div`
  flex: 1;
  min-height: 1.2em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const Item = styled.button<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  margin: 0;

  border: none;
  background: transparent;
  font: inherit;
  text-align: start;
  white-space: nowrap;

  &:hover {
    background: ${({ theme }) => theme.colors.background.selection};
    color: ${({ theme }) => theme.colors.foreground.selection};
  }
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 5px;

  background-color: ${({ theme }) => theme.colors.background.primary};
  color: ${({ theme }) => theme.colors.foreground.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  font-size: 12px;
`;

const PlaceholderWrapper = styled.div`
  padding: 2px;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.foreground.tertiary};
`;

export interface Option<V> {
  value: V;
  label: string;
  disabled?: boolean;
}

interface Props<V> {
  options: Option<V>[];
  onChange(selection: Set<V>): void;
  value: Set<V>;
  renderSelected?(item: Option<V> | undefined): ReactNode | null;
  className?: string;
  disabled?: boolean;
  small?: boolean;
  placeholder?: string;
  position?: DropdownPosition;
  searchFn?: (search: string, option: Option<V>) => boolean;
  labelRender?: (item: Option<V>) => ReactNode;
}

const MultiSelect = <V,>({
  options,
  onChange,
  value,
  renderSelected,
  className,
  disabled,
  small = false,
  placeholder,
  position,
  searchFn,
  labelRender,
}: Props<V>) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    if (!open) {
      setSearchValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dropdown
      content={
        open && (
          <Items>
            {!!searchFn && (
              <StyledInput
                placeholder="Sök"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            )}
            {options
              .filter(
                (o) => !searchFn || searchFn(searchValue.toLowerCase(), o)
              )
              .map((option, i) => (
                <Item
                  key={i}
                  disabled={option.disabled}
                  onClick={() => {
                    const newValue = new Set(value);
                    if (value.has(option.value)) {
                      newValue.delete(option.value);
                    } else {
                      newValue.add(option.value);
                    }
                    onChange(newValue);
                  }}
                >
                  <CheckBox>
                    {value.has(option.value) && (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                  </CheckBox>
                  {labelRender ? labelRender(option) : option.label}
                </Item>
              ))}
          </Items>
        )
      }
      onLostFocus={() => setOpen(false)}
      position={position}
    >
      <FakeSelect
        className={className}
        disabled={disabled}
        onClick={() => setOpen((o) => !o)}
        small={small}
      >
        {value.size === 0 ? (
          <PlaceholderWrapper>{placeholder}</PlaceholderWrapper>
        ) : renderSelected ? (
          Array.from(value).map((v) => {
            return renderSelected(options.find((o) => o.value === v));
          })
        ) : (
          <SelectedValue>
            {Array.from(value)
              .map((v) => options.find((o) => o.value === v)?.label)
              .join(', ')}
          </SelectedValue>
        )}
        <FontAwesomeIcon icon={faAngleDown} />
      </FakeSelect>
    </Dropdown>
  );
};

export default MultiSelect;
