import {
  faCompressAlt,
  faDownload,
  faExpandAlt,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { DatePicker } from '../../components';
import Roles from 'constants/Roles';
import {
  BarSection,
  BarWrapper,
  CustomInput,
  DateFormat,
  StyledAuthorizedButton,
} from '../../components/filterBars';
import BudgetFilterPicker from './BudgetFilterPicker';
import { useBudgetFilters } from './useBudgetFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssignmentRequestsGetCounts } from 'api/assignment-requests/assignment-requests';
import { FileResponse, InternalDeliveryOverviewClient } from 'api';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import themeSizes from 'constants/Sizes';
import Modal from 'components/Modal';
import InternalDeliveryOverviewTable from './table/InternalDeliveryOverviewTable';
import { useState } from 'react';
import TextButton from 'components/inputs/TextButton';

const MyLabel = styled.label`
  margin-right: 5px;
`;

const FullScreenWrapper = styled.div`
  width: 95vw;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DatePickerContainer = styled.div``;

const SubBar = styled(BarWrapper)`
  justify-content: flex-start;
`;

const RightBarSection = styled(BarSection)`
  margin-left: auto;
`;

const InternalDeliveryOverviewFilterBar = () => {
  const [showInFS, setShowInFS] = useState(false);

  const countData = useAssignmentRequestsGetCounts({
    query: {
      staleTime: 15000,
      refetchInterval: 60000,
    },
  });
  const {
    state: { selectedDate: date, startDate, endDate },
    setState,
    selectedFilterView,
    setSelectedFilterView,
  } = useBudgetFilters();

  const handleChangeDate = (
    newDate: Date | [Date | null, Date | null] | null
  ) => {
    if (newDate) {
      if (!Array.isArray(newDate)) {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
        }));
      }
    }
  };

  const exportDataCall = useApiCall(
    InternalDeliveryOverviewClient,
    (c, from: Date, to: Date) => {
      return c.getInternalDeliveryOverviewExport(from, to);
    }
  );

  const downloadFile = (fileResponse: FileResponse) => {
    const url = URL.createObjectURL(fileResponse.data);

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileResponse.fileName
      ? window.decodeURI(fileResponse.fileName)
      : 'Export.xls';
    anchor.click();

    URL.revokeObjectURL(url);
  };

  const handleExport = async () => {
    const [result, error] = await exportDataCall.run(startDate, endDate);

    if (error) {
      console.error('Error: ', error);
      return;
    }

    if (result) {
      downloadFile(result);
    }
  };

  if (showInFS) {
    return (
      <Modal bgColor="black" fullscreen>
        <FullScreenWrapper>
          <TextButton
            style={{ marginLeft: 'auto', background: 'black' }}
            onClick={() => setShowInFS(false)}
          >
            <FontAwesomeIcon
              color="white"
              icon={faCompressAlt}
              fontSize={themeSizes.font.large}
            />
          </TextButton>
          <InternalDeliveryOverviewTable onlyTotals />
        </FullScreenWrapper>
      </Modal>
    );
  }

  return (
    <>
      <BarWrapper>
        <BarSection>
          <MyLabel>Visa:</MyLabel>
          <DatePickerContainer>
            <DatePicker
              calendarStartDay={1}
              customInput={
                <CustomInput
                  date={date}
                  dateFormat={
                    `${
                      selectedFilterView === 'week' ? 'week' : 'date'
                    }` as DateFormat
                  }
                />
              }
              dateFormat="yyyy-MM-dd"
              locale="sv"
              onChange={(d) => handleChangeDate(d)}
              populateHolidays
              selected={date}
              showYearPicker={selectedFilterView === 'year'}
              showMonthYearPicker={selectedFilterView === 'month'}
              showWeekNumbers
              isWeekSelect={selectedFilterView === 'week'}
              shouldCloseOnSelect
            />
          </DatePickerContainer>
        </BarSection>

        <BudgetFilterPicker
          onSelect={(select: DateFormat) => setSelectedFilterView(select)}
          selected={selectedFilterView}
        />

        <RightBarSection>
          <span>
            Nycklar för inventering:{' '}
            {countData.data?.numberOfKeysInStorage ?? 0}
          </span>
        </RightBarSection>
      </BarWrapper>
      {selectedFilterView === 'week' && (
        <SubBar>
          <StyledAuthorizedButton
            onClick={handleExport}
            disabled={exportDataCall.status === RequestStatus.Fetching}
            roles={[
              Roles.Annons,
              Roles.AnnonsAdmin,
              Roles.Admin,
              Roles.SuperAdmin,
              Roles.Riddermark,
            ]}
          >
            {exportDataCall.status === RequestStatus.Fetching ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <>
                <FontAwesomeIcon icon={faDownload} onClick={handleExport} />
                Hämta export
              </>
            )}
          </StyledAuthorizedButton>
          <TextButton
            style={{ marginLeft: 'auto', background: 'black' }}
            onClick={() => setShowInFS(true)}
          >
            <FontAwesomeIcon
              color="white"
              icon={faExpandAlt}
              fontSize={themeSizes.font.large}
            />
          </TextButton>
        </SubBar>
      )}
    </>
  );
};

export default InternalDeliveryOverviewFilterBar;
