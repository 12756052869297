import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssignmentClient, StandardizedCommentDto } from 'api';
// import { useDetailedCaseContext } from 'components/DetailedCase/DetailedCaseProvider';
import Button from 'components/inputs/Button';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import useStandardizedComments from 'contexts/standardizedComments/useStandardizedComments';
import { useState } from 'react';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  flex: 1;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  flex-wrap: wrap;
`;

const Spacer = styled.div`
  display: block;
  width: 0;
`;

const InputHeight = 25;

const StyledStandardizedComment = styled(Button)<{
  selected?: boolean;
}>`
  border: 2px solid ${({ theme }) => theme.colors.background.button};
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  outline: none;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.background.button : 'transparent'};
  color: ${({ selected, theme }) =>
    selected ? 'white' : theme.colors.background.button};
  width: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px 0 10px;
  overflow: hidden;
  height: ${InputHeight}px;
  max-width: 300px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  cursor: pointer;
`;

const StyledInput = styled.input`
  border: 2px solid ${({ theme }) => theme.colors.background.button};
  border-right: none;
  border-radius: ${({ theme }) =>
    `${theme.sizes.radius.input}px 0 0 ${theme.sizes.radius.input}px`};
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.background.button};
  width: fit-content;
  padding: 10px 0 10px 20px;
  height: ${InputHeight}px;
`;

const InputButton = styled.button`
  border: 2px solid ${({ theme }) => theme.colors.background.button};
  border-left: none;
  border-radius: ${({ theme }) =>
    `0 ${theme.sizes.radius.input}px ${theme.sizes.radius.input}px 0`};
  padding: 0 10px 0 10px;
  font-weight: 500;
  cursor: pointer;
  background-color: transparent;
  height: ${InputHeight}px;
`;

type StandardizedCommentsProps = {
  assignmentId: number;
  commentsOnAssignment: StandardizedCommentDto[];
  onChange?(newComments: StandardizedCommentDto[]): void;
  onRefreshCase?(): Promise<void>;
};

const StandardizedComments = ({
  assignmentId,
  commentsOnAssignment,
  onChange,
  onRefreshCase,
}: StandardizedCommentsProps) => {
  const [customComment, setCustomComment] = useState<string>();

  const standardizedComments = useStandardizedComments();
  const { triggerAssignmentsUpdate } = useAssignmentsFilters();

  const addApiCall = useApiCall(
    AssignmentClient,
    (
      c,
      standardizedCommentId: number | null,
      newComment: string | null = null
    ) =>
      c.addStandardizedComment(standardizedCommentId, assignmentId, newComment)
  );

  const removeApiCall = useApiCall(
    AssignmentClient,
    (c, standardizedCommentId: number | null, comment: string | null) =>
      c.removeStandardizedComment(standardizedCommentId, assignmentId, comment)
  );

  const addStandardizedComment = async (
    standardizedCommentId: number | null,
    newComment: string | null
  ) => {
    if (standardizedCommentId === null && newComment === null) {
      console.log('Both standardizedCommentId and newComment cant be null!');
      return;
    }

    if (onChange) {
      // the component is being used in edit mode - no api calls should be made
      if (standardizedCommentId) {
        const scd = standardizedComments.find(
          (e) => e.id === standardizedCommentId
        );

        onChange([
          ...commentsOnAssignment,
          new StandardizedCommentDto({
            comment: scd!.comment,
            created: new Date(),
          }),
        ]);
      } else if (newComment) {
        onChange([
          ...commentsOnAssignment,
          new StandardizedCommentDto({
            comment: newComment,
            created: new Date(),
          }),
        ]);
      }
    } else if (addApiCall.status !== RequestStatus.Fetching) {
      // the component is being used in view mode - api calls should be made
      await addApiCall.run(standardizedCommentId, newComment);

      // await caseResponse.update();
      await onRefreshCase?.();
      triggerAssignmentsUpdate(new Date());
    }
  };

  const removeStandardizedComment = async (
    standardizedCommentId: number | null,
    comment: string | null
  ) => {
    if (onChange) {
      // the component is being used in edit mode - no api calls should be made
      if (standardizedCommentId) {
        const scd = standardizedComments.find(
          (e) => e.id === standardizedCommentId
        );

        onChange(
          commentsOnAssignment.reduce((acc, cur) => {
            if (cur.comment === scd?.comment) {
              return acc;
            }

            return [...acc, cur];
          }, [] as StandardizedCommentDto[])
        );
      } else if (comment) {
        onChange(
          commentsOnAssignment.reduce((acc, cur) => {
            if (cur.comment === comment) {
              return acc;
            }

            return [...acc, cur];
          }, [] as StandardizedCommentDto[])
        );
      }
    } else if (removeApiCall.status !== RequestStatus.Fetching) {
      // the component is being used in view mode - api calls should be made
      await removeApiCall.run(standardizedCommentId, comment);

      // await caseResponse.update();
      await onRefreshCase?.();
      triggerAssignmentsUpdate(new Date());
    }
  };

  return (
    <Container>
      <CommentContainer>
        {standardizedComments.map((comment) => (
          <StyledStandardizedComment
            key={`standardized_comment_${comment.id}`}
            onClick={() =>
              !commentsOnAssignment.some((e) => e.comment === comment.comment)
                ? addStandardizedComment(comment.id, null)
                : removeStandardizedComment(comment.id, null)
            }
            selected={commentsOnAssignment.some(
              (e) => e.comment === comment.comment
            )}
          >
            {comment.displayName}
            {commentsOnAssignment.some((e) => e.comment === comment.comment) ? (
              <StyledFontAwesomeIcon icon={faClose} />
            ) : (
              <StyledFontAwesomeIcon icon={faPlus} />
            )}
          </StyledStandardizedComment>
        ))}

        {commentsOnAssignment
          .filter((e) =>
            standardizedComments.every((sc) => sc.comment !== e.comment)
          )
          .map((c) => (
            <StyledStandardizedComment
              // disabled={removeApiCall.status === RequestStatus.Fetching}
              key={`custom_standardized_comment_${c.comment}`}
              onClick={() => removeStandardizedComment(null, c.comment)}
              selected
            >
              {c.comment}
              <StyledFontAwesomeIcon
                icon={faClose}
                onClick={() => removeStandardizedComment(null, c.comment)}
              />
            </StyledStandardizedComment>
          ))}
        {commentsOnAssignment.filter((e) =>
          standardizedComments.every((s) => s.comment !== e.comment)
        ).length > 0 && <Spacer />}
        <div>
          <StyledInput
            onChange={(e) => setCustomComment(e.currentTarget.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addStandardizedComment(null, customComment ?? null);
                setCustomComment('');
              }
            }}
            placeholder="Egen kommentar"
            value={customComment ?? ''}
          />
          <InputButton
            onClick={() => {
              addStandardizedComment(null, customComment ?? null);
              setCustomComment('');
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </InputButton>
        </div>
      </CommentContainer>
    </Container>
  );
};

export default StandardizedComments;
