import { useCallback, useMemo, useRef, useState } from 'react';
import { AssignmentClient, AssignmentViewModel, CaseEventType } from 'api';
import { useApiCall } from 'swaggerhooks/lib';

/** The idea of providing a transformation function instead of a AssignmentViewModel directly,
 *  is to be able to queue operations and only modify one specific attribute */
export type AssignmentEditOperation = (
  AssignmentViewModel: AssignmentViewModel
) => AssignmentViewModel;

function useBindRef<V>(value: V) {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}

const useQueuedAssignmentUpdates = (
  assignments: AssignmentViewModel[],
  onAssignmentUpdated: () => Promise<any>
) => {
  const [queues, setQueues] = useState<{
    [caseId: string]: AssignmentEditOperation[];
  }>({});
  const queuesRef = useBindRef(queues);
  const assignmentsRef = useBindRef(assignments);
  const onAssignmentUpdatedRef = useBindRef(onAssignmentUpdated);
  const queueWorkers = useRef<{ [caseId: string]: boolean }>({});

  const updateAssignmentCall = useApiCall(
    AssignmentClient,
    (c, avm: AssignmentViewModel) =>
      c.updateAssignment(
        avm,
        CaseEventType.AssignmentEdited,
        'Uppdrag uppdaterat',
        true
      )
  );

  const queueWorker = useCallback(async (assignmentId: number) => {
    // The queue will be emptied before the update api call,
    // but during the api call additional items may be added to the queue, depending on connection and user input.
    while ((queuesRef.current[assignmentId]?.length ?? 0) > 0) {
      const assignmentToApplyTo = assignmentsRef.current.find(
        (a) => a.assignment.assignmentID === assignmentId
      );
      if (!assignmentToApplyTo) {
        throw Error(`Couldnt find assignmentViewModel with id ${assignmentId}`);
      }

      const appliedAssignment = queuesRef.current[assignmentId].reduce(
        (acc, curr) => curr(acc),
        assignmentToApplyTo
      );
      setQueues({ ...queuesRef.current, [assignmentId]: [] });

      // eslint-disable-next-line no-await-in-loop
      await updateAssignmentCall.run(appliedAssignment);
      // eslint-disable-next-line no-await-in-loop
      await onAssignmentUpdatedRef.current();
    }

    // Tell enqueue that this worker is dead, and it should start another worker
    delete queueWorkers.current[assignmentId];
    setQueues((qs) => {
      const newQs = { ...qs };
      delete newQs[assignmentId];
      return newQs;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enqueueAssignmentUpdate = useCallback(
    (assignmentId: number, editOperation: AssignmentEditOperation) => {
      const newQueues = {
        ...queuesRef.current,
        [assignmentId]: [
          ...(queuesRef.current[assignmentId] ?? []),
          editOperation,
        ],
      };
      queuesRef.current = newQueues;
      setQueues(newQueues);

      // Start a new worker for the caseId if there's none already.
      if (!queueWorkers.current[assignmentId]) {
        queueWorkers.current[assignmentId] = true;
        queueWorker(assignmentId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queueWorker]
  );

  return useMemo(
    () => ({
      enqueueAssignmentUpdate,
      queues,
    }),
    [enqueueAssignmentUpdate, queues]
  );
};

export default useQueuedAssignmentUpdates;
