import {
  faBell,
  faComment,
  faComments,
  faExclamationTriangle,
  faList,
  faMobileAlt,
  faRobot,
  faSquarePen,
  faTaxi,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseEventType, EventModel, ITranslationLookups } from 'api';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import Comment from './eventComponents/Comment';
import CommentEditor from './eventComponents/CommentEditor';
import { EventSection } from './eventComponents/components';
import DefaultEventHeader from './eventComponents/DefaultEventHeader';
import TitledEvent from './eventComponents/TitledEvent';
import CaseAssignmentRequest from './eventComponents/CaseAssignmentRequest';

interface Props {
  event: EventModel;
  translations: ITranslationLookups;
  editMode?: boolean;
  onEditClick?(): void;
  onDeleteClick?(): void;
  onCancelSave?(): void;
  onSave?(
    caseId: number,
    message: string,
    eventId?: number,
    internal?: boolean,
    readByAdmin?: boolean
  ): void;
  onUpdate?(): void;
}

const Event: FC<Props> = ({
  event,
  translations,
  editMode,
  onEditClick,
  onDeleteClick,
  onCancelSave,
  onSave,
  onUpdate,
}) => {
  const theme = useTheme();
  const { caseEventTypes } = translations;

  if (event.caseEventType === CaseEventType.AssignmentInspected) {
    console.log('event', event);
  }
  switch (event.caseEventType) {
    case CaseEventType.TaxiRequest:
    case CaseEventType.BringVehicleHomeRequest:
    case CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy:
      return <CaseAssignmentRequest event={event} onUpdate={onUpdate} />;
    case CaseEventType.Comment:
    case CaseEventType.InternalComment:
      return editMode ? (
        <CommentEditor event={event} onCancel={onCancelSave} onSave={onSave} />
      ) : (
        <Comment
          event={event}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
        />
      );

    case CaseEventType.CustomerStatusSMSSuccess:
      return (
        <TitledEvent
          event={event}
          icon={<FontAwesomeIcon icon={faMobileAlt} />}
          title="SMS-uppdatering utskickat"
        />
      );

    case CaseEventType.CustomerStatusSMSFail:
      return (
        <TitledEvent
          event={event}
          icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          title="SMS-uppdatering FEL"
        />
      );

    case CaseEventType.RideComment:
      return (
        <TitledEvent
          event={event}
          icon={<FontAwesomeIcon icon={faComments} />}
          title="Ride-kommentar"
        />
      );

    case CaseEventType.RideChange:
      return (
        <EventSection>
          <DefaultEventHeader
            position={event.position}
            created={event.created}
            headerText={
              <>
                <FontAwesomeIcon icon={faSquarePen} />{' '}
                {event.message || caseEventTypes[event.caseEventType]}
              </>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
        </EventSection>
      );
    case CaseEventType.CarKeyAndDrivingLicensePhoto:
    case CaseEventType.CarKeyPhoto:
    case CaseEventType.CarParkPhoto:
    case CaseEventType.WorkGearPhoto:
      return (
        <EventSection>
          <DefaultEventHeader
            position={event.position}
            attachmentFileEnding={event.attachmentFileEnding}
            attachmentFileGuid={event.attachmentFileGuid}
            created={event.created}
            headerText={
              <span>
                {event.message || caseEventTypes[event.caseEventType]}
              </span>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
        </EventSection>
      );
    case CaseEventType.FlaggedForSpeeding:
      return (
        <EventSection>
          <DefaultEventHeader
            created={event.created}
            headerText={
              <>
                <FontAwesomeIcon
                  color={theme.colors.foreground.error}
                  icon={faTaxi}
                />
                {event.message || caseEventTypes[event.caseEventType]}
              </>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
        </EventSection>
      );
    case CaseEventType.DeviationReport:
      return (
        <EventSection>
          <DefaultEventHeader
            position={event.position}
            created={event.created}
            headerText={
              <>
                {!event.readByAdmin && (
                  <FontAwesomeIcon
                    color={theme.colors.foreground.newMessage}
                    icon={faComment}
                  />
                )}
                {caseEventTypes[event.caseEventType]}
              </>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
          {event.message}
        </EventSection>
      );
    case CaseEventType.AutoApprove:
      return (
        <EventSection>
          <DefaultEventHeader
            created={event.created}
            headerText={
              <>
                <FontAwesomeIcon icon={faRobot} />{' '}
                {caseEventTypes[event.caseEventType]}
              </>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
          {event.message}
        </EventSection>
      );
    case CaseEventType.WorkGearReport:
      return (
        <EventSection>
          <DefaultEventHeader
            position={event.position}
            created={event.created}
            headerText={
              <>
                <FontAwesomeIcon icon={faList} />{' '}
                {caseEventTypes[event.caseEventType]}
                {!event.readByAdmin && (
                  <>
                    {' '}
                    <FontAwesomeIcon
                      color={theme.colors.foreground.newMessage}
                      icon={faBell}
                    />
                  </>
                )}
              </>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
          {event.message}
        </EventSection>
      );

    case CaseEventType.AssignmentVehicleFaultDiscovered:
      return (
        <EventSection>
          <DefaultEventHeader
            position={event.position}
            created={event.created}
            headerText={
              <>
                <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                {caseEventTypes[event.caseEventType]}
              </>
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
          {event.message}
        </EventSection>
      );

    default:
      return (
        <EventSection key={event.id}>
          <DefaultEventHeader
            position={event.position}
            created={event.created}
            headerText={
              (event.message || caseEventTypes[event.caseEventType]) ?? '-'
            }
            httpTitle={
              event.assignmentID ? `Uppdrag ${event.assignmentID}` : ''
            }
            modified={event.modified}
            userName={event.modifiedBy}
          />
        </EventSection>
      );
  }
};

export default Event;
