import { ExternalReceiptProvider, PaymentCardModel } from 'api/model';
import {
  usePaymentCardCreatePaymentCard,
  usePaymentCardGetPaymentCard,
  usePaymentCardInactivatePaymentCard,
  usePaymentCardUpdatePaymentCard,
} from 'api/payment-card/payment-card';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';
import UserPicker from 'components/UserPicker';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import SearchableSelect from 'components/inputs/SearchableSelect';
import MediaQuery from 'constants/MediaQuery';
import useTranslations from 'contexts/basicData/useTranslations';
import useModalStack from 'contexts/modal/useModalStack';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import ExternalReceipstList from './ExternalReceipstList';
import TextButton from 'components/inputs/TextButton';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  ${MediaQuery.laptop} {
    grid-template-columns: 300px 200px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

const getDefaultValues = (paymentCard?: PaymentCardModel) => {
  return (
    paymentCard ?? {
      number: '',
      expiration: '',
      provider: ExternalReceiptProvider.CircleK,
      cardHolderID: undefined,
    }
  );
};

interface Props {
  id?: number;
  onUpdated?: () => void;
}

const PaymentCardFormModal: FC<Props> = ({ id, onUpdated }) => {
  const modalStack = useModalStack();
  // api
  const paymentCard = usePaymentCardGetPaymentCard(
    { id },
    { query: { enabled: id !== undefined } }
  );
  const updateCardCall = usePaymentCardUpdatePaymentCard();
  const createCardCall = usePaymentCardCreatePaymentCard();
  const inactivateCardCall = usePaymentCardInactivatePaymentCard({
    mutation: {
      onSuccess: () => {
        onUpdated?.();
        modalStack.pop();
        modalStack.pop();
      },
    },
  });
  const { handleSubmit, register, formState, reset, watch, setValue } =
    useForm<PaymentCardModel>({
      defaultValues: getDefaultValues(paymentCard.data),
    });

  useEffect(() => {
    if (!paymentCard.data) return;
    reset(getDefaultValues(paymentCard.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentCard.data]);
  const { externalReceiptProviders } = useTranslations();

  const partnerOptions = useMemo(() => {
    return Array.from(Object.keys(externalReceiptProviders)).map((p) => {
      return {
        label: externalReceiptProviders[Number(p)] ?? '-',
        value: Number(p) as ExternalReceiptProvider,
      };
    });
  }, [externalReceiptProviders]);

  const onSubmit = async (data: PaymentCardModel) => {
    try {
      if (id !== undefined) {
        await updateCardCall.mutateAsync({ data });
      } else {
        await createCardCall.mutateAsync({ data });
      }
      await onUpdated?.();
      modalStack.pop();
    } catch (error) {
      console.log(error);
    }
  };

  const isInactive = paymentCard.data && !paymentCard.data.isActive;

  const ConfirmInactivationModal = (
    <Modal
      title="Bekräfta inaktivering av kort"
      buttons={[
        {
          label: inactivateCardCall.isLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Ja'
          ),
          onClick: async () => {
            if (!paymentCard.data) return;
            await inactivateCardCall.mutate({ data: paymentCard.data });
          },
          disabled: inactivateCardCall.isLoading,
        },
        {
          label: 'Nej',
          onClick: () => {
            modalStack.pop();
            modalStack.pop();
          },
        },
      ]}
    >
      Är du säker på att du vill inaktivera kort {paymentCard.data?.number}?
      Åtgärden går inte att ångra.
    </Modal>
  );

  return (
    <Modal
      buttons={[
        {
          label: 'Spara',
          onClick: () => {
            handleSubmit(onSubmit)();
          },
          disabled: isInactive,
        },
        {
          label: 'Avbryt',
          onClick: () => {
            modalStack.pop();
          },
        },
      ]}
    >
      <Wrapper>
        <Section>
          <SectionHeader>Kvitton</SectionHeader>
          <ExternalReceipstList paymentCard={paymentCard.data} />
        </Section>
        <Section>
          <SectionHeader>Betalkort</SectionHeader>
          <InputWrapper>
            <LabelWrap
              label="Kortinnehavare"
              errorLabel={
                formState.errors.cardHolderID &&
                formState.errors.cardHolderID?.message?.toString()
              }
            >
              <UserPicker
                {...register('cardHolderID')}
                disabled={isInactive}
                autoFocus
                selectedUserId={watch('cardHolderID') ?? null}
                onUserPicked={(user) => {
                  setValue('cardHolderID', user?.userID ?? undefined);
                }}
              />
            </LabelWrap>
            <LabelWrap
              label="Kortnummer"
              errorLabel={
                formState.errors.number &&
                formState.errors.number?.message?.toString()
              }
            >
              <Input
                {...register('number', {
                  required: 'Kortnummer är obligatoriskt',
                  pattern: {
                    value: /^[0-9]{17}$/,
                    message:
                      'Inkorrekt input, använd formatet 12345678901234567 (17 siffror)',
                  },
                  disabled: isInactive,
                })}
              />
            </LabelWrap>

            <LabelWrap
              label="Utgångdatum (YYMM)"
              errorLabel={
                formState.errors.expiration &&
                formState.errors.expiration?.message?.toString()
              }
            >
              <Input
                {...register('expiration', {
                  required: 'Utgångdatum är obligatoriskt',
                  pattern: {
                    value: /^[0-9]{4}$/,
                    message: 'Inkorrekt input, använd formatet MMYY',
                  },
                  disabled: isInactive,
                })}
              />
            </LabelWrap>

            <LabelWrap label="Samarbetspartner">
              <SearchableSelect
                disabled
                {...register('provider')}
                options={partnerOptions}
                onOptionClicked={(option: ExternalReceiptProvider) => {
                  console.log(option);
                }}
                selectedValue={ExternalReceiptProvider.CircleK}
                searchFilter={(searchString, opts) => {
                  const lowerNameFilter = searchString.toLowerCase();
                  return opts.filter((opt) =>
                    opt.description
                      ?.toString()
                      .toLowerCase()
                      .includes(lowerNameFilter)
                  );
                }}
              />
            </LabelWrap>
          </InputWrapper>
          {paymentCard.data && paymentCard.data.isActive && (
            <TextButton
              style={{ alignSelf: 'flex-start' }}
              onClick={() => {
                modalStack.push(ConfirmInactivationModal);
              }}
            >
              Inaktivera kort
            </TextButton>
          )}
          {isInactive && <i>Inaktivt kort</i>}
        </Section>
      </Wrapper>
    </Modal>
  );
};

export default PaymentCardFormModal;
