import React, { FC, ReactChild } from 'react';
import styled, { css } from 'styled-components';

const Table = styled.div<{ keyWidth?: string; valueWidth?: string }>`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 5px 10px;

  ${({ keyWidth }) =>
    keyWidth
      ? css`
          b {
            min-width: ${keyWidth};
          }
        `
      : ''}

  ${({ valueWidth }) =>
    valueWidth
      ? css`
          span {
            min-width: ${valueWidth};
          }
        `
      : ''}
`;

type KeyAlign = 'middle' | 'top' | 'bottom';
const Key = styled.b<{ verticalAlign?: KeyAlign; disabled?: boolean }>`
  font: inherit;
  font-weight: 500;
  text-align: start;
  align-self: ${({ verticalAlign }) => {
    switch (verticalAlign) {
      case 'bottom':
        return 'flex-end';
      case 'top':
        return 'flex-start';

      case 'middle':
      default:
        return 'center';
    }
  }};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

const Value = styled.span<{ disabled?: boolean }>`
  font: inherit;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

export interface KeyListRow {
  key: string;
  value?: ReactChild;
  /** defaults to 'middle' */
  keyAlign?: KeyAlign;
  disabled?: boolean;
}

interface Props {
  rows: KeyListRow[];
  colonKey?: boolean;
  className?: string;
  /** css string such as '1px' */
  keyWidth?: string;
  /** css string such as '1px' */
  valueWidth?: string;
}

const KeyValueList: FC<Props> = ({
  rows,
  colonKey,
  className,
  keyWidth,
  valueWidth,
}) => (
  <Table className={className} keyWidth={keyWidth} valueWidth={valueWidth}>
    {rows.map((row) => (
      <React.Fragment key={row.key}>
        <Key disabled={row.disabled} verticalAlign={row.keyAlign}>
          {row.key}
          {colonKey ? ':' : ''}
        </Key>
        <Value disabled={row.disabled}>{row.value}</Value>
      </React.Fragment>
    ))}
  </Table>
);

export default KeyValueList;
