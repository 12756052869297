/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AdvancedAssignmentFilterOption,
  AssignmentAddStandardizedCommentParams,
  AssignmentCreateStandardizedCommentParams,
  AssignmentDataLookupRecord,
  AssignmentDeleteStandardizedCommentParams,
  AssignmentExecutePredefinedActionParams,
  AssignmentExtendedModel,
  AssignmentFindAssignmentByIDParams,
  AssignmentGetActiveAssignmentByCaseTypeAndRegNrParams,
  AssignmentGetActiveAssignmentsForUserParams,
  AssignmentGetAllLivePositionsParams,
  AssignmentGetAssignmentByIdParams,
  AssignmentGetAssignmentParams,
  AssignmentGetAssignmentRequestsParams,
  AssignmentGetAssignmentsByDateParams,
  AssignmentGetAssignmentsForUserParams,
  AssignmentGetBookedAssignmentsForUserAndDateParams,
  AssignmentGetCaseAssignmentPositionsForAssignmentParams,
  AssignmentGetDerivedEstimatedStartTimeParams,
  AssignmentGetFollowUpAssignmentCandidatesParams,
  AssignmentGetHoursWorkedParams,
  AssignmentGetLastCaseAssignmentPositionForAssignmentParams,
  AssignmentGetLivePositionForAssignmentParams,
  AssignmentGetPlannedAssignmentsToSendToFieldTestersCountsParams,
  AssignmentGetPlannedAssignmentsToSendToFieldTestersParams,
  AssignmentGetUnassignedFurtherTransportAssignmentByRegNrParams,
  AssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaParams,
  AssignmentMarkAssignmentInNeedOfHandlingAsHandledParams,
  AssignmentModel,
  AssignmentRemoveStandardizedCommentParams,
  AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
  AssignmentSendPlannedAssignmentsToFieldTestersParams,
  AssignmentSetAssignmentToFollowUpParams,
  AssignmentSortFieldTestersForNewAssignmentParams,
  AssignmentSyncSpeedLimitDataParams,
  AssignmentUpdateAssignmentParams,
  AssignmentUpdateAssignmentStatusParams,
  AssignmentUpdateStandardizedCommentParams,
  AssignmentViewModel,
  AssignmentViewModelBody,
  AssignmentViewModelSearchResult,
  AssignmentsToSendCountDto,
  CaseModel,
  ConnectedAssignmentsResponse,
  DetailedStandardizedCommentDto,
  DeviationReportRequest,
  EventModel,
  ExtendedPosition,
  GeoEnabledUpdateAssignmentRequest,
  GetAssignmentsRequest,
  GetFinishedAssignmentsRequest,
  Position,
  SearchAssignmentsRequestBody,
  SortResult,
  StopOverRequest,
  StopOverRequestWithGeolocation,
  UpdateAssignmentStatusRequest,
  WorkedHours,
} from '.././model';
import useAssignmentUpdateAssignmentMutator from '.././mutator/custom-instance';
import useAssignmentUpdateAssignmentWithGeolocationMutator from '.././mutator/custom-instance';
import useAssignmentUpdateAssignmentStatusMutator from '.././mutator/custom-instance';
import useAssignmentUpdateAssignmentStatusWithGeolocationMutator from '.././mutator/custom-instance';
import useAssignmentSetAssignmentToStopOverMutator from '.././mutator/custom-instance';
import useAssignmentGetMyEligibleStopoverAssignmentsMutator from '.././mutator/custom-instance';
import useAssignmentGetLivePositionForAssignmentMutator from '.././mutator/custom-instance';
import useAssignmentGetCaseAssignmentPositionsForAssignmentMutator from '.././mutator/custom-instance';
import useAssignmentSyncSpeedLimitDataMutator from '.././mutator/custom-instance';
import useAssignmentGetLastCaseAssignmentPositionForAssignmentMutator from '.././mutator/custom-instance';
import useAssignmentGetAllLivePositionsMutator from '.././mutator/custom-instance';
import useAssignmentSetAssignmentToStopOverWithGeolocationMutator from '.././mutator/custom-instance';
import useAssignmentApproveMultipleAssignmentsMutator from '.././mutator/custom-instance';
import useAssignmentGetExtendedAssignmentsMutator from '.././mutator/custom-instance';
import useAssignmentSearchAssignmentMutator from '.././mutator/custom-instance';
import useAssignmentSearchAssignmentWithPagingMutator from '.././mutator/custom-instance';
import useAssignmentSearchAllAssignmentsNoPagingMutator from '.././mutator/custom-instance';
import useAssignmentGetAssignmentMutator from '.././mutator/custom-instance';
import useAssignmentFindAssignmentByIDMutator from '.././mutator/custom-instance';
import useAssignmentGetAssignmentsForUserMutator from '.././mutator/custom-instance';
import useAssignmentGetActiveAssignmentsForUserMutator from '.././mutator/custom-instance';
import useAssignmentGetFinishedAssignmentsForUserMutator from '.././mutator/custom-instance';
import useAssignmentGetBookedAssignmentsForUserAndDateMutator from '.././mutator/custom-instance';
import useAssignmentGetHoursWorkedMutator from '.././mutator/custom-instance';
import useAssignmentGetAssignmentByIdMutator from '.././mutator/custom-instance';
import useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrMutator from '.././mutator/custom-instance';
import useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaMutator from '.././mutator/custom-instance';
import useAssignmentGetActiveAssignmentByCaseTypeAndRegNrMutator from '.././mutator/custom-instance';
import useAssignmentGetDerivedEstimatedStartTimeMutator from '.././mutator/custom-instance';
import useAssignmentGetAssignmentsByDateMutator from '.././mutator/custom-instance';
import useAssignmentGetListMutator from '.././mutator/custom-instance';
import useAssignmentGetAssignmentsWithNewDataFromRideMutator from '.././mutator/custom-instance';
import useAssignmentGetAssignmentsWithNewDataFromRideCountMutator from '.././mutator/custom-instance';
import useAssignmentGetFurtherTransportAssignmentsMutator from '.././mutator/custom-instance';
import useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsMutator from '.././mutator/custom-instance';
import useAssignmentGetPlannedAssignmentsToSendToFieldTestersMutator from '.././mutator/custom-instance';
import useAssignmentSendPlannedAssignmentsToFieldTestersMutator from '.././mutator/custom-instance';
import useAssignmentGetFollowUpAssignmentCandidatesMutator from '.././mutator/custom-instance';
import useAssignmentSetAssignmentToFollowUpMutator from '.././mutator/custom-instance';
import useAssignmentSortFieldTestersForNewAssignmentMutator from '.././mutator/custom-instance';
import useAssignmentExecutePredefinedActionMutator from '.././mutator/custom-instance';
import useAssignmentAddStandardizedCommentMutator from '.././mutator/custom-instance';
import useAssignmentRemoveStandardizedCommentMutator from '.././mutator/custom-instance';
import useAssignmentCreateStandardizedCommentMutator from '.././mutator/custom-instance';
import useAssignmentGetAllDetailedStandardizedCommentsMutator from '.././mutator/custom-instance';
import useAssignmentDeleteStandardizedCommentMutator from '.././mutator/custom-instance';
import useAssignmentUpdateStandardizedCommentMutator from '.././mutator/custom-instance';
import useAssignmentLeaveDeviationReportMutator from '.././mutator/custom-instance';
import useAssignmentGetAssignmentRequestsMutator from '.././mutator/custom-instance';
import useAssignmentMarkAssignmentInNeedOfHandlingAsHandledMutator from '.././mutator/custom-instance';
import useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeMutator from '.././mutator/custom-instance';
import useAssignmentAutoApproveAssignmentsMutator from '.././mutator/custom-instance';
import useAssignmentGetAutoApproveAssignmentsCountMutator from '.././mutator/custom-instance';

export const useAssignmentUpdateAssignmentHook = () => {
  const assignmentUpdateAssignment =
    useAssignmentUpdateAssignmentMutator<void>();

  return (
    assignmentViewModelBody: AssignmentViewModelBody,
    params?: AssignmentUpdateAssignmentParams
  ) => {
    return assignmentUpdateAssignment({
      url: `/api/Assignment/UpdateAssignment`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentViewModelBody,
      params,
    });
  };
};

export const useAssignmentUpdateAssignmentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentHook>>>,
    TError,
    {
      data: AssignmentViewModelBody;
      params?: AssignmentUpdateAssignmentParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentHook>>>,
  TError,
  { data: AssignmentViewModelBody; params?: AssignmentUpdateAssignmentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentUpdateAssignment = useAssignmentUpdateAssignmentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentHook>>>,
    { data: AssignmentViewModelBody; params?: AssignmentUpdateAssignmentParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return assignmentUpdateAssignment(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentUpdateAssignmentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentHook>>>
>;
export type AssignmentUpdateAssignmentMutationBody = AssignmentViewModelBody;
export type AssignmentUpdateAssignmentMutationError = unknown;

export const useAssignmentUpdateAssignment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentHook>>>,
    TError,
    {
      data: AssignmentViewModelBody;
      params?: AssignmentUpdateAssignmentParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentHook>>>,
  TError,
  { data: AssignmentViewModelBody; params?: AssignmentUpdateAssignmentParams },
  TContext
> => {
  const mutationOptions = useAssignmentUpdateAssignmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentUpdateAssignmentWithGeolocationHook = () => {
  const assignmentUpdateAssignmentWithGeolocation =
    useAssignmentUpdateAssignmentWithGeolocationMutator<void>();

  return (
    geoEnabledUpdateAssignmentRequest: GeoEnabledUpdateAssignmentRequest
  ) => {
    return assignmentUpdateAssignmentWithGeolocation({
      url: `/api/Assignment/UpdateAssignmentWithGeolocation`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: geoEnabledUpdateAssignmentRequest,
    });
  };
};

export const useAssignmentUpdateAssignmentWithGeolocationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentUpdateAssignmentWithGeolocationHook>
      >
    >,
    TError,
    { data: GeoEnabledUpdateAssignmentRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentUpdateAssignmentWithGeolocationHook>
    >
  >,
  TError,
  { data: GeoEnabledUpdateAssignmentRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentUpdateAssignmentWithGeolocation =
    useAssignmentUpdateAssignmentWithGeolocationHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentUpdateAssignmentWithGeolocationHook>
      >
    >,
    { data: GeoEnabledUpdateAssignmentRequest }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentUpdateAssignmentWithGeolocation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentUpdateAssignmentWithGeolocationMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentUpdateAssignmentWithGeolocationHook>
      >
    >
  >;
export type AssignmentUpdateAssignmentWithGeolocationMutationBody =
  GeoEnabledUpdateAssignmentRequest;
export type AssignmentUpdateAssignmentWithGeolocationMutationError = unknown;

export const useAssignmentUpdateAssignmentWithGeolocation = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentUpdateAssignmentWithGeolocationHook>
      >
    >,
    TError,
    { data: GeoEnabledUpdateAssignmentRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentUpdateAssignmentWithGeolocationHook>
    >
  >,
  TError,
  { data: GeoEnabledUpdateAssignmentRequest },
  TContext
> => {
  const mutationOptions =
    useAssignmentUpdateAssignmentWithGeolocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentUpdateAssignmentStatusHook = () => {
  const assignmentUpdateAssignmentStatus =
    useAssignmentUpdateAssignmentStatusMutator<void>();

  return (
    assignmentViewModelBody: AssignmentViewModelBody,
    params?: AssignmentUpdateAssignmentStatusParams
  ) => {
    return assignmentUpdateAssignmentStatus({
      url: `/api/Assignment/UpdateAssignmentStatus`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentViewModelBody,
      params,
    });
  };
};

export const useAssignmentUpdateAssignmentStatusMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentStatusHook>>
    >,
    TError,
    {
      data: AssignmentViewModelBody;
      params?: AssignmentUpdateAssignmentStatusParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentStatusHook>>
  >,
  TError,
  {
    data: AssignmentViewModelBody;
    params?: AssignmentUpdateAssignmentStatusParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentUpdateAssignmentStatus =
    useAssignmentUpdateAssignmentStatusHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentStatusHook>>
    >,
    {
      data: AssignmentViewModelBody;
      params?: AssignmentUpdateAssignmentStatusParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return assignmentUpdateAssignmentStatus(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentUpdateAssignmentStatusMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentStatusHook>>
  >
>;
export type AssignmentUpdateAssignmentStatusMutationBody =
  AssignmentViewModelBody;
export type AssignmentUpdateAssignmentStatusMutationError = unknown;

export const useAssignmentUpdateAssignmentStatus = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentStatusHook>>
    >,
    TError,
    {
      data: AssignmentViewModelBody;
      params?: AssignmentUpdateAssignmentStatusParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentUpdateAssignmentStatusHook>>
  >,
  TError,
  {
    data: AssignmentViewModelBody;
    params?: AssignmentUpdateAssignmentStatusParams;
  },
  TContext
> => {
  const mutationOptions =
    useAssignmentUpdateAssignmentStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentUpdateAssignmentStatusWithGeolocationHook = () => {
  const assignmentUpdateAssignmentStatusWithGeolocation =
    useAssignmentUpdateAssignmentStatusWithGeolocationMutator<AssignmentViewModel>();

  return (updateAssignmentStatusRequest: UpdateAssignmentStatusRequest) => {
    return assignmentUpdateAssignmentStatusWithGeolocation({
      url: `/api/Assignment/UpdateAssignmentStatusWithGeolocation`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: updateAssignmentStatusRequest,
    });
  };
};

export const useAssignmentUpdateAssignmentStatusWithGeolocationMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentUpdateAssignmentStatusWithGeolocationHook
          >
        >
      >,
      TError,
      { data: UpdateAssignmentStatusRequest },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentUpdateAssignmentStatusWithGeolocationHook
        >
      >
    >,
    TError,
    { data: UpdateAssignmentStatusRequest },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentUpdateAssignmentStatusWithGeolocation =
      useAssignmentUpdateAssignmentStatusWithGeolocationHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentUpdateAssignmentStatusWithGeolocationHook
          >
        >
      >,
      { data: UpdateAssignmentStatusRequest }
    > = (props) => {
      const { data } = props ?? {};

      return assignmentUpdateAssignmentStatusWithGeolocation(data);
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentUpdateAssignmentStatusWithGeolocationMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentUpdateAssignmentStatusWithGeolocationHook
        >
      >
    >
  >;
export type AssignmentUpdateAssignmentStatusWithGeolocationMutationBody =
  UpdateAssignmentStatusRequest;
export type AssignmentUpdateAssignmentStatusWithGeolocationMutationError =
  unknown;

export const useAssignmentUpdateAssignmentStatusWithGeolocation = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentUpdateAssignmentStatusWithGeolocationHook
        >
      >
    >,
    TError,
    { data: UpdateAssignmentStatusRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentUpdateAssignmentStatusWithGeolocationHook>
    >
  >,
  TError,
  { data: UpdateAssignmentStatusRequest },
  TContext
> => {
  const mutationOptions =
    useAssignmentUpdateAssignmentStatusWithGeolocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentSetAssignmentToStopOverHook = () => {
  const assignmentSetAssignmentToStopOver =
    useAssignmentSetAssignmentToStopOverMutator<CaseModel>();

  return (stopOverRequest: StopOverRequest) => {
    return assignmentSetAssignmentToStopOver({
      url: `/api/Assignment/SetAssignmentToStopOver`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: stopOverRequest,
    });
  };
};

export const useAssignmentSetAssignmentToStopOverMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentSetAssignmentToStopOverHook>>
    >,
    TError,
    { data: StopOverRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSetAssignmentToStopOverHook>>
  >,
  TError,
  { data: StopOverRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentSetAssignmentToStopOver =
    useAssignmentSetAssignmentToStopOverHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentSetAssignmentToStopOverHook>>
    >,
    { data: StopOverRequest }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentSetAssignmentToStopOver(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentSetAssignmentToStopOverMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSetAssignmentToStopOverHook>>
  >
>;
export type AssignmentSetAssignmentToStopOverMutationBody = StopOverRequest;
export type AssignmentSetAssignmentToStopOverMutationError = unknown;

export const useAssignmentSetAssignmentToStopOver = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentSetAssignmentToStopOverHook>>
    >,
    TError,
    { data: StopOverRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSetAssignmentToStopOverHook>>
  >,
  TError,
  { data: StopOverRequest },
  TContext
> => {
  const mutationOptions =
    useAssignmentSetAssignmentToStopOverMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetMyEligibleStopoverAssignmentsHook = () => {
  const assignmentGetMyEligibleStopoverAssignments =
    useAssignmentGetMyEligibleStopoverAssignmentsMutator<
      AssignmentViewModel[]
    >();

  return (signal?: AbortSignal) => {
    return assignmentGetMyEligibleStopoverAssignments({
      url: `/api/Assignment/GetMyEligibleStopoverAssignments`,
      method: 'GET',
      signal,
    });
  };
};

export const getAssignmentGetMyEligibleStopoverAssignmentsQueryKey = () => {
  return [`/api/Assignment/GetMyEligibleStopoverAssignments`] as const;
};

export const useAssignmentGetMyEligibleStopoverAssignmentsQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetMyEligibleStopoverAssignmentsHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetMyEligibleStopoverAssignmentsHook>
      >
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentGetMyEligibleStopoverAssignmentsQueryKey();

  const assignmentGetMyEligibleStopoverAssignments =
    useAssignmentGetMyEligibleStopoverAssignmentsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetMyEligibleStopoverAssignmentsHook>
      >
    >
  > = ({ signal }) => assignmentGetMyEligibleStopoverAssignments(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetMyEligibleStopoverAssignmentsHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentGetMyEligibleStopoverAssignmentsQueryResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetMyEligibleStopoverAssignmentsHook>
    >
  >
>;
export type AssignmentGetMyEligibleStopoverAssignmentsQueryError = unknown;

export const useAssignmentGetMyEligibleStopoverAssignments = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetMyEligibleStopoverAssignmentsHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetMyEligibleStopoverAssignmentsHook>
      >
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAssignmentGetMyEligibleStopoverAssignmentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentGetLivePositionForAssignmentHook = () => {
  const assignmentGetLivePositionForAssignment =
    useAssignmentGetLivePositionForAssignmentMutator<Position>();

  return (
    params?: AssignmentGetLivePositionForAssignmentParams,
    signal?: AbortSignal
  ) => {
    return assignmentGetLivePositionForAssignment({
      url: `/api/Assignment/GetLivePositionForAssignment`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getAssignmentGetLivePositionForAssignmentQueryKey = (
  params?: AssignmentGetLivePositionForAssignmentParams
) => {
  return [
    `/api/Assignment/GetLivePositionForAssignment`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentGetLivePositionForAssignmentQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetLivePositionForAssignmentHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentGetLivePositionForAssignmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useAssignmentGetLivePositionForAssignmentHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentGetLivePositionForAssignmentQueryKey(params);

  const assignmentGetLivePositionForAssignment =
    useAssignmentGetLivePositionForAssignmentHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetLivePositionForAssignmentHook>
      >
    >
  > = ({ signal }) => assignmentGetLivePositionForAssignment(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetLivePositionForAssignmentHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentGetLivePositionForAssignmentQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetLivePositionForAssignmentHook>>
  >
>;
export type AssignmentGetLivePositionForAssignmentQueryError = unknown;

export const useAssignmentGetLivePositionForAssignment = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetLivePositionForAssignmentHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentGetLivePositionForAssignmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useAssignmentGetLivePositionForAssignmentHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAssignmentGetLivePositionForAssignmentQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentGetCaseAssignmentPositionsForAssignmentHook = () => {
  const assignmentGetCaseAssignmentPositionsForAssignment =
    useAssignmentGetCaseAssignmentPositionsForAssignmentMutator<
      ExtendedPosition[]
    >();

  return (
    params?: AssignmentGetCaseAssignmentPositionsForAssignmentParams,
    signal?: AbortSignal
  ) => {
    return assignmentGetCaseAssignmentPositionsForAssignment({
      url: `/api/Assignment/GetCaseAssignmentPositionsForAssignment`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getAssignmentGetCaseAssignmentPositionsForAssignmentQueryKey = (
  params?: AssignmentGetCaseAssignmentPositionsForAssignmentParams
) => {
  return [
    `/api/Assignment/GetCaseAssignmentPositionsForAssignment`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentGetCaseAssignmentPositionsForAssignmentQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetCaseAssignmentPositionsForAssignmentHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: AssignmentGetCaseAssignmentPositionsForAssignmentParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useAssignmentGetCaseAssignmentPositionsForAssignmentHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getAssignmentGetCaseAssignmentPositionsForAssignmentQueryKey(params);

    const assignmentGetCaseAssignmentPositionsForAssignment =
      useAssignmentGetCaseAssignmentPositionsForAssignmentHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetCaseAssignmentPositionsForAssignmentHook
          >
        >
      >
    > = ({ signal }) =>
      assignmentGetCaseAssignmentPositionsForAssignment(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetCaseAssignmentPositionsForAssignmentHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type AssignmentGetCaseAssignmentPositionsForAssignmentQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetCaseAssignmentPositionsForAssignmentHook
        >
      >
    >
  >;
export type AssignmentGetCaseAssignmentPositionsForAssignmentQueryError =
  unknown;

export const useAssignmentGetCaseAssignmentPositionsForAssignment = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useAssignmentGetCaseAssignmentPositionsForAssignmentHook
      >
    >
  >,
  TError = unknown,
>(
  params?: AssignmentGetCaseAssignmentPositionsForAssignmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetCaseAssignmentPositionsForAssignmentHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAssignmentGetCaseAssignmentPositionsForAssignmentQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentSyncSpeedLimitDataHook = () => {
  const assignmentSyncSpeedLimitData =
    useAssignmentSyncSpeedLimitDataMutator<void>();

  return (params?: AssignmentSyncSpeedLimitDataParams) => {
    return assignmentSyncSpeedLimitData({
      url: `/api/Assignment/SyncSpeedLimitData`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentSyncSpeedLimitDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentSyncSpeedLimitDataHook>>>,
    TError,
    { params?: AssignmentSyncSpeedLimitDataParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAssignmentSyncSpeedLimitDataHook>>>,
  TError,
  { params?: AssignmentSyncSpeedLimitDataParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentSyncSpeedLimitData = useAssignmentSyncSpeedLimitDataHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignmentSyncSpeedLimitDataHook>>>,
    { params?: AssignmentSyncSpeedLimitDataParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentSyncSpeedLimitData(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentSyncSpeedLimitDataMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentSyncSpeedLimitDataHook>>>
>;

export type AssignmentSyncSpeedLimitDataMutationError = unknown;

export const useAssignmentSyncSpeedLimitData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentSyncSpeedLimitDataHook>>>,
    TError,
    { params?: AssignmentSyncSpeedLimitDataParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAssignmentSyncSpeedLimitDataHook>>>,
  TError,
  { params?: AssignmentSyncSpeedLimitDataParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentSyncSpeedLimitDataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetLastCaseAssignmentPositionForAssignmentHook =
  () => {
    const assignmentGetLastCaseAssignmentPositionForAssignment =
      useAssignmentGetLastCaseAssignmentPositionForAssignmentMutator<ExtendedPosition>();

    return (
      params?: AssignmentGetLastCaseAssignmentPositionForAssignmentParams,
      signal?: AbortSignal
    ) => {
      return assignmentGetLastCaseAssignmentPositionForAssignment({
        url: `/api/Assignment/GetLastCaseAssignmentPositionForAssignment`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getAssignmentGetLastCaseAssignmentPositionForAssignmentQueryKey = (
  params?: AssignmentGetLastCaseAssignmentPositionForAssignmentParams
) => {
  return [
    `/api/Assignment/GetLastCaseAssignmentPositionForAssignment`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentGetLastCaseAssignmentPositionForAssignmentQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetLastCaseAssignmentPositionForAssignmentHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: AssignmentGetLastCaseAssignmentPositionForAssignmentParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useAssignmentGetLastCaseAssignmentPositionForAssignmentHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getAssignmentGetLastCaseAssignmentPositionForAssignmentQueryKey(params);

    const assignmentGetLastCaseAssignmentPositionForAssignment =
      useAssignmentGetLastCaseAssignmentPositionForAssignmentHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetLastCaseAssignmentPositionForAssignmentHook
          >
        >
      >
    > = ({ signal }) =>
      assignmentGetLastCaseAssignmentPositionForAssignment(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetLastCaseAssignmentPositionForAssignmentHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type AssignmentGetLastCaseAssignmentPositionForAssignmentQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetLastCaseAssignmentPositionForAssignmentHook
        >
      >
    >
  >;
export type AssignmentGetLastCaseAssignmentPositionForAssignmentQueryError =
  unknown;

export const useAssignmentGetLastCaseAssignmentPositionForAssignment = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useAssignmentGetLastCaseAssignmentPositionForAssignmentHook
      >
    >
  >,
  TError = unknown,
>(
  params?: AssignmentGetLastCaseAssignmentPositionForAssignmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetLastCaseAssignmentPositionForAssignmentHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAssignmentGetLastCaseAssignmentPositionForAssignmentQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentGetAllLivePositionsHook = () => {
  const assignmentGetAllLivePositions =
    useAssignmentGetAllLivePositionsMutator<ExtendedPosition[]>();

  return (
    params?: AssignmentGetAllLivePositionsParams,
    signal?: AbortSignal
  ) => {
    return assignmentGetAllLivePositions({
      url: `/api/Assignment/GetAllLivePositions`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getAssignmentGetAllLivePositionsQueryKey = (
  params?: AssignmentGetAllLivePositionsParams
) => {
  return [
    `/api/Assignment/GetAllLivePositions`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentGetAllLivePositionsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetAllLivePositionsHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentGetAllLivePositionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useAssignmentGetAllLivePositionsHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssignmentGetAllLivePositionsQueryKey(params);

  const assignmentGetAllLivePositions = useAssignmentGetAllLivePositionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetAllLivePositionsHook>>>
  > = ({ signal }) => assignmentGetAllLivePositions(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetAllLivePositionsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentGetAllLivePositionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetAllLivePositionsHook>>>
>;
export type AssignmentGetAllLivePositionsQueryError = unknown;

export const useAssignmentGetAllLivePositions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetAllLivePositionsHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentGetAllLivePositionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useAssignmentGetAllLivePositionsHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAssignmentGetAllLivePositionsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentSetAssignmentToStopOverWithGeolocationHook = () => {
  const assignmentSetAssignmentToStopOverWithGeolocation =
    useAssignmentSetAssignmentToStopOverWithGeolocationMutator<void>();

  return (stopOverRequestWithGeolocation: StopOverRequestWithGeolocation) => {
    return assignmentSetAssignmentToStopOverWithGeolocation({
      url: `/api/Assignment/SetAssignmentToStopOverWithGeolocation`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: stopOverRequestWithGeolocation,
    });
  };
};

export const useAssignmentSetAssignmentToStopOverWithGeolocationMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentSetAssignmentToStopOverWithGeolocationHook
          >
        >
      >,
      TError,
      { data: StopOverRequestWithGeolocation },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentSetAssignmentToStopOverWithGeolocationHook
        >
      >
    >,
    TError,
    { data: StopOverRequestWithGeolocation },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentSetAssignmentToStopOverWithGeolocation =
      useAssignmentSetAssignmentToStopOverWithGeolocationHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentSetAssignmentToStopOverWithGeolocationHook
          >
        >
      >,
      { data: StopOverRequestWithGeolocation }
    > = (props) => {
      const { data } = props ?? {};

      return assignmentSetAssignmentToStopOverWithGeolocation(data);
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentSetAssignmentToStopOverWithGeolocationMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentSetAssignmentToStopOverWithGeolocationHook
        >
      >
    >
  >;
export type AssignmentSetAssignmentToStopOverWithGeolocationMutationBody =
  StopOverRequestWithGeolocation;
export type AssignmentSetAssignmentToStopOverWithGeolocationMutationError =
  unknown;

export const useAssignmentSetAssignmentToStopOverWithGeolocation = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentSetAssignmentToStopOverWithGeolocationHook
        >
      >
    >,
    TError,
    { data: StopOverRequestWithGeolocation },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentSetAssignmentToStopOverWithGeolocationHook>
    >
  >,
  TError,
  { data: StopOverRequestWithGeolocation },
  TContext
> => {
  const mutationOptions =
    useAssignmentSetAssignmentToStopOverWithGeolocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentApproveMultipleAssignmentsHook = () => {
  const assignmentApproveMultipleAssignments =
    useAssignmentApproveMultipleAssignmentsMutator<string[]>();

  return (assignmentViewModel: AssignmentViewModel[]) => {
    return assignmentApproveMultipleAssignments({
      url: `/api/Assignment/ApproveMultipleAssignments`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentViewModel,
    });
  };
};

export const useAssignmentApproveMultipleAssignmentsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentApproveMultipleAssignmentsHook>>
    >,
    TError,
    { data: AssignmentViewModel[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentApproveMultipleAssignmentsHook>>
  >,
  TError,
  { data: AssignmentViewModel[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentApproveMultipleAssignments =
    useAssignmentApproveMultipleAssignmentsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentApproveMultipleAssignmentsHook>>
    >,
    { data: AssignmentViewModel[] }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentApproveMultipleAssignments(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentApproveMultipleAssignmentsMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentApproveMultipleAssignmentsHook>>
  >
>;
export type AssignmentApproveMultipleAssignmentsMutationBody =
  AssignmentViewModel[];
export type AssignmentApproveMultipleAssignmentsMutationError = unknown;

export const useAssignmentApproveMultipleAssignments = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentApproveMultipleAssignmentsHook>>
    >,
    TError,
    { data: AssignmentViewModel[] },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentApproveMultipleAssignmentsHook>>
  >,
  TError,
  { data: AssignmentViewModel[] },
  TContext
> => {
  const mutationOptions =
    useAssignmentApproveMultipleAssignmentsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetExtendedAssignmentsHook = () => {
  const assignmentGetExtendedAssignments =
    useAssignmentGetExtendedAssignmentsMutator<AssignmentExtendedModel[]>();

  return (
    assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody
  ) => {
    return assignmentGetExtendedAssignments({
      url: `/api/Assignment/GetExtendedAssignments`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
    });
  };
};

export const useAssignmentGetExtendedAssignmentsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetExtendedAssignmentsHook>>
    >,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetExtendedAssignmentsHook>>
  >,
  TError,
  { data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentGetExtendedAssignments =
    useAssignmentGetExtendedAssignmentsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetExtendedAssignmentsHook>>
    >,
    { data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentGetExtendedAssignments(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentGetExtendedAssignmentsMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetExtendedAssignmentsHook>>
  >
>;
export type AssignmentGetExtendedAssignmentsMutationBody =
  AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
export type AssignmentGetExtendedAssignmentsMutationError = unknown;

export const useAssignmentGetExtendedAssignments = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetExtendedAssignmentsHook>>
    >,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetExtendedAssignmentsHook>>
  >,
  TError,
  { data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody },
  TContext
> => {
  const mutationOptions =
    useAssignmentGetExtendedAssignmentsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentSearchAssignmentHook = () => {
  const assignmentSearchAssignment =
    useAssignmentSearchAssignmentMutator<AssignmentViewModel[]>();

  return (searchAssignmentsRequestBody: SearchAssignmentsRequestBody) => {
    return assignmentSearchAssignment({
      url: `/api/Assignment/SearchAssignment`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: searchAssignmentsRequestBody,
    });
  };
};

export const useAssignmentSearchAssignmentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentSearchAssignmentHook>>>,
    TError,
    { data: SearchAssignmentsRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAssignmentSearchAssignmentHook>>>,
  TError,
  { data: SearchAssignmentsRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentSearchAssignment = useAssignmentSearchAssignmentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignmentSearchAssignmentHook>>>,
    { data: SearchAssignmentsRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentSearchAssignment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentSearchAssignmentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentSearchAssignmentHook>>>
>;
export type AssignmentSearchAssignmentMutationBody =
  SearchAssignmentsRequestBody;
export type AssignmentSearchAssignmentMutationError = unknown;

export const useAssignmentSearchAssignment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentSearchAssignmentHook>>>,
    TError,
    { data: SearchAssignmentsRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAssignmentSearchAssignmentHook>>>,
  TError,
  { data: SearchAssignmentsRequestBody },
  TContext
> => {
  const mutationOptions = useAssignmentSearchAssignmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentSearchAssignmentWithPagingHook = () => {
  const assignmentSearchAssignmentWithPaging =
    useAssignmentSearchAssignmentWithPagingMutator<AssignmentViewModelSearchResult>();

  return (searchAssignmentsRequestBody: SearchAssignmentsRequestBody) => {
    return assignmentSearchAssignmentWithPaging({
      url: `/api/Assignment/SearchAssignmentWithPaging`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: searchAssignmentsRequestBody,
    });
  };
};

export const useAssignmentSearchAssignmentWithPagingMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentSearchAssignmentWithPagingHook>>
    >,
    TError,
    { data: SearchAssignmentsRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSearchAssignmentWithPagingHook>>
  >,
  TError,
  { data: SearchAssignmentsRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentSearchAssignmentWithPaging =
    useAssignmentSearchAssignmentWithPagingHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentSearchAssignmentWithPagingHook>>
    >,
    { data: SearchAssignmentsRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentSearchAssignmentWithPaging(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentSearchAssignmentWithPagingMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSearchAssignmentWithPagingHook>>
  >
>;
export type AssignmentSearchAssignmentWithPagingMutationBody =
  SearchAssignmentsRequestBody;
export type AssignmentSearchAssignmentWithPagingMutationError = unknown;

export const useAssignmentSearchAssignmentWithPaging = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentSearchAssignmentWithPagingHook>>
    >,
    TError,
    { data: SearchAssignmentsRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSearchAssignmentWithPagingHook>>
  >,
  TError,
  { data: SearchAssignmentsRequestBody },
  TContext
> => {
  const mutationOptions =
    useAssignmentSearchAssignmentWithPagingMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentSearchAllAssignmentsNoPagingHook = () => {
  const assignmentSearchAllAssignmentsNoPaging =
    useAssignmentSearchAllAssignmentsNoPagingMutator<AssignmentViewModel[]>();

  return (searchAssignmentsRequestBody: SearchAssignmentsRequestBody) => {
    return assignmentSearchAllAssignmentsNoPaging({
      url: `/api/Assignment/SearchAllAssignmentsNoPaging`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: searchAssignmentsRequestBody,
    });
  };
};

export const useAssignmentSearchAllAssignmentsNoPagingMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentSearchAllAssignmentsNoPagingHook>
      >
    >,
    TError,
    { data: SearchAssignmentsRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSearchAllAssignmentsNoPagingHook>>
  >,
  TError,
  { data: SearchAssignmentsRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentSearchAllAssignmentsNoPaging =
    useAssignmentSearchAllAssignmentsNoPagingHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentSearchAllAssignmentsNoPagingHook>
      >
    >,
    { data: SearchAssignmentsRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentSearchAllAssignmentsNoPaging(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentSearchAllAssignmentsNoPagingMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSearchAllAssignmentsNoPagingHook>>
  >
>;
export type AssignmentSearchAllAssignmentsNoPagingMutationBody =
  SearchAssignmentsRequestBody;
export type AssignmentSearchAllAssignmentsNoPagingMutationError = unknown;

export const useAssignmentSearchAllAssignmentsNoPaging = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentSearchAllAssignmentsNoPagingHook>
      >
    >,
    TError,
    { data: SearchAssignmentsRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSearchAllAssignmentsNoPagingHook>>
  >,
  TError,
  { data: SearchAssignmentsRequestBody },
  TContext
> => {
  const mutationOptions =
    useAssignmentSearchAllAssignmentsNoPagingMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const useAssignmentGetAssignmentHook = () => {
  const assignmentGetAssignment =
    useAssignmentGetAssignmentMutator<AssignmentExtendedModel>();

  return (params?: AssignmentGetAssignmentParams) => {
    return assignmentGetAssignment({
      url: `/api/Assignment/GetAssignment`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentGetAssignmentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentHook>>>,
    TError,
    { params?: AssignmentGetAssignmentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentHook>>>,
  TError,
  { params?: AssignmentGetAssignmentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentGetAssignment = useAssignmentGetAssignmentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentHook>>>,
    { params?: AssignmentGetAssignmentParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentGetAssignment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentGetAssignmentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentHook>>>
>;

export type AssignmentGetAssignmentMutationError = unknown;

/**
 * @deprecated
 */
export const useAssignmentGetAssignment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentHook>>>,
    TError,
    { params?: AssignmentGetAssignmentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentHook>>>,
  TError,
  { params?: AssignmentGetAssignmentParams },
  TContext
> => {
  const mutationOptions = useAssignmentGetAssignmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentFindAssignmentByIDHook = () => {
  const assignmentFindAssignmentByID =
    useAssignmentFindAssignmentByIDMutator<AssignmentExtendedModel>();

  return (
    params?: AssignmentFindAssignmentByIDParams,
    signal?: AbortSignal
  ) => {
    return assignmentFindAssignmentByID({
      url: `/api/Assignment/FindAssignmentByID`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getAssignmentFindAssignmentByIDQueryKey = (
  params?: AssignmentFindAssignmentByIDParams
) => {
  return [
    `/api/Assignment/FindAssignmentByID`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentFindAssignmentByIDQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentFindAssignmentByIDHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentFindAssignmentByIDParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useAssignmentFindAssignmentByIDHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssignmentFindAssignmentByIDQueryKey(params);

  const assignmentFindAssignmentByID = useAssignmentFindAssignmentByIDHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignmentFindAssignmentByIDHook>>>
  > = ({ signal }) => assignmentFindAssignmentByID(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentFindAssignmentByIDHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentFindAssignmentByIDQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentFindAssignmentByIDHook>>>
>;
export type AssignmentFindAssignmentByIDQueryError = unknown;

export const useAssignmentFindAssignmentByID = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentFindAssignmentByIDHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentFindAssignmentByIDParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useAssignmentFindAssignmentByIDHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAssignmentFindAssignmentByIDQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentGetAssignmentsForUserHook = () => {
  const assignmentGetAssignmentsForUser =
    useAssignmentGetAssignmentsForUserMutator<AssignmentViewModel[]>();

  return (params?: AssignmentGetAssignmentsForUserParams) => {
    return assignmentGetAssignmentsForUser({
      url: `/api/Assignment/GetAssignmentsForUser`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentGetAssignmentsForUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetAssignmentsForUserHook>>
    >,
    TError,
    { params?: AssignmentGetAssignmentsForUserParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetAssignmentsForUserHook>>
  >,
  TError,
  { params?: AssignmentGetAssignmentsForUserParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentGetAssignmentsForUser =
    useAssignmentGetAssignmentsForUserHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetAssignmentsForUserHook>>
    >,
    { params?: AssignmentGetAssignmentsForUserParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentGetAssignmentsForUser(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentGetAssignmentsForUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentsForUserHook>>>
>;

export type AssignmentGetAssignmentsForUserMutationError = unknown;

export const useAssignmentGetAssignmentsForUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetAssignmentsForUserHook>>
    >,
    TError,
    { params?: AssignmentGetAssignmentsForUserParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetAssignmentsForUserHook>>
  >,
  TError,
  { params?: AssignmentGetAssignmentsForUserParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentGetAssignmentsForUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetActiveAssignmentsForUserHook = () => {
  const assignmentGetActiveAssignmentsForUser =
    useAssignmentGetActiveAssignmentsForUserMutator<AssignmentViewModel[]>();

  return (params?: AssignmentGetActiveAssignmentsForUserParams) => {
    return assignmentGetActiveAssignmentsForUser({
      url: `/api/Assignment/GetActiveAssignmentsForUser`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentGetActiveAssignmentsForUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetActiveAssignmentsForUserHook>
      >
    >,
    TError,
    { params?: AssignmentGetActiveAssignmentsForUserParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetActiveAssignmentsForUserHook>>
  >,
  TError,
  { params?: AssignmentGetActiveAssignmentsForUserParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentGetActiveAssignmentsForUser =
    useAssignmentGetActiveAssignmentsForUserHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetActiveAssignmentsForUserHook>
      >
    >,
    { params?: AssignmentGetActiveAssignmentsForUserParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentGetActiveAssignmentsForUser(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentGetActiveAssignmentsForUserMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetActiveAssignmentsForUserHook>>
  >
>;

export type AssignmentGetActiveAssignmentsForUserMutationError = unknown;

export const useAssignmentGetActiveAssignmentsForUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetActiveAssignmentsForUserHook>
      >
    >,
    TError,
    { params?: AssignmentGetActiveAssignmentsForUserParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetActiveAssignmentsForUserHook>>
  >,
  TError,
  { params?: AssignmentGetActiveAssignmentsForUserParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentGetActiveAssignmentsForUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetFinishedAssignmentsForUserHook = () => {
  const assignmentGetFinishedAssignmentsForUser =
    useAssignmentGetFinishedAssignmentsForUserMutator<AssignmentViewModel[]>();

  return (getFinishedAssignmentsRequest: GetFinishedAssignmentsRequest) => {
    return assignmentGetFinishedAssignmentsForUser({
      url: `/api/Assignment/GetFinishedAssignmentsForUser`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getFinishedAssignmentsRequest,
    });
  };
};

export const useAssignmentGetFinishedAssignmentsForUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetFinishedAssignmentsForUserHook>
      >
    >,
    TError,
    { data: GetFinishedAssignmentsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetFinishedAssignmentsForUserHook>
    >
  >,
  TError,
  { data: GetFinishedAssignmentsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentGetFinishedAssignmentsForUser =
    useAssignmentGetFinishedAssignmentsForUserHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetFinishedAssignmentsForUserHook>
      >
    >,
    { data: GetFinishedAssignmentsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentGetFinishedAssignmentsForUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentGetFinishedAssignmentsForUserMutationResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetFinishedAssignmentsForUserHook>
    >
  >
>;
export type AssignmentGetFinishedAssignmentsForUserMutationBody =
  GetFinishedAssignmentsRequest;
export type AssignmentGetFinishedAssignmentsForUserMutationError = unknown;

export const useAssignmentGetFinishedAssignmentsForUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetFinishedAssignmentsForUserHook>
      >
    >,
    TError,
    { data: GetFinishedAssignmentsRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetFinishedAssignmentsForUserHook>
    >
  >,
  TError,
  { data: GetFinishedAssignmentsRequest },
  TContext
> => {
  const mutationOptions =
    useAssignmentGetFinishedAssignmentsForUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetBookedAssignmentsForUserAndDateHook = () => {
  const assignmentGetBookedAssignmentsForUserAndDate =
    useAssignmentGetBookedAssignmentsForUserAndDateMutator<
      AssignmentViewModel[]
    >();

  return (params?: AssignmentGetBookedAssignmentsForUserAndDateParams) => {
    return assignmentGetBookedAssignmentsForUserAndDate({
      url: `/api/Assignment/GetBookedAssignmentsForUserAndDate`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentGetBookedAssignmentsForUserAndDateMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetBookedAssignmentsForUserAndDateHook>
      >
    >,
    TError,
    { params?: AssignmentGetBookedAssignmentsForUserAndDateParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetBookedAssignmentsForUserAndDateHook>
    >
  >,
  TError,
  { params?: AssignmentGetBookedAssignmentsForUserAndDateParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentGetBookedAssignmentsForUserAndDate =
    useAssignmentGetBookedAssignmentsForUserAndDateHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetBookedAssignmentsForUserAndDateHook>
      >
    >,
    { params?: AssignmentGetBookedAssignmentsForUserAndDateParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentGetBookedAssignmentsForUserAndDate(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentGetBookedAssignmentsForUserAndDateMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetBookedAssignmentsForUserAndDateHook>
      >
    >
  >;

export type AssignmentGetBookedAssignmentsForUserAndDateMutationError = unknown;

export const useAssignmentGetBookedAssignmentsForUserAndDate = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetBookedAssignmentsForUserAndDateHook>
      >
    >,
    TError,
    { params?: AssignmentGetBookedAssignmentsForUserAndDateParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetBookedAssignmentsForUserAndDateHook>
    >
  >,
  TError,
  { params?: AssignmentGetBookedAssignmentsForUserAndDateParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentGetBookedAssignmentsForUserAndDateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetHoursWorkedHook = () => {
  const assignmentGetHoursWorked =
    useAssignmentGetHoursWorkedMutator<WorkedHours>();

  return (params?: AssignmentGetHoursWorkedParams) => {
    return assignmentGetHoursWorked({
      url: `/api/Assignment/GetHoursWorked`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentGetHoursWorkedMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetHoursWorkedHook>>>,
    TError,
    { params?: AssignmentGetHoursWorkedParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetHoursWorkedHook>>>,
  TError,
  { params?: AssignmentGetHoursWorkedParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentGetHoursWorked = useAssignmentGetHoursWorkedHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetHoursWorkedHook>>>,
    { params?: AssignmentGetHoursWorkedParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentGetHoursWorked(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentGetHoursWorkedMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetHoursWorkedHook>>>
>;

export type AssignmentGetHoursWorkedMutationError = unknown;

export const useAssignmentGetHoursWorked = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetHoursWorkedHook>>>,
    TError,
    { params?: AssignmentGetHoursWorkedParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetHoursWorkedHook>>>,
  TError,
  { params?: AssignmentGetHoursWorkedParams },
  TContext
> => {
  const mutationOptions = useAssignmentGetHoursWorkedMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetAssignmentByIdHook = () => {
  const assignmentGetAssignmentById =
    useAssignmentGetAssignmentByIdMutator<AssignmentViewModel>();

  return (params?: AssignmentGetAssignmentByIdParams) => {
    return assignmentGetAssignmentById({
      url: `/api/Assignment/GetAssignmentById`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentGetAssignmentByIdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentByIdHook>>>,
    TError,
    { params?: AssignmentGetAssignmentByIdParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentByIdHook>>>,
  TError,
  { params?: AssignmentGetAssignmentByIdParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentGetAssignmentById = useAssignmentGetAssignmentByIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentByIdHook>>>,
    { params?: AssignmentGetAssignmentByIdParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentGetAssignmentById(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentGetAssignmentByIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentByIdHook>>>
>;

export type AssignmentGetAssignmentByIdMutationError = unknown;

export const useAssignmentGetAssignmentById = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentByIdHook>>>,
    TError,
    { params?: AssignmentGetAssignmentByIdParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentByIdHook>>>,
  TError,
  { params?: AssignmentGetAssignmentByIdParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentGetAssignmentByIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrHook =
  () => {
    const assignmentGetUnassignedFurtherTransportAssignmentByRegNr =
      useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrMutator<AssignmentViewModel>();

    return (
      params?: AssignmentGetUnassignedFurtherTransportAssignmentByRegNrParams
    ) => {
      return assignmentGetUnassignedFurtherTransportAssignmentByRegNr({
        url: `/api/Assignment/GetUnassignedFurtherTransportAssignmentByRegNr`,
        method: 'POST',
        params,
      });
    };
  };

export const useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrHook
          >
        >
      >,
      TError,
      {
        params?: AssignmentGetUnassignedFurtherTransportAssignmentByRegNrParams;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrHook
        >
      >
    >,
    TError,
    { params?: AssignmentGetUnassignedFurtherTransportAssignmentByRegNrParams },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentGetUnassignedFurtherTransportAssignmentByRegNr =
      useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrHook
          >
        >
      >,
      {
        params?: AssignmentGetUnassignedFurtherTransportAssignmentByRegNrParams;
      }
    > = (props) => {
      const { params } = props ?? {};

      return assignmentGetUnassignedFurtherTransportAssignmentByRegNr(params);
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentGetUnassignedFurtherTransportAssignmentByRegNrMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrHook
        >
      >
    >
  >;

export type AssignmentGetUnassignedFurtherTransportAssignmentByRegNrMutationError =
  unknown;

export const useAssignmentGetUnassignedFurtherTransportAssignmentByRegNr = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrHook
        >
      >
    >,
    TError,
    { params?: AssignmentGetUnassignedFurtherTransportAssignmentByRegNrParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrHook
      >
    >
  >,
  TError,
  { params?: AssignmentGetUnassignedFurtherTransportAssignmentByRegNrParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentGetUnassignedFurtherTransportAssignmentByRegNrMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
export const useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaHook =
  () => {
    const assignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByArea =
      useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaMutator<
        AssignmentViewModel[]
      >();

    return (
      params?: AssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaParams
    ) => {
      return assignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByArea(
        {
          url: `/api/Assignment/GetUnassignedPurchasesAndFurtherTransportAssignmentsByArea`,
          method: 'POST',
          params,
        }
      );
    };
  };

export const useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaHook
          >
        >
      >,
      TError,
      {
        params?: AssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaParams;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaHook
        >
      >
    >,
    TError,
    {
      params?: AssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaParams;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByArea =
      useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaHook
          >
        >
      >,
      {
        params?: AssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaParams;
      }
    > = (props) => {
      const { params } = props ?? {};

      return assignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByArea(
        params
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaHook
        >
      >
    >
  >;

export type AssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaMutationError =
  unknown;

export const useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByArea =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaHook
          >
        >
      >,
      TError,
      {
        params?: AssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaParams;
      },
      TContext
    >;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaHook
        >
      >
    >,
    TError,
    {
      params?: AssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaParams;
    },
    TContext
  > => {
    const mutationOptions =
      useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByAreaMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };
export const useAssignmentGetActiveAssignmentByCaseTypeAndRegNrHook = () => {
  const assignmentGetActiveAssignmentByCaseTypeAndRegNr =
    useAssignmentGetActiveAssignmentByCaseTypeAndRegNrMutator<AssignmentViewModel>();

  return (params?: AssignmentGetActiveAssignmentByCaseTypeAndRegNrParams) => {
    return assignmentGetActiveAssignmentByCaseTypeAndRegNr({
      url: `/api/Assignment/GetActiveAssignmentByCaseTypeAndRegNr`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentGetActiveAssignmentByCaseTypeAndRegNrMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetActiveAssignmentByCaseTypeAndRegNrHook
          >
        >
      >,
      TError,
      { params?: AssignmentGetActiveAssignmentByCaseTypeAndRegNrParams },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetActiveAssignmentByCaseTypeAndRegNrHook
        >
      >
    >,
    TError,
    { params?: AssignmentGetActiveAssignmentByCaseTypeAndRegNrParams },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentGetActiveAssignmentByCaseTypeAndRegNr =
      useAssignmentGetActiveAssignmentByCaseTypeAndRegNrHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetActiveAssignmentByCaseTypeAndRegNrHook
          >
        >
      >,
      { params?: AssignmentGetActiveAssignmentByCaseTypeAndRegNrParams }
    > = (props) => {
      const { params } = props ?? {};

      return assignmentGetActiveAssignmentByCaseTypeAndRegNr(params);
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentGetActiveAssignmentByCaseTypeAndRegNrMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetActiveAssignmentByCaseTypeAndRegNrHook
        >
      >
    >
  >;

export type AssignmentGetActiveAssignmentByCaseTypeAndRegNrMutationError =
  unknown;

export const useAssignmentGetActiveAssignmentByCaseTypeAndRegNr = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetActiveAssignmentByCaseTypeAndRegNrHook
        >
      >
    >,
    TError,
    { params?: AssignmentGetActiveAssignmentByCaseTypeAndRegNrParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetActiveAssignmentByCaseTypeAndRegNrHook>
    >
  >,
  TError,
  { params?: AssignmentGetActiveAssignmentByCaseTypeAndRegNrParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentGetActiveAssignmentByCaseTypeAndRegNrMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetDerivedEstimatedStartTimeHook = () => {
  const assignmentGetDerivedEstimatedStartTime =
    useAssignmentGetDerivedEstimatedStartTimeMutator<Date>();

  return (
    params?: AssignmentGetDerivedEstimatedStartTimeParams,
    signal?: AbortSignal
  ) => {
    return assignmentGetDerivedEstimatedStartTime({
      url: `/api/Assignment/GetDerivedEstimatedStartTime`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getAssignmentGetDerivedEstimatedStartTimeQueryKey = (
  params?: AssignmentGetDerivedEstimatedStartTimeParams
) => {
  return [
    `/api/Assignment/GetDerivedEstimatedStartTime`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentGetDerivedEstimatedStartTimeQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetDerivedEstimatedStartTimeHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentGetDerivedEstimatedStartTimeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useAssignmentGetDerivedEstimatedStartTimeHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentGetDerivedEstimatedStartTimeQueryKey(params);

  const assignmentGetDerivedEstimatedStartTime =
    useAssignmentGetDerivedEstimatedStartTimeHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetDerivedEstimatedStartTimeHook>
      >
    >
  > = ({ signal }) => assignmentGetDerivedEstimatedStartTime(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetDerivedEstimatedStartTimeHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentGetDerivedEstimatedStartTimeQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetDerivedEstimatedStartTimeHook>>
  >
>;
export type AssignmentGetDerivedEstimatedStartTimeQueryError = unknown;

export const useAssignmentGetDerivedEstimatedStartTime = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetDerivedEstimatedStartTimeHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentGetDerivedEstimatedStartTimeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useAssignmentGetDerivedEstimatedStartTimeHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAssignmentGetDerivedEstimatedStartTimeQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentGetAssignmentsByDateHook = () => {
  const assignmentGetAssignmentsByDate =
    useAssignmentGetAssignmentsByDateMutator<AssignmentViewModel[]>();

  return (
    advancedAssignmentFilterOption: AdvancedAssignmentFilterOption[],
    params?: AssignmentGetAssignmentsByDateParams,
    signal?: AbortSignal
  ) => {
    return assignmentGetAssignmentsByDate({
      url: `/api/Assignment/GetAssignmentsByDate`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      params,
      signal,
    });
  };
};

export const getAssignmentGetAssignmentsByDateQueryKey = (
  advancedAssignmentFilterOption: AdvancedAssignmentFilterOption[],
  params?: AssignmentGetAssignmentsByDateParams
) => {
  return [
    `/api/Assignment/GetAssignmentsByDate`,
    ...(params ? [params] : []),
    advancedAssignmentFilterOption,
  ] as const;
};

export const useAssignmentGetAssignmentsByDateQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetAssignmentsByDateHook>>
  >,
  TError = unknown,
>(
  advancedAssignmentFilterOption: AdvancedAssignmentFilterOption[],
  params?: AssignmentGetAssignmentsByDateParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useAssignmentGetAssignmentsByDateHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentGetAssignmentsByDateQueryKey(
      advancedAssignmentFilterOption,
      params
    );

  const assignmentGetAssignmentsByDate =
    useAssignmentGetAssignmentsByDateHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetAssignmentsByDateHook>>
    >
  > = ({ signal }) =>
    assignmentGetAssignmentsByDate(
      advancedAssignmentFilterOption,
      params,
      signal
    );

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetAssignmentsByDateHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentGetAssignmentsByDateQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentsByDateHook>>>
>;
export type AssignmentGetAssignmentsByDateQueryError = unknown;

export const useAssignmentGetAssignmentsByDate = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetAssignmentsByDateHook>>
  >,
  TError = unknown,
>(
  advancedAssignmentFilterOption: AdvancedAssignmentFilterOption[],
  params?: AssignmentGetAssignmentsByDateParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useAssignmentGetAssignmentsByDateHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAssignmentGetAssignmentsByDateQueryOptions(
    advancedAssignmentFilterOption,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentGetListHook = () => {
  const assignmentGetList =
    useAssignmentGetListMutator<AssignmentViewModel[]>();

  return (getAssignmentsRequest: GetAssignmentsRequest) => {
    return assignmentGetList({
      url: `/api/Assignment/GetList`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getAssignmentsRequest,
    });
  };
};

export const useAssignmentGetListMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetListHook>>>,
    TError,
    { data: GetAssignmentsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetListHook>>>,
  TError,
  { data: GetAssignmentsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentGetList = useAssignmentGetListHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetListHook>>>,
    { data: GetAssignmentsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentGetList(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentGetListMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetListHook>>>
>;
export type AssignmentGetListMutationBody = GetAssignmentsRequest;
export type AssignmentGetListMutationError = unknown;

export const useAssignmentGetList = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentGetListHook>>>,
    TError,
    { data: GetAssignmentsRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetListHook>>>,
  TError,
  { data: GetAssignmentsRequest },
  TContext
> => {
  const mutationOptions = useAssignmentGetListMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetAssignmentsWithNewDataFromRideHook = () => {
  const assignmentGetAssignmentsWithNewDataFromRide =
    useAssignmentGetAssignmentsWithNewDataFromRideMutator<
      AssignmentViewModel[]
    >();

  return () => {
    return assignmentGetAssignmentsWithNewDataFromRide({
      url: `/api/Assignment/GetAssignmentsWithNewDataFromRide`,
      method: 'POST',
    });
  };
};

export const useAssignmentGetAssignmentsWithNewDataFromRideMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAssignmentsWithNewDataFromRideHook>
      >
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetAssignmentsWithNewDataFromRideHook>
    >
  >,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentGetAssignmentsWithNewDataFromRide =
    useAssignmentGetAssignmentsWithNewDataFromRideHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAssignmentsWithNewDataFromRideHook>
      >
    >,
    void
  > = () => {
    return assignmentGetAssignmentsWithNewDataFromRide();
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentGetAssignmentsWithNewDataFromRideMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAssignmentsWithNewDataFromRideHook>
      >
    >
  >;

export type AssignmentGetAssignmentsWithNewDataFromRideMutationError = unknown;

export const useAssignmentGetAssignmentsWithNewDataFromRide = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAssignmentsWithNewDataFromRideHook>
      >
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetAssignmentsWithNewDataFromRideHook>
    >
  >,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    useAssignmentGetAssignmentsWithNewDataFromRideMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetAssignmentsWithNewDataFromRideCountHook = () => {
  const assignmentGetAssignmentsWithNewDataFromRideCount =
    useAssignmentGetAssignmentsWithNewDataFromRideCountMutator<number>();

  return () => {
    return assignmentGetAssignmentsWithNewDataFromRideCount({
      url: `/api/Assignment/GetAssignmentsWithNewDataFromRideCount`,
      method: 'POST',
    });
  };
};

export const useAssignmentGetAssignmentsWithNewDataFromRideCountMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetAssignmentsWithNewDataFromRideCountHook
          >
        >
      >,
      TError,
      void,
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetAssignmentsWithNewDataFromRideCountHook
        >
      >
    >,
    TError,
    void,
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentGetAssignmentsWithNewDataFromRideCount =
      useAssignmentGetAssignmentsWithNewDataFromRideCountHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetAssignmentsWithNewDataFromRideCountHook
          >
        >
      >,
      void
    > = () => {
      return assignmentGetAssignmentsWithNewDataFromRideCount();
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentGetAssignmentsWithNewDataFromRideCountMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetAssignmentsWithNewDataFromRideCountHook
        >
      >
    >
  >;

export type AssignmentGetAssignmentsWithNewDataFromRideCountMutationError =
  unknown;

export const useAssignmentGetAssignmentsWithNewDataFromRideCount = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetAssignmentsWithNewDataFromRideCountHook
        >
      >
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetAssignmentsWithNewDataFromRideCountHook>
    >
  >,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    useAssignmentGetAssignmentsWithNewDataFromRideCountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetFurtherTransportAssignmentsHook = () => {
  const assignmentGetFurtherTransportAssignments =
    useAssignmentGetFurtherTransportAssignmentsMutator<AssignmentViewModel[]>();

  return (signal?: AbortSignal) => {
    return assignmentGetFurtherTransportAssignments({
      url: `/api/Assignment/GetFurtherTransportAssignments`,
      method: 'GET',
      signal,
    });
  };
};

export const getAssignmentGetFurtherTransportAssignmentsQueryKey = () => {
  return [`/api/Assignment/GetFurtherTransportAssignments`] as const;
};

export const useAssignmentGetFurtherTransportAssignmentsQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetFurtherTransportAssignmentsHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetFurtherTransportAssignmentsHook>
      >
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentGetFurtherTransportAssignmentsQueryKey();

  const assignmentGetFurtherTransportAssignments =
    useAssignmentGetFurtherTransportAssignmentsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetFurtherTransportAssignmentsHook>
      >
    >
  > = ({ signal }) => assignmentGetFurtherTransportAssignments(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetFurtherTransportAssignmentsHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentGetFurtherTransportAssignmentsQueryResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetFurtherTransportAssignmentsHook>
    >
  >
>;
export type AssignmentGetFurtherTransportAssignmentsQueryError = unknown;

export const useAssignmentGetFurtherTransportAssignments = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetFurtherTransportAssignmentsHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetFurtherTransportAssignmentsHook>
      >
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAssignmentGetFurtherTransportAssignmentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsHook =
  () => {
    const assignmentGetPlannedAssignmentsToSendToFieldTestersCounts =
      useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsMutator<AssignmentsToSendCountDto>();

    return (
      params?: AssignmentGetPlannedAssignmentsToSendToFieldTestersCountsParams,
      signal?: AbortSignal
    ) => {
      return assignmentGetPlannedAssignmentsToSendToFieldTestersCounts({
        url: `/api/Assignment/GetPlannedAssignmentsToSendToFieldTestersCounts`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsQueryKey =
  (
    params?: AssignmentGetPlannedAssignmentsToSendToFieldTestersCountsParams
  ) => {
    return [
      `/api/Assignment/GetPlannedAssignmentsToSendToFieldTestersCounts`,
      ...(params ? [params] : []),
    ] as const;
  };

export const useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: AssignmentGetPlannedAssignmentsToSendToFieldTestersCountsParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsQueryKey(
        params
      );

    const assignmentGetPlannedAssignmentsToSendToFieldTestersCounts =
      useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsHook
          >
        >
      >
    > = ({ signal }) =>
      assignmentGetPlannedAssignmentsToSendToFieldTestersCounts(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type AssignmentGetPlannedAssignmentsToSendToFieldTestersCountsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsHook
        >
      >
    >
  >;
export type AssignmentGetPlannedAssignmentsToSendToFieldTestersCountsQueryError =
  unknown;

export const useAssignmentGetPlannedAssignmentsToSendToFieldTestersCounts = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsHook
      >
    >
  >,
  TError = unknown,
>(
  params?: AssignmentGetPlannedAssignmentsToSendToFieldTestersCountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAssignmentGetPlannedAssignmentsToSendToFieldTestersCountsQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentGetPlannedAssignmentsToSendToFieldTestersHook =
  () => {
    const assignmentGetPlannedAssignmentsToSendToFieldTesters =
      useAssignmentGetPlannedAssignmentsToSendToFieldTestersMutator<number>();

    return (
      params?: AssignmentGetPlannedAssignmentsToSendToFieldTestersParams,
      signal?: AbortSignal
    ) => {
      return assignmentGetPlannedAssignmentsToSendToFieldTesters({
        url: `/api/Assignment/GetPlannedAssignmentsToSendToFieldTesters`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getAssignmentGetPlannedAssignmentsToSendToFieldTestersQueryKey = (
  params?: AssignmentGetPlannedAssignmentsToSendToFieldTestersParams
) => {
  return [
    `/api/Assignment/GetPlannedAssignmentsToSendToFieldTesters`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentGetPlannedAssignmentsToSendToFieldTestersQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: AssignmentGetPlannedAssignmentsToSendToFieldTestersParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getAssignmentGetPlannedAssignmentsToSendToFieldTestersQueryKey(params);

    const assignmentGetPlannedAssignmentsToSendToFieldTesters =
      useAssignmentGetPlannedAssignmentsToSendToFieldTestersHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersHook
          >
        >
      >
    > = ({ signal }) =>
      assignmentGetPlannedAssignmentsToSendToFieldTesters(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type AssignmentGetPlannedAssignmentsToSendToFieldTestersQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersHook
        >
      >
    >
  >;
export type AssignmentGetPlannedAssignmentsToSendToFieldTestersQueryError =
  unknown;

export const useAssignmentGetPlannedAssignmentsToSendToFieldTesters = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersHook
      >
    >
  >,
  TError = unknown,
>(
  params?: AssignmentGetPlannedAssignmentsToSendToFieldTestersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetPlannedAssignmentsToSendToFieldTestersHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAssignmentGetPlannedAssignmentsToSendToFieldTestersQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentSendPlannedAssignmentsToFieldTestersHook = () => {
  const assignmentSendPlannedAssignmentsToFieldTesters =
    useAssignmentSendPlannedAssignmentsToFieldTestersMutator<boolean>();

  return (params?: AssignmentSendPlannedAssignmentsToFieldTestersParams) => {
    return assignmentSendPlannedAssignmentsToFieldTesters({
      url: `/api/Assignment/SendPlannedAssignmentsToFieldTesters`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentSendPlannedAssignmentsToFieldTestersMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentSendPlannedAssignmentsToFieldTestersHook
          >
        >
      >,
      TError,
      { params?: AssignmentSendPlannedAssignmentsToFieldTestersParams },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentSendPlannedAssignmentsToFieldTestersHook>
      >
    >,
    TError,
    { params?: AssignmentSendPlannedAssignmentsToFieldTestersParams },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentSendPlannedAssignmentsToFieldTesters =
      useAssignmentSendPlannedAssignmentsToFieldTestersHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentSendPlannedAssignmentsToFieldTestersHook
          >
        >
      >,
      { params?: AssignmentSendPlannedAssignmentsToFieldTestersParams }
    > = (props) => {
      const { params } = props ?? {};

      return assignmentSendPlannedAssignmentsToFieldTesters(params);
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentSendPlannedAssignmentsToFieldTestersMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentSendPlannedAssignmentsToFieldTestersHook>
      >
    >
  >;

export type AssignmentSendPlannedAssignmentsToFieldTestersMutationError =
  unknown;

export const useAssignmentSendPlannedAssignmentsToFieldTesters = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentSendPlannedAssignmentsToFieldTestersHook>
      >
    >,
    TError,
    { params?: AssignmentSendPlannedAssignmentsToFieldTestersParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentSendPlannedAssignmentsToFieldTestersHook>
    >
  >,
  TError,
  { params?: AssignmentSendPlannedAssignmentsToFieldTestersParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentSendPlannedAssignmentsToFieldTestersMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetFollowUpAssignmentCandidatesHook = () => {
  const assignmentGetFollowUpAssignmentCandidates =
    useAssignmentGetFollowUpAssignmentCandidatesMutator<ConnectedAssignmentsResponse>();

  return (
    params?: AssignmentGetFollowUpAssignmentCandidatesParams,
    signal?: AbortSignal
  ) => {
    return assignmentGetFollowUpAssignmentCandidates({
      url: `/api/Assignment/GetFollowUpAssignmentCandidates`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getAssignmentGetFollowUpAssignmentCandidatesQueryKey = (
  params?: AssignmentGetFollowUpAssignmentCandidatesParams
) => {
  return [
    `/api/Assignment/GetFollowUpAssignmentCandidates`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentGetFollowUpAssignmentCandidatesQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetFollowUpAssignmentCandidatesHook>
    >
  >,
  TError = unknown,
>(
  params?: AssignmentGetFollowUpAssignmentCandidatesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useAssignmentGetFollowUpAssignmentCandidatesHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentGetFollowUpAssignmentCandidatesQueryKey(params);

  const assignmentGetFollowUpAssignmentCandidates =
    useAssignmentGetFollowUpAssignmentCandidatesHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetFollowUpAssignmentCandidatesHook>
      >
    >
  > = ({ signal }) => assignmentGetFollowUpAssignmentCandidates(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetFollowUpAssignmentCandidatesHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentGetFollowUpAssignmentCandidatesQueryResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetFollowUpAssignmentCandidatesHook>
    >
  >
>;
export type AssignmentGetFollowUpAssignmentCandidatesQueryError = unknown;

export const useAssignmentGetFollowUpAssignmentCandidates = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetFollowUpAssignmentCandidatesHook>
    >
  >,
  TError = unknown,
>(
  params?: AssignmentGetFollowUpAssignmentCandidatesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useAssignmentGetFollowUpAssignmentCandidatesHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAssignmentGetFollowUpAssignmentCandidatesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentSetAssignmentToFollowUpHook = () => {
  const assignmentSetAssignmentToFollowUp =
    useAssignmentSetAssignmentToFollowUpMutator<void>();

  return (params?: AssignmentSetAssignmentToFollowUpParams) => {
    return assignmentSetAssignmentToFollowUp({
      url: `/api/Assignment/SetAssignmentToFollowUp`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentSetAssignmentToFollowUpMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentSetAssignmentToFollowUpHook>>
    >,
    TError,
    { params?: AssignmentSetAssignmentToFollowUpParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSetAssignmentToFollowUpHook>>
  >,
  TError,
  { params?: AssignmentSetAssignmentToFollowUpParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentSetAssignmentToFollowUp =
    useAssignmentSetAssignmentToFollowUpHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentSetAssignmentToFollowUpHook>>
    >,
    { params?: AssignmentSetAssignmentToFollowUpParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentSetAssignmentToFollowUp(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentSetAssignmentToFollowUpMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSetAssignmentToFollowUpHook>>
  >
>;

export type AssignmentSetAssignmentToFollowUpMutationError = unknown;

export const useAssignmentSetAssignmentToFollowUp = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentSetAssignmentToFollowUpHook>>
    >,
    TError,
    { params?: AssignmentSetAssignmentToFollowUpParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentSetAssignmentToFollowUpHook>>
  >,
  TError,
  { params?: AssignmentSetAssignmentToFollowUpParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentSetAssignmentToFollowUpMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentSortFieldTestersForNewAssignmentHook = () => {
  const assignmentSortFieldTestersForNewAssignment =
    useAssignmentSortFieldTestersForNewAssignmentMutator<SortResult>();

  return (
    params?: AssignmentSortFieldTestersForNewAssignmentParams,
    signal?: AbortSignal
  ) => {
    return assignmentSortFieldTestersForNewAssignment({
      url: `/api/Assignment/SortFieldTestersForNewAssignment`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getAssignmentSortFieldTestersForNewAssignmentQueryKey = (
  params?: AssignmentSortFieldTestersForNewAssignmentParams
) => {
  return [
    `/api/Assignment/SortFieldTestersForNewAssignment`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentSortFieldTestersForNewAssignmentQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentSortFieldTestersForNewAssignmentHook>
    >
  >,
  TError = unknown,
>(
  params?: AssignmentSortFieldTestersForNewAssignmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useAssignmentSortFieldTestersForNewAssignmentHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentSortFieldTestersForNewAssignmentQueryKey(params);

  const assignmentSortFieldTestersForNewAssignment =
    useAssignmentSortFieldTestersForNewAssignmentHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentSortFieldTestersForNewAssignmentHook>
      >
    >
  > = ({ signal }) =>
    assignmentSortFieldTestersForNewAssignment(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentSortFieldTestersForNewAssignmentHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentSortFieldTestersForNewAssignmentQueryResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentSortFieldTestersForNewAssignmentHook>
    >
  >
>;
export type AssignmentSortFieldTestersForNewAssignmentQueryError = unknown;

export const useAssignmentSortFieldTestersForNewAssignment = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentSortFieldTestersForNewAssignmentHook>
    >
  >,
  TError = unknown,
>(
  params?: AssignmentSortFieldTestersForNewAssignmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useAssignmentSortFieldTestersForNewAssignmentHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAssignmentSortFieldTestersForNewAssignmentQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentExecutePredefinedActionHook = () => {
  const assignmentExecutePredefinedAction =
    useAssignmentExecutePredefinedActionMutator<void>();

  return (
    assignmentModel: AssignmentModel,
    params?: AssignmentExecutePredefinedActionParams
  ) => {
    return assignmentExecutePredefinedAction({
      url: `/api/Assignment/ExecutePredefinedAction`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentModel,
      params,
    });
  };
};

export const useAssignmentExecutePredefinedActionMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentExecutePredefinedActionHook>>
    >,
    TError,
    { data: AssignmentModel; params?: AssignmentExecutePredefinedActionParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentExecutePredefinedActionHook>>
  >,
  TError,
  { data: AssignmentModel; params?: AssignmentExecutePredefinedActionParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentExecutePredefinedAction =
    useAssignmentExecutePredefinedActionHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentExecutePredefinedActionHook>>
    >,
    { data: AssignmentModel; params?: AssignmentExecutePredefinedActionParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return assignmentExecutePredefinedAction(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentExecutePredefinedActionMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentExecutePredefinedActionHook>>
  >
>;
export type AssignmentExecutePredefinedActionMutationBody = AssignmentModel;
export type AssignmentExecutePredefinedActionMutationError = unknown;

export const useAssignmentExecutePredefinedAction = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentExecutePredefinedActionHook>>
    >,
    TError,
    { data: AssignmentModel; params?: AssignmentExecutePredefinedActionParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentExecutePredefinedActionHook>>
  >,
  TError,
  { data: AssignmentModel; params?: AssignmentExecutePredefinedActionParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentExecutePredefinedActionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentAddStandardizedCommentHook = () => {
  const assignmentAddStandardizedComment =
    useAssignmentAddStandardizedCommentMutator<void>();

  return (params?: AssignmentAddStandardizedCommentParams) => {
    return assignmentAddStandardizedComment({
      url: `/api/Assignment/AddStandardizedComment`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentAddStandardizedCommentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentAddStandardizedCommentHook>>
    >,
    TError,
    { params?: AssignmentAddStandardizedCommentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentAddStandardizedCommentHook>>
  >,
  TError,
  { params?: AssignmentAddStandardizedCommentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentAddStandardizedComment =
    useAssignmentAddStandardizedCommentHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentAddStandardizedCommentHook>>
    >,
    { params?: AssignmentAddStandardizedCommentParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentAddStandardizedComment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentAddStandardizedCommentMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentAddStandardizedCommentHook>>
  >
>;

export type AssignmentAddStandardizedCommentMutationError = unknown;

export const useAssignmentAddStandardizedComment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentAddStandardizedCommentHook>>
    >,
    TError,
    { params?: AssignmentAddStandardizedCommentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentAddStandardizedCommentHook>>
  >,
  TError,
  { params?: AssignmentAddStandardizedCommentParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentAddStandardizedCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentRemoveStandardizedCommentHook = () => {
  const assignmentRemoveStandardizedComment =
    useAssignmentRemoveStandardizedCommentMutator<void>();

  return (params?: AssignmentRemoveStandardizedCommentParams) => {
    return assignmentRemoveStandardizedComment({
      url: `/api/Assignment/RemoveStandardizedComment`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentRemoveStandardizedCommentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRemoveStandardizedCommentHook>>
    >,
    TError,
    { params?: AssignmentRemoveStandardizedCommentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRemoveStandardizedCommentHook>>
  >,
  TError,
  { params?: AssignmentRemoveStandardizedCommentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentRemoveStandardizedComment =
    useAssignmentRemoveStandardizedCommentHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRemoveStandardizedCommentHook>>
    >,
    { params?: AssignmentRemoveStandardizedCommentParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentRemoveStandardizedComment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentRemoveStandardizedCommentMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRemoveStandardizedCommentHook>>
  >
>;

export type AssignmentRemoveStandardizedCommentMutationError = unknown;

export const useAssignmentRemoveStandardizedComment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRemoveStandardizedCommentHook>>
    >,
    TError,
    { params?: AssignmentRemoveStandardizedCommentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRemoveStandardizedCommentHook>>
  >,
  TError,
  { params?: AssignmentRemoveStandardizedCommentParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentRemoveStandardizedCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentCreateStandardizedCommentHook = () => {
  const assignmentCreateStandardizedComment =
    useAssignmentCreateStandardizedCommentMutator<void>();

  return (params?: AssignmentCreateStandardizedCommentParams) => {
    return assignmentCreateStandardizedComment({
      url: `/api/Assignment/CreateStandardizedComment`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentCreateStandardizedCommentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentCreateStandardizedCommentHook>>
    >,
    TError,
    { params?: AssignmentCreateStandardizedCommentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentCreateStandardizedCommentHook>>
  >,
  TError,
  { params?: AssignmentCreateStandardizedCommentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentCreateStandardizedComment =
    useAssignmentCreateStandardizedCommentHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentCreateStandardizedCommentHook>>
    >,
    { params?: AssignmentCreateStandardizedCommentParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentCreateStandardizedComment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentCreateStandardizedCommentMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentCreateStandardizedCommentHook>>
  >
>;

export type AssignmentCreateStandardizedCommentMutationError = unknown;

export const useAssignmentCreateStandardizedComment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentCreateStandardizedCommentHook>>
    >,
    TError,
    { params?: AssignmentCreateStandardizedCommentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentCreateStandardizedCommentHook>>
  >,
  TError,
  { params?: AssignmentCreateStandardizedCommentParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentCreateStandardizedCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetAllDetailedStandardizedCommentsHook = () => {
  const assignmentGetAllDetailedStandardizedComments =
    useAssignmentGetAllDetailedStandardizedCommentsMutator<
      DetailedStandardizedCommentDto[]
    >();

  return (signal?: AbortSignal) => {
    return assignmentGetAllDetailedStandardizedComments({
      url: `/api/Assignment/GetAllDetailedStandardizedComments`,
      method: 'GET',
      signal,
    });
  };
};

export const getAssignmentGetAllDetailedStandardizedCommentsQueryKey = () => {
  return [`/api/Assignment/GetAllDetailedStandardizedComments`] as const;
};

export const useAssignmentGetAllDetailedStandardizedCommentsQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetAllDetailedStandardizedCommentsHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAllDetailedStandardizedCommentsHook>
      >
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentGetAllDetailedStandardizedCommentsQueryKey();

  const assignmentGetAllDetailedStandardizedComments =
    useAssignmentGetAllDetailedStandardizedCommentsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAllDetailedStandardizedCommentsHook>
      >
    >
  > = ({ signal }) => assignmentGetAllDetailedStandardizedComments(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAllDetailedStandardizedCommentsHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentGetAllDetailedStandardizedCommentsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAllDetailedStandardizedCommentsHook>
      >
    >
  >;
export type AssignmentGetAllDetailedStandardizedCommentsQueryError = unknown;

export const useAssignmentGetAllDetailedStandardizedComments = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetAllDetailedStandardizedCommentsHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAllDetailedStandardizedCommentsHook>
      >
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAssignmentGetAllDetailedStandardizedCommentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentDeleteStandardizedCommentHook = () => {
  const assignmentDeleteStandardizedComment =
    useAssignmentDeleteStandardizedCommentMutator<void>();

  return (params?: AssignmentDeleteStandardizedCommentParams) => {
    return assignmentDeleteStandardizedComment({
      url: `/api/Assignment/DeleteStandardizedComment`,
      method: 'DELETE',
      params,
    });
  };
};

export const useAssignmentDeleteStandardizedCommentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentDeleteStandardizedCommentHook>>
    >,
    TError,
    { params?: AssignmentDeleteStandardizedCommentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentDeleteStandardizedCommentHook>>
  >,
  TError,
  { params?: AssignmentDeleteStandardizedCommentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentDeleteStandardizedComment =
    useAssignmentDeleteStandardizedCommentHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentDeleteStandardizedCommentHook>>
    >,
    { params?: AssignmentDeleteStandardizedCommentParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentDeleteStandardizedComment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentDeleteStandardizedCommentMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentDeleteStandardizedCommentHook>>
  >
>;

export type AssignmentDeleteStandardizedCommentMutationError = unknown;

export const useAssignmentDeleteStandardizedComment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentDeleteStandardizedCommentHook>>
    >,
    TError,
    { params?: AssignmentDeleteStandardizedCommentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentDeleteStandardizedCommentHook>>
  >,
  TError,
  { params?: AssignmentDeleteStandardizedCommentParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentDeleteStandardizedCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentUpdateStandardizedCommentHook = () => {
  const assignmentUpdateStandardizedComment =
    useAssignmentUpdateStandardizedCommentMutator<void>();

  return (params?: AssignmentUpdateStandardizedCommentParams) => {
    return assignmentUpdateStandardizedComment({
      url: `/api/Assignment/UpdateStandardizedComment`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentUpdateStandardizedCommentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentUpdateStandardizedCommentHook>>
    >,
    TError,
    { params?: AssignmentUpdateStandardizedCommentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentUpdateStandardizedCommentHook>>
  >,
  TError,
  { params?: AssignmentUpdateStandardizedCommentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentUpdateStandardizedComment =
    useAssignmentUpdateStandardizedCommentHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentUpdateStandardizedCommentHook>>
    >,
    { params?: AssignmentUpdateStandardizedCommentParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentUpdateStandardizedComment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentUpdateStandardizedCommentMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentUpdateStandardizedCommentHook>>
  >
>;

export type AssignmentUpdateStandardizedCommentMutationError = unknown;

export const useAssignmentUpdateStandardizedComment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentUpdateStandardizedCommentHook>>
    >,
    TError,
    { params?: AssignmentUpdateStandardizedCommentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentUpdateStandardizedCommentHook>>
  >,
  TError,
  { params?: AssignmentUpdateStandardizedCommentParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentUpdateStandardizedCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentLeaveDeviationReportHook = () => {
  const assignmentLeaveDeviationReport =
    useAssignmentLeaveDeviationReportMutator<void>();

  return (deviationReportRequest: DeviationReportRequest) => {
    return assignmentLeaveDeviationReport({
      url: `/api/Assignment/LeaveDeviationReport`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: deviationReportRequest,
    });
  };
};

export const useAssignmentLeaveDeviationReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentLeaveDeviationReportHook>>
    >,
    TError,
    { data: DeviationReportRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAssignmentLeaveDeviationReportHook>>>,
  TError,
  { data: DeviationReportRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentLeaveDeviationReport =
    useAssignmentLeaveDeviationReportHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentLeaveDeviationReportHook>>
    >,
    { data: DeviationReportRequest }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentLeaveDeviationReport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentLeaveDeviationReportMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentLeaveDeviationReportHook>>>
>;
export type AssignmentLeaveDeviationReportMutationBody = DeviationReportRequest;
export type AssignmentLeaveDeviationReportMutationError = unknown;

export const useAssignmentLeaveDeviationReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentLeaveDeviationReportHook>>
    >,
    TError,
    { data: DeviationReportRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAssignmentLeaveDeviationReportHook>>>,
  TError,
  { data: DeviationReportRequest },
  TContext
> => {
  const mutationOptions =
    useAssignmentLeaveDeviationReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetAssignmentRequestsHook = () => {
  const assignmentGetAssignmentRequests =
    useAssignmentGetAssignmentRequestsMutator<EventModel[]>();

  return (
    params?: AssignmentGetAssignmentRequestsParams,
    signal?: AbortSignal
  ) => {
    return assignmentGetAssignmentRequests({
      url: `/api/Assignment/GetAssignmentRequests`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getAssignmentGetAssignmentRequestsQueryKey = (
  params?: AssignmentGetAssignmentRequestsParams
) => {
  return [
    `/api/Assignment/GetAssignmentRequests`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentGetAssignmentRequestsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetAssignmentRequestsHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentGetAssignmentRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useAssignmentGetAssignmentRequestsHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentGetAssignmentRequestsQueryKey(params);

  const assignmentGetAssignmentRequests =
    useAssignmentGetAssignmentRequestsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetAssignmentRequestsHook>>
    >
  > = ({ signal }) => assignmentGetAssignmentRequests(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentGetAssignmentRequestsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentGetAssignmentRequestsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentGetAssignmentRequestsHook>>>
>;
export type AssignmentGetAssignmentRequestsQueryError = unknown;

export const useAssignmentGetAssignmentRequests = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentGetAssignmentRequestsHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentGetAssignmentRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useAssignmentGetAssignmentRequestsHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAssignmentGetAssignmentRequestsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentMarkAssignmentInNeedOfHandlingAsHandledHook = () => {
  const assignmentMarkAssignmentInNeedOfHandlingAsHandled =
    useAssignmentMarkAssignmentInNeedOfHandlingAsHandledMutator<void>();

  return (params?: AssignmentMarkAssignmentInNeedOfHandlingAsHandledParams) => {
    return assignmentMarkAssignmentInNeedOfHandlingAsHandled({
      url: `/api/Assignment/MarkAssignmentInNeedOfHandlingAsHandled`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentMarkAssignmentInNeedOfHandlingAsHandledMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentMarkAssignmentInNeedOfHandlingAsHandledHook
          >
        >
      >,
      TError,
      { params?: AssignmentMarkAssignmentInNeedOfHandlingAsHandledParams },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentMarkAssignmentInNeedOfHandlingAsHandledHook
        >
      >
    >,
    TError,
    { params?: AssignmentMarkAssignmentInNeedOfHandlingAsHandledParams },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentMarkAssignmentInNeedOfHandlingAsHandled =
      useAssignmentMarkAssignmentInNeedOfHandlingAsHandledHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentMarkAssignmentInNeedOfHandlingAsHandledHook
          >
        >
      >,
      { params?: AssignmentMarkAssignmentInNeedOfHandlingAsHandledParams }
    > = (props) => {
      const { params } = props ?? {};

      return assignmentMarkAssignmentInNeedOfHandlingAsHandled(params);
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentMarkAssignmentInNeedOfHandlingAsHandledMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentMarkAssignmentInNeedOfHandlingAsHandledHook
        >
      >
    >
  >;

export type AssignmentMarkAssignmentInNeedOfHandlingAsHandledMutationError =
  unknown;

export const useAssignmentMarkAssignmentInNeedOfHandlingAsHandled = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentMarkAssignmentInNeedOfHandlingAsHandledHook
        >
      >
    >,
    TError,
    { params?: AssignmentMarkAssignmentInNeedOfHandlingAsHandledParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useAssignmentMarkAssignmentInNeedOfHandlingAsHandledHook
      >
    >
  >,
  TError,
  { params?: AssignmentMarkAssignmentInNeedOfHandlingAsHandledParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentMarkAssignmentInNeedOfHandlingAsHandledMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
export const useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeHook =
  () => {
    const assignmentGetPurchaseAssignmentsForSameAddressAndBookedToTime =
      useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeMutator<
        AssignmentViewModel[]
      >();

    return (assignmentDataLookupRecord: AssignmentDataLookupRecord) => {
      return assignmentGetPurchaseAssignmentsForSameAddressAndBookedToTime({
        url: `/api/Assignment/GetPurchaseAssignmentsForSameAddressAndBookedToTime`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: assignmentDataLookupRecord,
      });
    };
  };

export const useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeHook
          >
        >
      >,
      TError,
      { data: AssignmentDataLookupRecord },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeHook
        >
      >
    >,
    TError,
    { data: AssignmentDataLookupRecord },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentGetPurchaseAssignmentsForSameAddressAndBookedToTime =
      useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeHook
          >
        >
      >,
      { data: AssignmentDataLookupRecord }
    > = (props) => {
      const { data } = props ?? {};

      return assignmentGetPurchaseAssignmentsForSameAddressAndBookedToTime(
        data
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeHook
        >
      >
    >
  >;
export type AssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeMutationBody =
  AssignmentDataLookupRecord;
export type AssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeMutationError =
  unknown;

export const useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTime =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeHook
          >
        >
      >,
      TError,
      { data: AssignmentDataLookupRecord },
      TContext
    >;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeHook
        >
      >
    >,
    TError,
    { data: AssignmentDataLookupRecord },
    TContext
  > => {
    const mutationOptions =
      useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTimeMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };
export const useAssignmentAutoApproveAssignmentsHook = () => {
  const assignmentAutoApproveAssignments =
    useAssignmentAutoApproveAssignmentsMutator<void>();

  return () => {
    return assignmentAutoApproveAssignments({
      url: `/api/Assignment/AutoApproveAssignments`,
      method: 'POST',
    });
  };
};

export const useAssignmentAutoApproveAssignmentsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentAutoApproveAssignmentsHook>>
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentAutoApproveAssignmentsHook>>
  >,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentAutoApproveAssignments =
    useAssignmentAutoApproveAssignmentsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentAutoApproveAssignmentsHook>>
    >,
    void
  > = () => {
    return assignmentAutoApproveAssignments();
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentAutoApproveAssignmentsMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentAutoApproveAssignmentsHook>>
  >
>;

export type AssignmentAutoApproveAssignmentsMutationError = unknown;

export const useAssignmentAutoApproveAssignments = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentAutoApproveAssignmentsHook>>
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentAutoApproveAssignmentsHook>>
  >,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    useAssignmentAutoApproveAssignmentsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentGetAutoApproveAssignmentsCountHook = () => {
  const assignmentGetAutoApproveAssignmentsCount =
    useAssignmentGetAutoApproveAssignmentsCountMutator<number>();

  return (signal?: AbortSignal) => {
    return assignmentGetAutoApproveAssignmentsCount({
      url: `/api/Assignment/GetAutoApproveAssignmentsCount`,
      method: 'GET',
      signal,
    });
  };
};

export const getAssignmentGetAutoApproveAssignmentsCountQueryKey = () => {
  return [`/api/Assignment/GetAutoApproveAssignmentsCount`] as const;
};

export const useAssignmentGetAutoApproveAssignmentsCountQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetAutoApproveAssignmentsCountHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAutoApproveAssignmentsCountHook>
      >
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentGetAutoApproveAssignmentsCountQueryKey();

  const assignmentGetAutoApproveAssignmentsCount =
    useAssignmentGetAutoApproveAssignmentsCountHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAutoApproveAssignmentsCountHook>
      >
    >
  > = ({ signal }) => assignmentGetAutoApproveAssignmentsCount(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAutoApproveAssignmentsCountHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentGetAutoApproveAssignmentsCountQueryResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetAutoApproveAssignmentsCountHook>
    >
  >
>;
export type AssignmentGetAutoApproveAssignmentsCountQueryError = unknown;

export const useAssignmentGetAutoApproveAssignmentsCount = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentGetAutoApproveAssignmentsCountHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentGetAutoApproveAssignmentsCountHook>
      >
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAssignmentGetAutoApproveAssignmentsCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
