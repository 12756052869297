/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
  AssignmentViewModel,
  BulkImportFromExternalProviderDto,
  ExternalReceiptModel,
  FindReceiptsRequest,
  GenericPageResultDtoOfReceiptLineItemDTO,
  GetMyUnmappedReceiptLineItemsRequest,
  ProductExpenseTypeMappingDto,
  ReceiptAssignReceiptToAssignmentParams,
  ReceiptBulkImportFromExternalProviderParams,
  ReceiptChangeValidStatusOfExternalReceiptLineItemParams,
  ReceiptFindApplicableAssignmentsForReceiptParams,
  ReceiptFindMyApplicableAssignmentsForReceiptParams,
  ReceiptFindReceiptsParams,
  ReceiptGetExpenseTypesMapping200Five,
  ReceiptGetExpenseTypesMapping200Four,
  ReceiptGetExpenseTypesMapping200One,
  ReceiptGetExpenseTypesMapping200Three,
  ReceiptGetExpenseTypesMapping200Two,
  ReceiptLineItemDTO,
} from '.././model';
import useReceiptFindReceiptLineItemsMutator from '.././mutator/custom-instance';
import useReceiptFindReceiptsMutator from '.././mutator/custom-instance';
import useReceiptGetReceiptByLineItemIdMutator from '.././mutator/custom-instance';
import useReceiptGetExpenseTypesMappingMutator from '.././mutator/custom-instance';
import useReceiptFindApplicableAssignmentsForReceiptMutator from '.././mutator/custom-instance';
import useReceiptFindMyApplicableAssignmentsForReceiptMutator from '.././mutator/custom-instance';
import useReceiptAssignReceiptToAssignmentMutator from '.././mutator/custom-instance';
import useReceiptUpdateProductExpenseTypeMappingMutator from '.././mutator/custom-instance';
import useReceiptGetProductExpenseTypeMappingsMutator from '.././mutator/custom-instance';
import useReceiptBulkImportFromExternalProviderMutator from '.././mutator/custom-instance';
import useReceiptAttemptBulkConnectExternalLineItemsToExpensesMutator from '.././mutator/custom-instance';
import useReceiptGetMyUnmappedReceiptLineItemsMutator from '.././mutator/custom-instance';
import useReceiptGetMyUnmappedReceiptLineItemsCountMutator from '.././mutator/custom-instance';
import useReceiptChangeValidStatusOfExternalReceiptLineItemMutator from '.././mutator/custom-instance';

export const useReceiptFindReceiptLineItemsHook = () => {
  const receiptFindReceiptLineItems =
    useReceiptFindReceiptLineItemsMutator<GenericPageResultDtoOfReceiptLineItemDTO>();

  return (findReceiptsRequest: FindReceiptsRequest) => {
    return receiptFindReceiptLineItems({
      url: `/api/Receipt/FindReceiptLineItems`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: findReceiptsRequest,
    });
  };
};

export const useReceiptFindReceiptLineItemsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptLineItemsHook>>>,
    TError,
    { data: FindReceiptsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptLineItemsHook>>>,
  TError,
  { data: FindReceiptsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const receiptFindReceiptLineItems = useReceiptFindReceiptLineItemsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptLineItemsHook>>>,
    { data: FindReceiptsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return receiptFindReceiptLineItems(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiptFindReceiptLineItemsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptLineItemsHook>>>
>;
export type ReceiptFindReceiptLineItemsMutationBody = FindReceiptsRequest;
export type ReceiptFindReceiptLineItemsMutationError = unknown;

export const useReceiptFindReceiptLineItems = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptLineItemsHook>>>,
    TError,
    { data: FindReceiptsRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptLineItemsHook>>>,
  TError,
  { data: FindReceiptsRequest },
  TContext
> => {
  const mutationOptions =
    useReceiptFindReceiptLineItemsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useReceiptFindReceiptsHook = () => {
  const receiptFindReceipts =
    useReceiptFindReceiptsMutator<ExternalReceiptModel[]>();

  return (params?: ReceiptFindReceiptsParams) => {
    return receiptFindReceipts({
      url: `/api/Receipt/FindReceipts`,
      method: 'POST',
      params,
    });
  };
};

export const useReceiptFindReceiptsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptsHook>>>,
    TError,
    { params?: ReceiptFindReceiptsParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptsHook>>>,
  TError,
  { params?: ReceiptFindReceiptsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const receiptFindReceipts = useReceiptFindReceiptsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptsHook>>>,
    { params?: ReceiptFindReceiptsParams }
  > = (props) => {
    const { params } = props ?? {};

    return receiptFindReceipts(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiptFindReceiptsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptsHook>>>
>;

export type ReceiptFindReceiptsMutationError = unknown;

export const useReceiptFindReceipts = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptsHook>>>,
    TError,
    { params?: ReceiptFindReceiptsParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useReceiptFindReceiptsHook>>>,
  TError,
  { params?: ReceiptFindReceiptsParams },
  TContext
> => {
  const mutationOptions = useReceiptFindReceiptsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useReceiptGetReceiptByLineItemIdHook = () => {
  const receiptGetReceiptByLineItemId =
    useReceiptGetReceiptByLineItemIdMutator<ReceiptLineItemDTO>();

  return (receiptGetReceiptByLineItemIdBody: number) => {
    return receiptGetReceiptByLineItemId({
      url: `/api/Receipt/GetReceiptByLineItemId`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: receiptGetReceiptByLineItemIdBody,
    });
  };
};

export const useReceiptGetReceiptByLineItemIdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetReceiptByLineItemIdHook>>
    >,
    TError,
    { data: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useReceiptGetReceiptByLineItemIdHook>>>,
  TError,
  { data: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const receiptGetReceiptByLineItemId = useReceiptGetReceiptByLineItemIdHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetReceiptByLineItemIdHook>>
    >,
    { data: number }
  > = (props) => {
    const { data } = props ?? {};

    return receiptGetReceiptByLineItemId(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiptGetReceiptByLineItemIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useReceiptGetReceiptByLineItemIdHook>>>
>;
export type ReceiptGetReceiptByLineItemIdMutationBody = number;
export type ReceiptGetReceiptByLineItemIdMutationError = unknown;

export const useReceiptGetReceiptByLineItemId = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetReceiptByLineItemIdHook>>
    >,
    TError,
    { data: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useReceiptGetReceiptByLineItemIdHook>>>,
  TError,
  { data: number },
  TContext
> => {
  const mutationOptions =
    useReceiptGetReceiptByLineItemIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useReceiptGetExpenseTypesMappingHook = () => {
  const receiptGetExpenseTypesMapping = useReceiptGetExpenseTypesMappingMutator<
    | ReceiptGetExpenseTypesMapping200One
    | ReceiptGetExpenseTypesMapping200Two
    | ReceiptGetExpenseTypesMapping200Three
    | ReceiptGetExpenseTypesMapping200Four
    | ReceiptGetExpenseTypesMapping200Five
  >();

  return (signal?: AbortSignal) => {
    return receiptGetExpenseTypesMapping({
      url: `/api/Receipt/GetExpenseTypesMapping`,
      method: 'GET',
      signal,
    });
  };
};

export const getReceiptGetExpenseTypesMappingQueryKey = () => {
  return [`/api/Receipt/GetExpenseTypesMapping`] as const;
};

export const useReceiptGetExpenseTypesMappingQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useReceiptGetExpenseTypesMappingHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetExpenseTypesMappingHook>>
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReceiptGetExpenseTypesMappingQueryKey();

  const receiptGetExpenseTypesMapping = useReceiptGetExpenseTypesMappingHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useReceiptGetExpenseTypesMappingHook>>>
  > = ({ signal }) => receiptGetExpenseTypesMapping(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetExpenseTypesMappingHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ReceiptGetExpenseTypesMappingQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useReceiptGetExpenseTypesMappingHook>>>
>;
export type ReceiptGetExpenseTypesMappingQueryError = unknown;

export const useReceiptGetExpenseTypesMapping = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useReceiptGetExpenseTypesMappingHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetExpenseTypesMappingHook>>
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useReceiptGetExpenseTypesMappingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useReceiptFindApplicableAssignmentsForReceiptHook = () => {
  const receiptFindApplicableAssignmentsForReceipt =
    useReceiptFindApplicableAssignmentsForReceiptMutator<
      AssignmentViewModel[]
    >();

  return (params?: ReceiptFindApplicableAssignmentsForReceiptParams) => {
    return receiptFindApplicableAssignmentsForReceipt({
      url: `/api/Receipt/FindApplicableAssignmentsForReceipt`,
      method: 'POST',
      params,
    });
  };
};

export const useReceiptFindApplicableAssignmentsForReceiptMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptFindApplicableAssignmentsForReceiptHook>
      >
    >,
    TError,
    { params?: ReceiptFindApplicableAssignmentsForReceiptParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useReceiptFindApplicableAssignmentsForReceiptHook>
    >
  >,
  TError,
  { params?: ReceiptFindApplicableAssignmentsForReceiptParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const receiptFindApplicableAssignmentsForReceipt =
    useReceiptFindApplicableAssignmentsForReceiptHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptFindApplicableAssignmentsForReceiptHook>
      >
    >,
    { params?: ReceiptFindApplicableAssignmentsForReceiptParams }
  > = (props) => {
    const { params } = props ?? {};

    return receiptFindApplicableAssignmentsForReceipt(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiptFindApplicableAssignmentsForReceiptMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptFindApplicableAssignmentsForReceiptHook>
      >
    >
  >;

export type ReceiptFindApplicableAssignmentsForReceiptMutationError = unknown;

export const useReceiptFindApplicableAssignmentsForReceipt = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptFindApplicableAssignmentsForReceiptHook>
      >
    >,
    TError,
    { params?: ReceiptFindApplicableAssignmentsForReceiptParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useReceiptFindApplicableAssignmentsForReceiptHook>
    >
  >,
  TError,
  { params?: ReceiptFindApplicableAssignmentsForReceiptParams },
  TContext
> => {
  const mutationOptions =
    useReceiptFindApplicableAssignmentsForReceiptMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useReceiptFindMyApplicableAssignmentsForReceiptHook = () => {
  const receiptFindMyApplicableAssignmentsForReceipt =
    useReceiptFindMyApplicableAssignmentsForReceiptMutator<
      AssignmentViewModel[]
    >();

  return (params?: ReceiptFindMyApplicableAssignmentsForReceiptParams) => {
    return receiptFindMyApplicableAssignmentsForReceipt({
      url: `/api/Receipt/FindMyApplicableAssignmentsForReceipt`,
      method: 'POST',
      params,
    });
  };
};

export const useReceiptFindMyApplicableAssignmentsForReceiptMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptFindMyApplicableAssignmentsForReceiptHook>
      >
    >,
    TError,
    { params?: ReceiptFindMyApplicableAssignmentsForReceiptParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useReceiptFindMyApplicableAssignmentsForReceiptHook>
    >
  >,
  TError,
  { params?: ReceiptFindMyApplicableAssignmentsForReceiptParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const receiptFindMyApplicableAssignmentsForReceipt =
    useReceiptFindMyApplicableAssignmentsForReceiptHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptFindMyApplicableAssignmentsForReceiptHook>
      >
    >,
    { params?: ReceiptFindMyApplicableAssignmentsForReceiptParams }
  > = (props) => {
    const { params } = props ?? {};

    return receiptFindMyApplicableAssignmentsForReceipt(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiptFindMyApplicableAssignmentsForReceiptMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptFindMyApplicableAssignmentsForReceiptHook>
      >
    >
  >;

export type ReceiptFindMyApplicableAssignmentsForReceiptMutationError = unknown;

export const useReceiptFindMyApplicableAssignmentsForReceipt = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptFindMyApplicableAssignmentsForReceiptHook>
      >
    >,
    TError,
    { params?: ReceiptFindMyApplicableAssignmentsForReceiptParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useReceiptFindMyApplicableAssignmentsForReceiptHook>
    >
  >,
  TError,
  { params?: ReceiptFindMyApplicableAssignmentsForReceiptParams },
  TContext
> => {
  const mutationOptions =
    useReceiptFindMyApplicableAssignmentsForReceiptMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useReceiptAssignReceiptToAssignmentHook = () => {
  const receiptAssignReceiptToAssignment =
    useReceiptAssignReceiptToAssignmentMutator<void>();

  return (params?: ReceiptAssignReceiptToAssignmentParams) => {
    return receiptAssignReceiptToAssignment({
      url: `/api/Receipt/AssignReceiptToAssignment`,
      method: 'POST',
      params,
    });
  };
};

export const useReceiptAssignReceiptToAssignmentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptAssignReceiptToAssignmentHook>>
    >,
    TError,
    { params?: ReceiptAssignReceiptToAssignmentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptAssignReceiptToAssignmentHook>>
  >,
  TError,
  { params?: ReceiptAssignReceiptToAssignmentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const receiptAssignReceiptToAssignment =
    useReceiptAssignReceiptToAssignmentHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptAssignReceiptToAssignmentHook>>
    >,
    { params?: ReceiptAssignReceiptToAssignmentParams }
  > = (props) => {
    const { params } = props ?? {};

    return receiptAssignReceiptToAssignment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiptAssignReceiptToAssignmentMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptAssignReceiptToAssignmentHook>>
  >
>;

export type ReceiptAssignReceiptToAssignmentMutationError = unknown;

export const useReceiptAssignReceiptToAssignment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptAssignReceiptToAssignmentHook>>
    >,
    TError,
    { params?: ReceiptAssignReceiptToAssignmentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptAssignReceiptToAssignmentHook>>
  >,
  TError,
  { params?: ReceiptAssignReceiptToAssignmentParams },
  TContext
> => {
  const mutationOptions =
    useReceiptAssignReceiptToAssignmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useReceiptUpdateProductExpenseTypeMappingHook = () => {
  const receiptUpdateProductExpenseTypeMapping =
    useReceiptUpdateProductExpenseTypeMappingMutator<void>();

  return (productExpenseTypeMappingDto: ProductExpenseTypeMappingDto) => {
    return receiptUpdateProductExpenseTypeMapping({
      url: `/api/Receipt/UpdateProductExpenseTypeMapping`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: productExpenseTypeMappingDto,
    });
  };
};

export const useReceiptUpdateProductExpenseTypeMappingMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptUpdateProductExpenseTypeMappingHook>
      >
    >,
    TError,
    { data: ProductExpenseTypeMappingDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptUpdateProductExpenseTypeMappingHook>>
  >,
  TError,
  { data: ProductExpenseTypeMappingDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const receiptUpdateProductExpenseTypeMapping =
    useReceiptUpdateProductExpenseTypeMappingHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptUpdateProductExpenseTypeMappingHook>
      >
    >,
    { data: ProductExpenseTypeMappingDto }
  > = (props) => {
    const { data } = props ?? {};

    return receiptUpdateProductExpenseTypeMapping(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiptUpdateProductExpenseTypeMappingMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptUpdateProductExpenseTypeMappingHook>>
  >
>;
export type ReceiptUpdateProductExpenseTypeMappingMutationBody =
  ProductExpenseTypeMappingDto;
export type ReceiptUpdateProductExpenseTypeMappingMutationError = unknown;

export const useReceiptUpdateProductExpenseTypeMapping = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptUpdateProductExpenseTypeMappingHook>
      >
    >,
    TError,
    { data: ProductExpenseTypeMappingDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptUpdateProductExpenseTypeMappingHook>>
  >,
  TError,
  { data: ProductExpenseTypeMappingDto },
  TContext
> => {
  const mutationOptions =
    useReceiptUpdateProductExpenseTypeMappingMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useReceiptGetProductExpenseTypeMappingsHook = () => {
  const receiptGetProductExpenseTypeMappings =
    useReceiptGetProductExpenseTypeMappingsMutator<
      ProductExpenseTypeMappingDto[]
    >();

  return (signal?: AbortSignal) => {
    return receiptGetProductExpenseTypeMappings({
      url: `/api/Receipt/GetProductExpenseTypeMappings`,
      method: 'GET',
      signal,
    });
  };
};

export const getReceiptGetProductExpenseTypeMappingsQueryKey = () => {
  return [`/api/Receipt/GetProductExpenseTypeMappings`] as const;
};

export const useReceiptGetProductExpenseTypeMappingsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useReceiptGetProductExpenseTypeMappingsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetProductExpenseTypeMappingsHook>>
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getReceiptGetProductExpenseTypeMappingsQueryKey();

  const receiptGetProductExpenseTypeMappings =
    useReceiptGetProductExpenseTypeMappingsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetProductExpenseTypeMappingsHook>>
    >
  > = ({ signal }) => receiptGetProductExpenseTypeMappings(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetProductExpenseTypeMappingsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ReceiptGetProductExpenseTypeMappingsQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptGetProductExpenseTypeMappingsHook>>
  >
>;
export type ReceiptGetProductExpenseTypeMappingsQueryError = unknown;

export const useReceiptGetProductExpenseTypeMappings = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useReceiptGetProductExpenseTypeMappingsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetProductExpenseTypeMappingsHook>>
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useReceiptGetProductExpenseTypeMappingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useReceiptBulkImportFromExternalProviderHook = () => {
  const receiptBulkImportFromExternalProvider =
    useReceiptBulkImportFromExternalProviderMutator<Blob>();

  return (
    bulkImportFromExternalProviderDto: BulkImportFromExternalProviderDto[],
    params?: ReceiptBulkImportFromExternalProviderParams
  ) => {
    return receiptBulkImportFromExternalProvider({
      url: `/api/Receipt/BulkImportFromExternalProvider`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: bulkImportFromExternalProviderDto,
      params,
      responseType: 'blob',
    });
  };
};

export const useReceiptBulkImportFromExternalProviderMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptBulkImportFromExternalProviderHook>
      >
    >,
    TError,
    {
      data: BulkImportFromExternalProviderDto[];
      params?: ReceiptBulkImportFromExternalProviderParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptBulkImportFromExternalProviderHook>>
  >,
  TError,
  {
    data: BulkImportFromExternalProviderDto[];
    params?: ReceiptBulkImportFromExternalProviderParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const receiptBulkImportFromExternalProvider =
    useReceiptBulkImportFromExternalProviderHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptBulkImportFromExternalProviderHook>
      >
    >,
    {
      data: BulkImportFromExternalProviderDto[];
      params?: ReceiptBulkImportFromExternalProviderParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return receiptBulkImportFromExternalProvider(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiptBulkImportFromExternalProviderMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptBulkImportFromExternalProviderHook>>
  >
>;
export type ReceiptBulkImportFromExternalProviderMutationBody =
  BulkImportFromExternalProviderDto[];
export type ReceiptBulkImportFromExternalProviderMutationError = unknown;

export const useReceiptBulkImportFromExternalProvider = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptBulkImportFromExternalProviderHook>
      >
    >,
    TError,
    {
      data: BulkImportFromExternalProviderDto[];
      params?: ReceiptBulkImportFromExternalProviderParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptBulkImportFromExternalProviderHook>>
  >,
  TError,
  {
    data: BulkImportFromExternalProviderDto[];
    params?: ReceiptBulkImportFromExternalProviderParams;
  },
  TContext
> => {
  const mutationOptions =
    useReceiptBulkImportFromExternalProviderMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useReceiptAttemptBulkConnectExternalLineItemsToExpensesHook =
  () => {
    const receiptAttemptBulkConnectExternalLineItemsToExpenses =
      useReceiptAttemptBulkConnectExternalLineItemsToExpensesMutator<Blob>();

    return (
      assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody
    ) => {
      return receiptAttemptBulkConnectExternalLineItemsToExpenses({
        url: `/api/Receipt/AttemptBulkConnectExternalLineItemsToExpenses`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
        responseType: 'blob',
      });
    };
  };

export const useReceiptAttemptBulkConnectExternalLineItemsToExpensesMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useReceiptAttemptBulkConnectExternalLineItemsToExpensesHook
          >
        >
      >,
      TError,
      {
        data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useReceiptAttemptBulkConnectExternalLineItemsToExpensesHook
        >
      >
    >,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const receiptAttemptBulkConnectExternalLineItemsToExpenses =
      useReceiptAttemptBulkConnectExternalLineItemsToExpensesHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useReceiptAttemptBulkConnectExternalLineItemsToExpensesHook
          >
        >
      >,
      {
        data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
      }
    > = (props) => {
      const { data } = props ?? {};

      return receiptAttemptBulkConnectExternalLineItemsToExpenses(data);
    };

    return { mutationFn, ...mutationOptions };
  };

export type ReceiptAttemptBulkConnectExternalLineItemsToExpensesMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useReceiptAttemptBulkConnectExternalLineItemsToExpensesHook
        >
      >
    >
  >;
export type ReceiptAttemptBulkConnectExternalLineItemsToExpensesMutationBody =
  AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
export type ReceiptAttemptBulkConnectExternalLineItemsToExpensesMutationError =
  unknown;

export const useReceiptAttemptBulkConnectExternalLineItemsToExpenses = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useReceiptAttemptBulkConnectExternalLineItemsToExpensesHook
        >
      >
    >,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useReceiptAttemptBulkConnectExternalLineItemsToExpensesHook
      >
    >
  >,
  TError,
  { data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody },
  TContext
> => {
  const mutationOptions =
    useReceiptAttemptBulkConnectExternalLineItemsToExpensesMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
export const useReceiptGetMyUnmappedReceiptLineItemsHook = () => {
  const receiptGetMyUnmappedReceiptLineItems =
    useReceiptGetMyUnmappedReceiptLineItemsMutator<ReceiptLineItemDTO[]>();

  return (
    getMyUnmappedReceiptLineItemsRequest: GetMyUnmappedReceiptLineItemsRequest
  ) => {
    return receiptGetMyUnmappedReceiptLineItems({
      url: `/api/Receipt/GetMyUnmappedReceiptLineItems`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getMyUnmappedReceiptLineItemsRequest,
    });
  };
};

export const useReceiptGetMyUnmappedReceiptLineItemsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsHook>>
    >,
    TError,
    { data: GetMyUnmappedReceiptLineItemsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsHook>>
  >,
  TError,
  { data: GetMyUnmappedReceiptLineItemsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const receiptGetMyUnmappedReceiptLineItems =
    useReceiptGetMyUnmappedReceiptLineItemsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsHook>>
    >,
    { data: GetMyUnmappedReceiptLineItemsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return receiptGetMyUnmappedReceiptLineItems(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiptGetMyUnmappedReceiptLineItemsMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsHook>>
  >
>;
export type ReceiptGetMyUnmappedReceiptLineItemsMutationBody =
  GetMyUnmappedReceiptLineItemsRequest;
export type ReceiptGetMyUnmappedReceiptLineItemsMutationError = unknown;

export const useReceiptGetMyUnmappedReceiptLineItems = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsHook>>
    >,
    TError,
    { data: GetMyUnmappedReceiptLineItemsRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsHook>>
  >,
  TError,
  { data: GetMyUnmappedReceiptLineItemsRequest },
  TContext
> => {
  const mutationOptions =
    useReceiptGetMyUnmappedReceiptLineItemsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useReceiptGetMyUnmappedReceiptLineItemsCountHook = () => {
  const receiptGetMyUnmappedReceiptLineItemsCount =
    useReceiptGetMyUnmappedReceiptLineItemsCountMutator<number>();

  return () => {
    return receiptGetMyUnmappedReceiptLineItemsCount({
      url: `/api/Receipt/GetMyUnmappedReceiptLineItemsCount`,
      method: 'POST',
    });
  };
};

export const useReceiptGetMyUnmappedReceiptLineItemsCountMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsCountHook>
      >
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsCountHook>
    >
  >,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const receiptGetMyUnmappedReceiptLineItemsCount =
    useReceiptGetMyUnmappedReceiptLineItemsCountHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsCountHook>
      >
    >,
    void
  > = () => {
    return receiptGetMyUnmappedReceiptLineItemsCount();
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiptGetMyUnmappedReceiptLineItemsCountMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsCountHook>
      >
    >
  >;

export type ReceiptGetMyUnmappedReceiptLineItemsCountMutationError = unknown;

export const useReceiptGetMyUnmappedReceiptLineItemsCount = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsCountHook>
      >
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useReceiptGetMyUnmappedReceiptLineItemsCountHook>
    >
  >,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    useReceiptGetMyUnmappedReceiptLineItemsCountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useReceiptChangeValidStatusOfExternalReceiptLineItemHook = () => {
  const receiptChangeValidStatusOfExternalReceiptLineItem =
    useReceiptChangeValidStatusOfExternalReceiptLineItemMutator<void>();

  return (params?: ReceiptChangeValidStatusOfExternalReceiptLineItemParams) => {
    return receiptChangeValidStatusOfExternalReceiptLineItem({
      url: `/api/Receipt/ChangeValidStatusOfExternalReceiptLineItem`,
      method: 'POST',
      params,
    });
  };
};

export const useReceiptChangeValidStatusOfExternalReceiptLineItemMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useReceiptChangeValidStatusOfExternalReceiptLineItemHook
          >
        >
      >,
      TError,
      { params?: ReceiptChangeValidStatusOfExternalReceiptLineItemParams },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useReceiptChangeValidStatusOfExternalReceiptLineItemHook
        >
      >
    >,
    TError,
    { params?: ReceiptChangeValidStatusOfExternalReceiptLineItemParams },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const receiptChangeValidStatusOfExternalReceiptLineItem =
      useReceiptChangeValidStatusOfExternalReceiptLineItemHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useReceiptChangeValidStatusOfExternalReceiptLineItemHook
          >
        >
      >,
      { params?: ReceiptChangeValidStatusOfExternalReceiptLineItemParams }
    > = (props) => {
      const { params } = props ?? {};

      return receiptChangeValidStatusOfExternalReceiptLineItem(params);
    };

    return { mutationFn, ...mutationOptions };
  };

export type ReceiptChangeValidStatusOfExternalReceiptLineItemMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useReceiptChangeValidStatusOfExternalReceiptLineItemHook
        >
      >
    >
  >;

export type ReceiptChangeValidStatusOfExternalReceiptLineItemMutationError =
  unknown;

export const useReceiptChangeValidStatusOfExternalReceiptLineItem = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useReceiptChangeValidStatusOfExternalReceiptLineItemHook
        >
      >
    >,
    TError,
    { params?: ReceiptChangeValidStatusOfExternalReceiptLineItemParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useReceiptChangeValidStatusOfExternalReceiptLineItemHook
      >
    >
  >,
  TError,
  { params?: ReceiptChangeValidStatusOfExternalReceiptLineItemParams },
  TContext
> => {
  const mutationOptions =
    useReceiptChangeValidStatusOfExternalReceiptLineItemMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
