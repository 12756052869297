import { faArrowUp, faCarSide } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByArea } from 'api/assignment/assignment';
import {
  AssignmentModel,
  AssignmentStatusEnum,
  AssignmentTypeEnum,
  AssignmentViewModel,
  CaseModel,
  DrivingPolicyReportDto,
} from 'api/model';
import { useSetupGetBasicData } from 'api/setup/setup';
import TextButton from 'components/inputs/TextButton';
import Modal from 'components/Modal';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import useCompanies from 'contexts/basicData/useCompanies';
import useTranslations from 'contexts/basicData/useTranslations';
import useModalStack from 'contexts/modal/useModalStack';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { openGoogleMapsDirections } from 'utils/address-helper';
import { getHoursAndMinutesFromMillisecondsString } from 'utils/date-helpers';
import { showException } from 'utils/exception-helper';
import { makeNumberComparator, makeStringComparator } from 'utils/sorting';

const ModalTextButton = styled(TextButton)`
  text-align: left;
  padding: 0px;
`;

const Header = styled.h3`
  margin-bottom: 10px;
`;

const Span = styled.span`
  white-space: wrap;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InvalidRowClassName = 'invalid-row';

const TableStylerWrapper = styled.div`
  display: contents;

  .${InvalidRowClassName} {
    color: ${({ theme }) => theme.colors.foreground.error};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const getAssignmentPriority = (assignment: AssignmentViewModel) => {
  // assignmenttype  Normal or WithTest is Prio 1
  // assignmenttype FurtherTransport is Prio 2

  if (
    (
      [
        AssignmentTypeEnum.Normal,
        AssignmentTypeEnum.WithTest,
      ] as AssignmentTypeEnum[]
    ).includes(assignment.assignment.assignmentTypeID)
  ) {
    return 1;
  }

  if (
    assignment.assignment.assignmentTypeID ===
    AssignmentTypeEnum.FurtherTransport
  ) {
    return 2;
  }

  return 3;
};

const getAssignmentPriorityIcon = (prio: number): React.ReactNode => {
  switch (prio) {
    case 1:
      return (
        <IconWrapper>
          <FontAwesomeIcon color="red" icon={faArrowUp} />
        </IconWrapper>
      );

    default:
      return null;
  }
};

type ReturnAssignmentFinderProps = {
  assignmentModel: AssignmentModel;
  caseModel: CaseModel;
  drivingPolicyReport?: DrivingPolicyReportDto;
};

type ReturnAssignmentFinderModalProps = ReturnAssignmentFinderProps & {
  handleClose: () => void;
};

const ReturnAssignmentFinderModal: React.FC<
  ReturnAssignmentFinderModalProps
> = ({ assignmentModel, caseModel, drivingPolicyReport, handleClose }) => {
  const { companiesByAddrDict } = useCompanies();
  const basicdata = useSetupGetBasicData();
  const consecutiveHoursOfRestLast24Hours =
    basicdata.data?.consecutiveHoursOfRestLast24Hours ?? 0;
  const workHoursToday = drivingPolicyReport?.workHoursToday ?? 0;
  const { caseTypes, assignmentTypes } = useTranslations();
  const getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall =
    useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByArea();

  const searchAssignments = useCallback(async () => {
    try {
      await getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall.mutateAsync(
        {
          params: {
            zipCode: assignmentModel.toZip,
            zipCodeAreaId: assignmentModel.toCounty?.id,
            approximateStartTime: assignmentModel.endTime,
          },
        }
      );
    } catch (error) {
      showException(error);
    }
  }, [
    assignmentModel.endTime,
    assignmentModel.toCounty?.id,
    assignmentModel.toZip,
    getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall,
  ]);

  useEffect(() => {
    searchAssignments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToCase = (caseID: number) => {
    // open the case in a new tab with the caseID
    window.open(`/sok/${caseID}`, caseModel.caseID.toString());
  };

  const getWorkTimeIncludingNewAssignment = useCallback(
    (assignment: AssignmentModel) => {
      // get the current estimated duration in hours (default to 0)
      const estimatedDuration = assignment.estimatedDuration ?? 0;
      const alreadyUsedTime = workHoursToday * 60 * 60 * 1000;

      return estimatedDuration + alreadyUsedTime;
    },
    [workHoursToday]
  );

  const getTimeLeftIncludingNewAssignment = useCallback(
    (assignment: AssignmentModel) => {
      // get the current estimated duration in hours (default to 0)
      const estimatedDuration = assignment.estimatedDuration ?? 0;
      const totalTimeAllowedToday =
        (24 - consecutiveHoursOfRestLast24Hours) * 60 * 60 * 1000;
      const alreadyUsedTime = workHoursToday * 60 * 60 * 1000;

      const timeLeftOfDay =
        totalTimeAllowedToday - estimatedDuration - alreadyUsedTime;

      return timeLeftOfDay;
    },
    [consecutiveHoursOfRestLast24Hours, workHoursToday]
  );

  const orderedByDistanceAndFilteredByDuration = useMemo(() => {
    // sort by zipcode proximity to assignment.toZip
    try {
      const startZip = Number(assignmentModel.toZip.replace(/\D/g, ''));
      const filteredData =
        getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall.data?.filter(
          (a) => {
            if (!a.assignment.estimatedDuration) {
              return false;
            }
            return getTimeLeftIncludingNewAssignment(a.assignment) > 0;
          }
        );
      const orderedData = filteredData?.sort((a, b) => {
        // const durationA = a.assignment.estimatedDuration ?? 0;
        // const durationB = b.assignment.estimatedDuration ?? 0;
        const zipA = Number(a.assignment.fromZip.replace(/\D/g, ''));
        const zipB = Number(b.assignment.fromZip.replace(/\D/g, ''));
        return Math.abs(zipA - startZip) - Math.abs(zipB - startZip);
      });
      return orderedData ?? [];
    } catch (error) {
      return [];
    }
  }, [
    assignmentModel.toZip,
    getTimeLeftIncludingNewAssignment,
    getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall.data,
  ]);

  const columnSettings = useMemo((): ColumnSetting<
    AssignmentViewModel,
    any
  >[] => {
    return [
      {
        head: 'Prio',
        render: (a) => getAssignmentPriorityIcon(getAssignmentPriority(a)),
        width: 50,
        sortFunction: makeNumberComparator((a) => getAssignmentPriority(a)),
      },
      {
        head: 'Regnr',
        render: (a) => a.case.registrationNumber,
        width: 100,
        sortFunction: makeStringComparator((a) => a.case.registrationNumber),
      },
      {
        head: 'Estimerad tid',
        render: (a) =>
          a.assignment.estimatedDuration
            ? getHoursAndMinutesFromMillisecondsString(
                a.assignment.estimatedDuration
              )
            : '?',
        width: 100,
        sortFunction: makeNumberComparator(
          (a) => a.assignment.estimatedDuration ?? 0
        ),
      },
      {
        head: 'Total tid inkl uppdraget',
        render: (a) =>
          getHoursAndMinutesFromMillisecondsString(
            getWorkTimeIncludingNewAssignment(a.assignment)
          ),
        width: 200,
        sortFunction: makeNumberComparator((a) =>
          getTimeLeftIncludingNewAssignment(a.assignment)
        ),
      },
      {
        head: 'Tid kvar inkl uppdraget',
        render: (a) =>
          getHoursAndMinutesFromMillisecondsString(
            getTimeLeftIncludingNewAssignment(a.assignment)
          ),
        width: 200,
        sortFunction: makeNumberComparator((a) =>
          getTimeLeftIncludingNewAssignment(a.assignment)
        ),
      },
      {
        head: 'Ärendetyp',
        render: (a) => caseTypes[a.case.caseTypeID],
        width: 100,
        sortFunction: makeStringComparator(
          (a) => caseTypes[a.case.caseTypeID] ?? ''
        ),
      },
      {
        head: 'Uppdragstyp',
        render: (a) => assignmentTypes[a.assignment.assignmentTypeID],
        width: 120,
        sortFunction: makeStringComparator(
          (a) => assignmentTypes[a.assignment.assignmentTypeID] ?? ''
        ),
      },
      {
        head: 'Från',
        render: (a) => {
          const addressKey =
            `${a.assignment.fromAddress}${a.assignment.fromZip}${a.assignment.fromCity}`
              .toLowerCase()
              .replaceAll(' ', '');
          const addressString =
            companiesByAddrDict.get(addressKey)?.name ??
            `${a.assignment.fromAddress}, ${a.assignment.fromZip}, ${a.assignment.fromCity}`;
          return <Span>{addressString}</Span>;
        },
        width: 200,
        sortFunction: makeStringComparator((a) => a.assignment.fromAddress),
      },
      {
        head: '',
        render: (a) => (
          <TextButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              openGoogleMapsDirections({
                fromAddress: assignmentModel.toAddress,
                fromCity: assignmentModel.toCity,
                fromZip: assignmentModel.toZip,
                toAddress: a.assignment.fromAddress,
                toCity: a.assignment.fromCity,
                toZip: a.assignment.fromZip,
              });
            }}
          >
            <img
              alt="Google maps icon"
              height={15}
              src="/images/googlemapsiconsmall.png"
              width={15}
            />
          </TextButton>
        ),
        width: 40,
      },
    ];
  }, [
    assignmentModel.toAddress,
    assignmentModel.toCity,
    assignmentModel.toZip,
    assignmentTypes,
    caseTypes,
    companiesByAddrDict,
    getTimeLeftIncludingNewAssignment,
    getWorkTimeIncludingNewAssignment,
  ]);

  return (
    <Modal
      buttons={[
        {
          label: 'Stäng',
          onClick: handleClose,
        },
      ]}
      closeOnOutsideClick={false}
      onClose={handleClose}
      title="Välj returuppdrag"
    >
      <ContentWrapper>
        {getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Header>
              Möjliga returuppdrag från Zäkra Hub inom länet för{' '}
              {caseModel.registrationNumber}
            </Header>
            {orderedByDistanceAndFilteredByDuration?.length === 0 ? (
              <span>Inga returuppdrag hittades</span>
            ) : (
              <TableStylerWrapper>
                <Table
                  rows={orderedByDistanceAndFilteredByDuration}
                  onRowClick={(row) => goToCase(row.case.caseID)}
                  columnSettings={columnSettings}
                  rowClassName={(row) => {
                    if (row.assignment.estimatedDuration === null) {
                      return InvalidRowClassName;
                    }
                    const isWithinTime =
                      getTimeLeftIncludingNewAssignment(row.assignment) > 0;
                    return isWithinTime ? '' : InvalidRowClassName;
                  }}
                  initialSortState={{
                    ascending: true,
                    sortedColumnIndex: 0,
                  }}
                />
              </TableStylerWrapper>
            )}
          </>
        )}
      </ContentWrapper>
    </Modal>
  );
};

const ReturnAssignmentFinder: React.FC<ReturnAssignmentFinderProps> = (
  props
) => {
  const modalStack = useModalStack();
  const { assignmentModel } = props;
  if (assignmentModel.assignmentStatusID > AssignmentStatusEnum.Accepted) {
    return null; // no need to show the modal if the assignment is already accepted
  }

  return (
    <ModalTextButton
      onClick={() => {
        modalStack.push(
          <ReturnAssignmentFinderModal
            {...props}
            handleClose={() => modalStack.pop()}
          />
        );
      }}
    >
      Hitta returuppdrag <FontAwesomeIcon icon={faCarSide} flip="horizontal" />
    </ModalTextButton>
  );
};

export default ReturnAssignmentFinder;
