import Modal, { ModalButton } from 'components/Modal';
import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';

import {
  ApiCallGlobalConfig,
  ApiCallGlobalConfigContext,
} from 'swaggerhooks/lib';

import {
  ApiException,
  AuthenticationClient,
  LoginResponse,
  RefreshTokenLoginRequest,
} from 'api';
import useAuthenticationState from '../authentication/useAuthentication';
import useModalStack from '../modal/useModalStack';
import { pollersErrorModalId } from 'constants/AppConstants';
import ApiErrorModalIds from './ApiErrorModalIds';
import { ApiExceptionResponse } from 'api/types';
import { arraySpreadIf } from 'utils/spreading';
import ErrorMessageModal from './ErrorMessageModal';

const Pre = styled.pre`
  max-width: 90vw;
  max-height: 80vh;
  overflow: auto;
`;

const ApiCallConfiguration = ({ children }: { children: React.ReactNode }) => {
  const modalStack = useModalStack();
  const { state: authState, refreshTokens, signout } = useAuthenticationState();

  const authStateRef = useRef(authState);
  authStateRef.current = authState;

  const validateRefreshTokenCaller =
    useRef<Promise<LoginResponse | null> | null>(null);
  const isValidatingRefreshToken = useRef(false);

  const authorizationHeaderFetch = useCallback(
    (
      [...args]: Parameters<typeof fetch>,
      token: string
    ): ReturnType<typeof fetch> =>
      fetch(args[0], {
        ...(args[1] ?? {}),
        headers: {
          ...(args[1]?.headers ?? {}),
          Authorization: `Bearer ${token}`,
        },
      }),
    []
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const tokenAutorefreshFetch: typeof fetch = useCallback(
    async (...args: Parameters<typeof fetch>) => {
      const { token, refreshToken } = authStateRef.current;

      if (!token) {
        return fetch(...args);
      }

      let upToDateToken = token;
      // If a new token is being fetched, wait for it instead of trying to fetch with the current, expired token.
      if (isValidatingRefreshToken.current) {
        upToDateToken =
          (await validateRefreshTokenCaller.current)?.token ?? token;
      }
      const response = await authorizationHeaderFetch(args, upToDateToken);

      // If we got 401 unauthorized response, try to get a new token and then redo the api call.
      if (response.status === 401) {
        if (!refreshToken) {
          signout();
          return response;
        }

        // There should only be one validateRefreshToken api call running at a time. If there's none currently running, start one.
        // If 'token' does not equal 'authStateRef.current.token', we won't fetch a new token,
        // because a new one was fetched in the background while one of the 'await':s above was running.
        // await:ing 'validateRefreshTokenCaller.current' will return the latest fetched token.
        if (
          !isValidatingRefreshToken.current &&
          token === authStateRef.current.token
        ) {
          isValidatingRefreshToken.current = true;

          validateRefreshTokenCaller.current = (async () => {
            const authClient = new AuthenticationClient(undefined, {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              fetch: (...args2) => authorizationHeaderFetch(args2, token),
            });

            let refreshTokenResponse: LoginResponse | null = null;
            try {
              refreshTokenResponse = await authClient.validateRefreshToken(
                new RefreshTokenLoginRequest({
                  refreshToken,
                  token,
                })
              );

              if (
                refreshTokenResponse.token &&
                refreshTokenResponse.refreshToken
              ) {
                refreshTokens(
                  refreshTokenResponse.token,
                  refreshTokenResponse.refreshToken,
                  refreshTokenResponse.userId
                );
              }
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log('Error while trying to refresh login token', err);
              signout();
            }

            return refreshTokenResponse;
          })();
        }

        // validatingRefreshTokenPromise.current should never be null here, but TS likes this "if" :P
        if (validateRefreshTokenCaller.current) {
          const refreshTokenResponse = await validateRefreshTokenCaller.current;
          isValidatingRefreshToken.current = false;

          if (
            refreshTokenResponse?.token &&
            refreshTokenResponse?.refreshToken
          ) {
            // do the api call again, but now with updated token
            return authorizationHeaderFetch(args, refreshTokenResponse.token);
          }
        }
      }
      return response;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const apiCallConfig = useMemo(
    (): ApiCallGlobalConfig => ({
      onApiError: (err, apiCallOptions) => {
        const errorModal = (
          modalTypeId: string,
          title: string,
          body: React.ReactNode,
          copyableError?: string
        ) =>
          modalStack.push(
            <Modal
              buttons={[
                {
                  label: 'Stäng',
                  onClick: () => {
                    modalStack.pop(modalTypeId);
                    modalStack.pop(apiCallOptions.errorModalId);
                    modalStack.pop(pollersErrorModalId);
                  },
                },
                ...arraySpreadIf<ModalButton>(copyableError !== undefined, {
                  label: 'Visa felmeddelande',
                  onClick: () => {
                    modalStack.push(
                      <ErrorMessageModal
                        errorMessage={copyableError ?? 'no error message'}
                      />
                    );
                  },
                }),
              ]}
              title={title}
            >
              {body}
            </Modal>,
            apiCallOptions.errorModalId ?? modalTypeId
          );

        if (err instanceof ApiException) {
          try {
            const errorResponse = JSON.parse(
              err.response
            ) as ApiExceptionResponse;
            if (err.status === 403) {
              errorModal(
                ApiErrorModalIds.AccessDenied,
                'Fel',
                'Du har tyvärr inte tillgång till den här funktionen.'
              );
            } else if (err.status === 401) {
              errorModal(
                ApiErrorModalIds.LoggedOut,
                'Utloggad',
                'Du har blivit utloggad. Logga in och försök igen.'
              );
            } else if (err.status === 409) {
              errorModal(
                ApiErrorModalIds.ConcurrencyError,
                'Fel',
                'En nyare version av det här objektet finns redan sparat. Avbryt och försök igen.'
              );
            } else {
              console.log('errorResponse', errorResponse);

              errorModal(
                ApiErrorModalIds.ServerError,
                'Fel',
                <>
                  {errorResponse.title}
                  <br />
                  <br />
                  {process.env.NODE_ENV !== 'development' && (
                    <Pre>{errorResponse.detail}</Pre>
                  )}
                </>,
                err.response
              );
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log('failed to parse error');
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('err', err);

          if (err instanceof TypeError) {
            errorModal(
              ApiErrorModalIds.ConnectionError,
              'Anslutningsfel',
              'Kunde inte ansluta till servern. Kontrollera din internetanslutning.'
            );
          }
        }
      },
      constructClient: (clientConstructor) =>
        // eslint-disable-next-line new-cap
        new clientConstructor(undefined, {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetch: authStateRef.current.token
            ? (tokenAutorefreshFetch as any)
            : (...args: Parameters<typeof fetch>) => fetch(...args), // Chrome didn't like when I passed down window.fetch directly
        }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokenAutorefreshFetch, authState]
  );

  return (
    <ApiCallGlobalConfigContext.Provider value={apiCallConfig}>
      {children}
    </ApiCallGlobalConfigContext.Provider>
  );
};

export default ApiCallConfiguration;
