import { CaseEventType, EventModel } from 'api';
import { useMemo, useState } from 'react';

export interface EventFilter {
  name: string;
  eventTypes: Set<CaseEventType>;
}

export const commentEventFilter: EventFilter = {
  name: 'Kommentarer',
  eventTypes: new Set([
    CaseEventType.Comment,
    CaseEventType.InternalComment,
    CaseEventType.FlaggedForSpeeding,
    CaseEventType.DeviationReport,
    CaseEventType.CarParkPhoto,
    CaseEventType.CarKeyAndDrivingLicensePhoto,
    CaseEventType.CarKeyPhoto,
  ]),
};

// export const eventsEventFilter: EventFilter = {
//   name: 'Händelser',
//   eventTypes: new Set([
//     CaseEventType.CaseEdited,
//     CaseEventType.CasePending,
//     CaseEventType.CaseDeleted,
//     CaseEventType.AssignmentCreated,
//     CaseEventType.AssignmentEdited,
//     CaseEventType.AssignmentAssigned,
//     CaseEventType.AssignmentAccepted,
//     CaseEventType.AssignmentStarted,
//     CaseEventType.AssignmentArrived,
//     CaseEventType.AssignmentReturning,
//     CaseEventType.AssignmentCompleted,
//     CaseEventType.AssignmentDeleted,
//     CaseEventType.AssignmentApproved,
//     CaseEventType.AssignmentDeclined,
//     CaseEventType.AssignmentTestComplete,
//     CaseEventType.CustomerStatusSMSSuccess,
//     CaseEventType.CustomerStatusSMSFail,
//   ]),
// };

export const rideUpdatesEventFilter: EventFilter = {
  name: 'Ride',
  eventTypes: new Set([CaseEventType.RideChange, CaseEventType.RideComment]),
};

// export const customerUpdateSMSFilter: EventFilter = {
//   name: 'SMS-uppdateringar',
//   eventTypes: new Set([
//     CaseEventType.CustomerStatusSMSSuccess,
//     CaseEventType.CustomerStatusSMSFail,
//   ]),
// };

export function applyEventFilter(
  events: EventModel[],
  eventFilter: EventFilter
) {
  return events.filter((eve) => eventFilter.eventTypes.has(eve.caseEventType));
}

const useFilteredEvents = (events: EventModel[]) => {
  const [selectedFilter, setSelectedFilter] = useState<EventFilter | null>(
    null
  );

  return useMemo(
    () => ({
      events: (selectedFilter
        ? applyEventFilter(events, selectedFilter)
        : events
      ).sort((a, b) => ((a.created ?? 0) > (b.created ?? 0) ? -1 : 1)),

      selectedFilter,
      setSelectedFilter,
    }),
    [events, selectedFilter]
  );
};

export default useFilteredEvents;
