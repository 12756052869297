import { faStar as faOutlineStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import AutoSizedTextArea from './inputs/AutoSizedTextArea';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    margin-top: 1em;
    width: 100%;
    max-height: 250px;
    min-width: 100%;
    max-width: 100%;
    min-height: 3em;
    resize: none;
  }
`;

const Info = styled.div<{ warn?: boolean }>`
  margin-left: auto;
  font-size: 0.8em;
  margin-top: 0.5em;
  color: ${(props) =>
    props.warn
      ? props.theme.colors.foreground.warning
      : props.theme.colors.foreground.primary};
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 1.2em;
  margin-bottom: 1em;
`;

// const StyledTextArea = styled(AutoSizedTextArea)`
//   margin-top: 1em;
//   flex: 1;
//   display: flex;
//   width: 100%;
// `;

interface Props {
  initialRating?: number | null;
  onRatingChanged(newRating: number, ratingMessage?: string): void;
  title: string;
}

const RatingWidget: React.FC<Props> = ({
  initialRating,
  onRatingChanged,
  title,
}) => {
  const [rating, setRating] = useState(initialRating);
  const [ratingReason, setRatingReason] = useState<string | undefined>();
  const [currentHover, setCurrentHover] = useState<number | undefined>();

  useEffect(() => {
    setRating(initialRating);
  }, [initialRating]);

  const handleRating = (newRating: number) => {
    setRating(newRating);
    onRatingChanged(newRating, ratingReason);
  };

  const handleRatingReason = (newReason: string) => {
    setRatingReason(newReason);
    onRatingChanged(rating ?? 0, newReason);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i += 1) {
      const isFilled =
        (currentHover !== undefined && i <= currentHover) ||
        (currentHover === undefined &&
          rating !== undefined &&
          rating !== null &&
          i <= rating);
      stars.push(
        <FontAwesomeIcon
          size="2x"
          icon={isFilled ? faSolidStar : faOutlineStar}
          onMouseOver={() => setCurrentHover(i)}
          onMouseLeave={() => setCurrentHover(undefined)}
          key={i}
          onClick={() => handleRating(i)}
          color={isFilled ? '#FFD700' : '#D3D3D3'}
        />
      );
    }
    return stars;
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Horizontal>{renderStars()}</Horizontal>
      <AutoSizedTextArea
        placeholder="Ange anledning"
        value={ratingReason}
        onChange={(e) => handleRatingReason(e.currentTarget.value)}
      />
      {(rating ?? 0) <= 3 && (
        <Info warn={(ratingReason?.length ?? 0) < 10}>
          {ratingReason?.length ?? 0}/10
        </Info>
      )}
    </Wrapper>
  );
};

export default RatingWidget;
