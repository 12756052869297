/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type CaseEventType = (typeof CaseEventType)[keyof typeof CaseEventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseEventType = {
  CaseEdited: 0,
  CasePending: 1,
  CaseDeleted: 2,
  AssignmentCreated: 3,
  AssignmentEdited: 4,
  AssignmentAssigned: 5,
  AssignmentAccepted: 6,
  AssignmentStarted: 7,
  AssignmentArrived: 8,
  AssignmentReturning: 9,
  AssignmentCompleted: 10,
  AssignmentDeleted: 11,
  Comment: 12,
  AssignmentApproved: 13,
  AssignmentDeclined: 14,
  AssignmentTestComplete: 15,
  CustomerStatusSMSSuccess: 16,
  CustomerStatusSMSFail: 17,
  RideComment: 18,
  RideChange: 19,
  InternalComment: 20,
  AssignmentInspected: 21,
  CarKeyPhoto: 22,
  AutoApprove: 23,
  AutoBreakTime: 24,
  UnmarkedAsInvoiced: 25,
  FlaggedForSpeeding: 26,
  AssignmentUpdateFailed: 27,
  LinkedAssignment: 28,
  AutoLinkedAssignment: 29,
  TaxiRequest: 30,
  BringVehicleHomeRequest: 31,
  DeviationReport: 32,
  CarParkPhoto: 33,
  RequestAssignFieldTesterOutsideDrivingPolicy: 34,
  CarKeyAndDrivingLicensePhoto: 35,
  AssigneeAvailabilityChanged: 36,
  AutoApproveFailed: 37,
  ExpenseConnectedToAssignment: 38,
  WorkGearPhoto: 39,
  WorkGearReport: 40,
  CaseClosed: 41,
  AssignmentVehicleFaultDiscovered: 42,
} as const;
