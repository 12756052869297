/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import CsvDownloader from 'react-csv-downloader';

import {
  faChevronDown,
  faChevronUp,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import {
  ColumnSetting,
  makeColumnClassName,
  tableHeadClassName,
} from './utils';
import useBetaFeatures from 'contexts/basicData/useBetaFeatures';
import Button from 'components/inputs/Button';

export type ColumnProps = { fluid?: boolean };

type ContainerProps = ColumnProps & { inverted?: boolean };

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  ${({ fluid }) =>
    fluid &&
    css`
      width: 100%;
    `}

  ${({ inverted, theme }) =>
    inverted
      ? css`
          background-color: ${theme.colors.background.secondary};
          color: black;
        `
      : css`
          color: white;
          background-color: black;
        `}
  z-index: 1;
`;

const Span = styled.div<ColumnProps>`
  white-space: nowrap;
  ${({ fluid }) =>
    fluid &&
    css`
      overflow: hidden; /* THIS CAUSES TOOLTIP TO FALL BEHIND OTHER ELEMENTS, BUT REMOVING THIS DIS-ALIGNS COLUMNS WIDTHS! */
    `}

  font-weight: bold;
  padding: 5px 3px;
  box-sizing: border-box;
  cursor: default;

  svg {
    font-size: 11px;
    margin-left: 2px;
    padding-bottom: 1px;
    cursor: pointer;
  }
`;

const HeaderButton = styled(Button)`
  height: 30px;
  padding: 0 10px;
  margin-right: 10px;
  font: inherit;
  font-weight: normal;

  & > *:first-child {
    margin-right: 6px;
    font-size: 12px;
  }
`;

interface Props<Row extends Object, RenderProps extends Object> {
  columnSettings: ColumnSetting<Row, RenderProps>[];
  fluid?: boolean;

  toggleSort(columnIndex: number): void;
  sortedColumnIndex: number;
  sortAscending: boolean;
  rows: Row[];
}

const TableHead = <Row extends Object, RenderProps extends Object>({
  columnSettings,
  fluid,
  toggleSort,
  sortedColumnIndex,
  sortAscending,
  rows,
}: Props<Row, RenderProps>) => {
  const [betaFeatures] = useBetaFeatures();
  const csvColumns = columnSettings.filter((setting) => !!setting.csvValue);
  const csvData = [
    csvColumns.map((setting) => setting.head),
    ...rows.map((row, index) =>
      csvColumns.map((setting) => setting.csvValue?.(row, index) ?? '')
    ),
  ] as [string[], ...string[][]];
  return (
    <>
      {betaFeatures?.showCustomTableHead && (
        <Container
          inverted
          style={{
            padding: 5,
            gap: 5,
            alignItems: 'center',
            position: 'relative',
          }}
        >
          Beta-funktioner{' '}
          <CsvDownloader datas={csvData} filename="tabledata.csv">
            <HeaderButton>
              <FontAwesomeIcon icon={faDownload} /> Ladda ner
            </HeaderButton>
          </CsvDownloader>
        </Container>
      )}
      <Container fluid={fluid}>
        {columnSettings.map((setting, i) => (
          <Span
            className={`${makeColumnClassName(i)} ${tableHeadClassName}`}
            fluid={fluid}
            key={i}
            onClick={setting.sortFunction ? () => toggleSort(i) : undefined}
          >
            {setting.head}{' '}
            {i === sortedColumnIndex &&
              setting.sortFunction &&
              (sortAscending ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              ))}
          </Span>
        ))}
      </Container>
    </>
  );
};

export default TableHead;
