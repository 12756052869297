import { FC, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import CaseAssignments from './CaseAssignments';
import CaseData from './CaseData';
import EventsAndComments from './EventsAndComments';
import Expenses from './Expenses';
import LinkedCases from './LinkedCases';
import { useDetailedCaseContext } from './DetailedCaseProvider';
import Checkbox from 'components/inputs/Checkbox';
import { CaseDataField } from './DetailedCaseFormProvider/useCaseDataForm';
import {
  useDetailedCaseDataForm,
  useDetailedCaseFormControl,
} from './DetailedCaseFormProvider';
import {
  AssignmentClient,
  AssignmentStatusEnum,
  CaseExtendedModel,
  CaseModel,
  ExtendedPosition,
} from 'api';
import Map from 'components/map/Map';
import { useApiCall } from 'swaggerhooks/lib';
import Button from 'components/inputs/Button';
import AuthorizedButton from 'components/inputs/AuthorizedButton';
import {
  CustomMarker,
  MapIcons,
  useGoogleMapsContext,
} from 'components/map/GoogleMapsContext';
import { isLessThanXSecondsAgo } from 'utils/date-helpers';
import { swedenCenterCoords } from 'constants/AppConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalButton, modalButtons, ModalButtons } from 'components/Modal';
import MapControls from 'components/map/MapControls';
// import { useInterval } from 'usehooks-ts';
import useTranslations from 'contexts/basicData/useTranslations';
import speed from 'utils/speed';
import useSpeedLimitAlerts from 'contexts/basicData/useSpeedLimitAlerts';
import { CaseEventType } from 'api/model';
import useMe from 'contexts/authentication/useMe';
import { isDrivingPolicyAdmin } from 'constants/Roles';

const caseDataClassName = 'case-data';
const eventsAndCommentsClassName = 'events';
const caseAssignmentsClassName = 'case-assignments';
const expensesClassName = 'expenses';
const mapClassName = 'map';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2.1fr 6fr 2fr;
  grid-template-rows: auto 1fr;
  width: calc(90vw - 45px);
  height: calc(90vh - 150px);

  .${caseDataClassName} {
    grid-area: 1/2/2/3;
  }
  .${caseAssignmentsClassName} {
    grid-area: 2/2/3/3;
  }

  .${caseDataClassName} {
    margin-bottom: 20px;
  }

  .${eventsAndCommentsClassName} {
    grid-area: 1/1/5/2;
    border-right: 1px solid ${({ theme }) => theme.colors.border.light};
    margin-right: 10px;
    padding-right: 10px;
  }
`;

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(90vw - 45px);
  height: calc(90vh - 150px);
`;

const AssignmentsAndLinkedCases = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: calc(90vh - 250px);
  min-height: calc(60vh - 250px);
  overflow: hidden;
`;

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-area: 1/3/5/4;
  border-left: 1px solid ${({ theme }) => theme.colors.border.light};
  margin-left: 10px;
  padding-left: 10px;
  padding-top: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  gap: 5px;
`;

const MapRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

// const MAP_POLLING_INTERVAL_MS = 2000;
// const WARNING_SPEED_LIMIT_KM_PER_HOUR = 120;
// const WARNING_SPEED_LIMIT_M_PER_SEC =
//   (WARNING_SPEED_LIMIT_KM_PER_HOUR * 1000) / 60 / 60;

const MarkRideUpdateAsManaged: FC<{ theCase?: CaseExtendedModel }> = ({
  theCase,
}) => {
  const { editMode } = useDetailedCaseFormControl();
  const { state, checkbox } = useDetailedCaseDataForm();

  if (!editMode || !theCase?.case.newDataFromRide) return null;

  return (
    <Checkbox
      checked={state[CaseDataField.dataFromRideManaged] === 'true'}
      onChange={checkbox(CaseDataField.dataFromRideManaged)}
    >
      Ride-uppdatering hanterad
    </Checkbox>
  );
};

enum ShowPosition {
  OFF,
  ALL_POSITIONS,
  FILTERED_POSITIONS,
}

/** NB: Requires the <DetailedCaseProvider/> context */
type Props = {
  assignmentIdForMarkingAsHandled?: number;
  buttons?: ModalButton[];
  autoFocusAssignmentID?: number;
};

const DetailedCase: FC<Props> = ({
  assignmentIdForMarkingAsHandled,
  buttons,
  autoFocusAssignmentID,
}) => {
  const me = useMe();
  const isDrivingPolicyUser = me
    ? isDrivingPolicyAdmin(me.roles, me.userID)
    : false;
  const speedLimitAlerts = useSpeedLimitAlerts();

  const { caseEventTypes } = useTranslations();
  const { addCustomMarkers, markers, map, clearMarkers, fitBounds } =
    useGoogleMapsContext();

  const [showPositions, setShowPositions] = useState<ShowPosition>(
    ShowPosition.OFF
  );
  const hasFittedBounds = useRef<boolean>(false);
  const [positions, setPositions] = useState<ExtendedPosition[]>([]);

  const [activePositionFilterParams, setActivePositionFilterParams] = useState<{
    assignmentId?: number;
    fromTimestamp?: Date;
    toTimestamp?: Date;
    isLive?: boolean;
  }>();
  const { caseResponse } = useDetailedCaseContext();

  const getAllPositionsApiCall = useApiCall(
    AssignmentClient,
    (
      c,
      caseId: number,
      assignmentId?: number,
      fromTimestamp?: Date,
      toTimestamp?: Date
    ) =>
      c.getCaseAssignmentPositionsForAssignment(
        caseId,
        assignmentId,
        fromTimestamp,
        toTimestamp
      )
  );

  const getMarkerData = useCallback(
    (
      index: number,
      allPositions: ExtendedPosition[],
      position: ExtendedPosition,
      c: CaseModel
    ) => {
      const markerData: CustomMarker = {
        position: {
          lat: position.snappedLatitude ?? position.latitude,
          lng: position.snappedLongitude ?? position.longitude,
        },
        label: c.registrationNumber,
        icon: MapIcons.dot,
        link: `sok/${c.caseID}`,
        timestamp: position.timestamp,
        speed: speed.mpsToKmph(
          Math.max(
            position.speed ??
              speed.calculateSpeed(
                speed.getDistanceInM(position, allPositions[index - 1]),
                allPositions[index - 1]?.timestamp.getTime(),
                position.timestamp.getTime()
              ) ??
              0,
            0
          )
        ),
        missingSpeedData: !position.speed,
        speedLimit: position.speedLimit,
        zIndex: 0,
      };

      if (position.caseEvent) {
        // return case event specific marker data
        markerData.icon =
          MapIcons[position.caseEvent.caseEventType as keyof typeof MapIcons] ??
          MapIcons.dot;
        markerData.timestamp = position.caseEvent.created;
        markerData.zIndex = 2;
        markerData.link = undefined;
        markerData.label = caseEventTypes[position.caseEvent.caseEventType];
        return markerData;
      }
      if (
        index === allPositions.length - 1 &&
        (position.assignmentStatus ?? 0) < AssignmentStatusEnum.Complete
      ) {
        // this marks the last known position, show with vehicle icon
        if (isLessThanXSecondsAgo(position.timestamp, 15 * 60)) {
          if ((position.speed ?? 0) > 1) {
            markerData.icon = MapIcons.vehicleLive;
          } else {
            markerData.icon = MapIcons.vehicleStill;
          }
        } else {
          markerData.icon = MapIcons.vehicleInactive;
        }
      } else if (
        !!position.speedLimit &&
        !!position.speed &&
        speed.alertSpeedLimit(
          speedLimitAlerts,
          speed.mpsToKmph(position.speed),
          position.speedLimit
        )
      ) {
        // if position.speed && position.speedLimit are not null,
        // show MapIcons.warningDot if position.speed exceeds position.speedLimit (converted from kph to m/s)
        markerData.icon = MapIcons.warningDot;
      } else {
        markerData.icon = MapIcons.dot;
      }

      return markerData;
    },
    [caseEventTypes, speedLimitAlerts]
  );

  const getAllPositionsAsync = useCallback(
    async (clearOld?: boolean) => {
      const caseId = caseResponse.response?.case.caseID;
      if (!caseId) return;

      let mostRecentPosition = positions.slice(-1).pop();
      if (clearOld) {
        setPositions([]);
        mostRecentPosition = undefined;
      }

      const [newPositions] = await getAllPositionsApiCall.run(
        caseId,
        activePositionFilterParams?.assignmentId,
        showPositions === ShowPosition.ALL_POSITIONS
          ? mostRecentPosition?.timestamp
          : activePositionFilterParams?.fromTimestamp,
        activePositionFilterParams?.toTimestamp
      );

      setPositions(newPositions ?? []);
    },
    [
      caseResponse.response?.case.caseID,
      getAllPositionsApiCall,
      positions,
      showPositions,
      activePositionFilterParams,
    ]
  );

  useEffect(() => {
    if (activePositionFilterParams) {
      getAllPositionsAsync();
      // hack to get the map to auto-focus after the initial positions have been fetched
      // (only runs once, instead of every time the positions update)
      setTimeout(() => {
        fitBounds();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePositionFilterParams]);

  useEffect(() => {
    if (markers.length > 0 && map && !hasFittedBounds.current) {
      hasFittedBounds.current = true;
      fitBounds();
    }
  }, [markers, map, fitBounds]);

  const showMap = showPositions !== ShowPosition.OFF;
  // update markers when new positions are received
  useEffect(() => {
    if (positions && showMap) {
      clearMarkers();
      const markerData = positions.map((l, i, a) =>
        getMarkerData(i, a, l, caseResponse.response!.case)
      );
      addCustomMarkers(markerData, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions, map, showMap, fitBounds]);

  useEffect(() => {
    if (showPositions) {
      fitBounds();
      map?.setZoom(10);
    }
  }, [showPositions, fitBounds, map]);

  return (
    <Suspense fallback="Laddar...">
      <Wrapper>
        <Grid>
          {!showMap && (
            <>
              <CaseData
                caseResponse={caseResponse.response}
                className={caseDataClassName}
              />

              <EventsAndComments
                className={eventsAndCommentsClassName}
                events={
                  !isDrivingPolicyUser
                    ? caseResponse.response?.events.filter(
                        (e) =>
                          e.caseEventType !==
                          CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy
                      ) ?? []
                    : caseResponse.response?.events ?? []
                }
                theCase={caseResponse.response}
              />

              <AssignmentsAndLinkedCases className={caseAssignmentsClassName}>
                <CaseAssignments
                  autoFocusAssignmentID={autoFocusAssignmentID}
                  theCase={caseResponse.response}
                  assignmentIdForMarkingAsHandled={
                    assignmentIdForMarkingAsHandled
                  }
                />
                <LinkedCases extendedCase={caseResponse.response} />
              </AssignmentsAndLinkedCases>
            </>
          )}

          {showMap && (
            <MapContainer className={mapClassName}>
              <MapWrapper>
                <MapRow>
                  <MapControls
                    caseResponse={caseResponse.response}
                    onFilter={(_assignmentId, _from, _to, _isLive) => {
                      setActivePositionFilterParams({
                        assignmentId: _assignmentId,
                        fromTimestamp: _from,
                        toTimestamp: _to,
                        isLive: _isLive,
                      });
                      setShowPositions(ShowPosition.FILTERED_POSITIONS);
                    }}
                    positions={positions}
                  />
                  <Map
                    addCenterMarker
                    containerHeight={-1}
                    initialCenter={
                      positions?.[positions?.length ?? 0 - 1] ??
                      swedenCenterCoords
                    }
                    positions={positions.map(
                      (p) => new google.maps.LatLng(p.latitude, p.longitude)
                    )}
                    zoom={12}
                  />
                </MapRow>
                <ButtonRow>
                  <Button
                    onClick={() => {
                      hasFittedBounds.current = false;
                      setShowPositions(ShowPosition.OFF);
                    }}
                  >
                    Stäng kartan
                  </Button>
                  <Button
                    icon={<FontAwesomeIcon icon={faExpand} />}
                    onClick={() => fitBounds()}
                  >
                    Centrera kartan
                  </Button>
                </ButtonRow>
              </MapWrapper>
            </MapContainer>
          )}
          {!showMap && (
            <Column>
              <ButtonRow>
                <Button
                  onClick={() => {
                    setShowPositions(ShowPosition.ALL_POSITIONS);
                  }}
                >
                  Visa position
                </Button>
              </ButtonRow>
              <Expenses
                className={expensesClassName}
                expenses={caseResponse.response?.expenses}
                updateCase={() => caseResponse.update()}
              />
            </Column>
          )}
        </Grid>

        {!showMap && (
          <>
            <MarkRideUpdateAsManaged theCase={caseResponse.response} />
            {buttons && buttons.length > 0 && (
              <ModalButtons className={modalButtons}>
                {buttons.map((button, i) => (
                  <AuthorizedButton
                    disabled={button.disabled}
                    icon={button.icon}
                    key={i}
                    onClick={button.onClick}
                    roles={button.roles}
                  >
                    {button.label}
                  </AuthorizedButton>
                ))}
              </ModalButtons>
            )}
          </>
        )}
      </Wrapper>
    </Suspense>
  );
};

export default DetailedCase;
