import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/inputs/Button';
import DatePicker, { DatePickerButton } from 'components/inputs/DatePicker';
import { inputStyle } from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import { FC, forwardRef, ReactChild, useEffect, useState } from 'react';
import styled from 'styled-components';
import { adjustDateToNoon, formatDate } from 'utils/date-helpers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 10px;
`;

const CheckboxWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const MyButton = styled(Button)`
  ${inputStyle}
  background-color: ${(props) => props.theme.colors.background.button};
  color: ${(props) => props.theme.colors.foreground.button};
  height: 30px;
  border-style: none;
`;

const DateRangeInput = forwardRef<
  HTMLButtonElement,
  { daterange: [Date | null, Date | null] }
>(({ daterange, ...props }, ref) => (
  <DatePickerButton ref={ref} type="button" {...props}>
    <div>{daterange[0] && formatDate(daterange[0])}</div>
    <div> — </div>
    <div>{daterange[1] && formatDate(daterange[1])}</div>
    <span>
      <FontAwesomeIcon icon={faCalendarAlt} size="2x" />
    </span>
  </DatePickerButton>
));

interface Props {
  initialFrom?: Date;
  initialTo: Date;
  min?: Date;
  max?: Date;
  onDatesSelected(props: { fromDate: Date; toDate: Date }): void;
  okButtonLabel: ReactChild;
  checkboxLabel?: ReactChild;
  loading?: boolean;
  populateHolidays?: boolean;
  buttonWidth?: number;
  autoRefetchOnChange?: boolean;
  hasDropdowns?: boolean;
  // fromLabel?: string;
  // toLabel?: string;
}

const DateSpanPicker: FC<Props> = ({
  autoRefetchOnChange,
  initialFrom,
  initialTo,
  min,
  max,
  onDatesSelected,
  okButtonLabel,
  checkboxLabel,
  loading,
  populateHolidays,
  buttonWidth,
  hasDropdowns,
}) => {
  const [range, setRange] = useState<[Date | null, Date | null]>([
    initialFrom ?? null,
    initialTo,
  ]);

  const handleSelectClick = () => {
    if (range[0]) {
      onDatesSelected({
        fromDate: range[0],
        toDate: range[1] ?? range[0],
      });
    }
  };

  useEffect(() => {
    if (autoRefetchOnChange) {
      handleSelectClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  return (
    <Wrapper>
      <LabelWrap label="Datumspann">
        <DatePicker
          calendarStartDay={1}
          customInput={<DateRangeInput daterange={range} />}
          dateFormat="yyyy-MM-dd"
          endDate={range[1]}
          locale="sv"
          maxDate={max}
          minDate={min}
          onChange={(r) => {
            const newFrom = adjustDateToNoon(r[0]);
            const newTo = adjustDateToNoon(r[1]);
            setRange([newFrom, newTo]);
          }}
          onWeekSelect={(week) => {
            // set date range to selected week
            const newFrom = adjustDateToNoon(week);
            const in7Days = new Date(newFrom!);
            in7Days.setDate(week.getDate() + 6);
            const newTo = adjustDateToNoon(in7Days);
            setRange([newFrom, newTo]);
          }}
          populateHolidays={populateHolidays}
          selected={range[0]}
          selectsRange
          showWeekNumbers
          showMonthDropdown={hasDropdowns}
          showYearDropdown={hasDropdowns}
          startDate={range[0]}
        />
      </LabelWrap>
      <MyButton
        disabled={loading}
        onClick={handleSelectClick}
        style={{ width: buttonWidth || 220 }}
        type="button"
      >
        {loading ? <TransparentSpinner currentColor small /> : okButtonLabel}
      </MyButton>
      {checkboxLabel && <CheckboxWrap>{checkboxLabel}</CheckboxWrap>}
    </Wrapper>
  );
};

export default DateSpanPicker;
