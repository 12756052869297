import {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  BusinessUnitModel,
  DefaultScheduleDto,
  UpdateDefaultScheduleRequest,
  UserClient,
  UserModel,
  ZipCodeLocalAreaClient,
  ZipCodeLocalAreaModel,
} from 'api';
import Modal from 'components/Modal';
import useUserForm, {
  cleanWeekSchedule,
  UserFormField,
  userFormToUserModel,
} from './useUserForm';
import styled from 'styled-components';
import { RequestStatus, useApiCall, useResponse } from 'swaggerhooks/lib';
import Select from 'components/inputs/Select';
import LabelWrap from 'components/inputs/LabelWrap';
import Input from 'components/inputs/Input';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import MultiSelect, { Option } from 'components/inputs/MultiSelect';
import useTranslations from 'contexts/basicData/useTranslations';
import Checkbox from 'components/inputs/Checkbox';
import MediaQuery from 'constants/MediaQuery';
import WeeklySchedule from 'pages/Employees/WeeklySchedule';
import {
  convertToDateOfBirthString,
  isValidDateString,
} from 'utils/date-helpers';
import filterableUserFields, {
  userPropsArray,
} from 'pages/Employees/filterableUserFields';
import { arraySpreadIf } from 'utils/spreading';
import TextArea from 'components/inputs/TextArea';
import useBusinessUnits from 'contexts/basicData/useBusinessUnits';
import { SelectOption } from 'components/inputs/SearchableSelect';
import Button from 'components/inputs/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCaretDown,
  faCaretRight,
  faFlask,
  faTrashAlt,
  faTrashRestoreAlt,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import {
  getEmptyDefaultScheduleDto,
  ScheduleErrors,
  validateSchedule,
} from 'components/Availability/helpers';
import TextButton from 'components/inputs/TextButton';
import SectionHeader from 'components/SectionHeader';
import useModalStack from 'contexts/modal/useModalStack';
import useTeams from 'contexts/basicData/useTeams';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import UserPaymentCardForm from './UserPaymentCardForm';
import expenseUtils from 'components/DetailedCase/Expenses/expenseUtils';
import { useCompanyDepartmentGetCompanyDepartments } from 'api/company-department/company-department';
import { CompanyDepartmentModel } from 'api/model';

const GlobalFormWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 20px;
  ${MediaQuery.mobileL} {
    grid-template-columns: 1fr;
  }
  ${MediaQuery.laptop} {
    grid-template-columns: 300px 1fr;
  }

  ${MediaQuery.laptopL} {
    grid-template-columns: 300px 250px 1fr;
  }
`;

const MyTextButton = styled(TextButton)<{
  disabled?: boolean;
  inverted?: boolean;
}>`
  color: ${({ theme, disabled, inverted }) =>
    inverted
      ? theme.colors.foreground.primary
      : disabled
        ? theme.colors.foreground.tertiary
        : theme.colors.foreground.button};
  margin-left: auto;
  padding: 0px 10px 0px 0px;
`;

const SubFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`;

const FormWrap = styled.div<{ maxWidth?: number; compact?: boolean }>`
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: ${({ compact }) => (compact ? '0' : '10px')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
`;

const MyLabelWrap = styled(LabelWrap)<{ width?: number; disabled?: boolean }>`
  & > *:last-child {
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
  }
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;

const UserProp = styled.div`
  display: grid;
  grid-template-columns: 22px auto;
`;

const HorizontalWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Paragraph = styled.p`
  padding-bottom: 3px;
`;

const StyledTextArea = styled(TextArea)`
  height: 100px;
`;
interface Props {
  user?: UserModel;
  onCancel?(): void;
  onSaved?(): void;
  canEdit?: boolean;
  title?: string;
}

const ScheduleHeader = styled.div<{ deleted?: boolean }>`
  display: flex;
  /* color: white;
  background-color: rgb(204, 102, 51); */
  background-color: ${({ theme, deleted }) =>
    !deleted
      ? theme.colors.foreground.secondary
      : theme.colors.background.tertiary};
  color: ${({ theme }) => theme.colors.background.primary};
  font-weight: 500;
  border-radius: 3px;
  user-select: none;
  line-height: ${({ theme }) => theme.sizes.font.large};
  padding-left: 20px;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const DefaultScheduleSection = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
`;

export interface DeletableDefaultScheduleDto extends DefaultScheduleDto {
  deleted?: boolean;
}

const UserFormModal: FC<Props> = ({
  user,
  onCancel,
  onSaved,
  canEdit,
  title,
}) => {
  const modalStack = useModalStack();
  const { units } = useBusinessUnits();
  const { teams } = useTeams();
  const me = useMe();

  const [defaultWeekSchedule, setDefaultWeekSchedule] = useState<
    DeletableDefaultScheduleDto[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedScheduleIndex, setSelectedScheduleIndex] = useState<
    number | null
  >(0);
  const [scheduleErrors, setScheduleErrors] = useState<ScheduleErrors[]>([]);
  const [zipCodeLocalArea, setZipCodeLocalArea] = useState<
    ZipCodeLocalAreaModel | undefined
  >(user?.zipCodeLocalArea);
  const { rolesById, employmentStatuses } = useTranslations();
  const { input, select, checkbox, onChange, state } = useUserForm(user);
  const currentState = useMemo(() => userFormToUserModel(state), [state]);
  const createMode = !user;

  const getZipCodeAreaCall = useApiCall(
    ZipCodeLocalAreaClient,
    (c, zipCode: string) => c.findZipCodeLocalAreaByZipCode(zipCode)
  );

  const getCompanyDepartmentsCall = useCompanyDepartmentGetCompanyDepartments();

  // disable isAllowedToChangeAvailability if all schedules are deleted
  useEffect(() => {
    if (defaultWeekSchedule.every((d) => d.deleted)) {
      onChange(UserFormField.isAllowedToChangeAvailability, 'false');
    } else {
      onChange(UserFormField.isAllowedToChangeAvailability, 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultWeekSchedule]);

  useEffect(() => {
    const handleChangedZipCode = async (newCode: string) => {
      const [result] = await getZipCodeAreaCall.run(newCode);
      onChange(UserFormField.zipCodeLocalAreaID, result?.id?.toString() ?? '');
      setZipCodeLocalArea(result ?? undefined);
    };

    if (currentState.postalCode.length > 0) {
      handleChangedZipCode(currentState.postalCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState.postalCode]);

  const createCall = useApiCall(UserClient, (c, userModel: UserModel) =>
    c.createUser(userModel)
  );

  const updateUserCall = useApiCall(
    UserClient,
    (c, request: UpdateDefaultScheduleRequest) =>
      c.updateUserAndSchedule(request)
  );

  const deleteScheduleApiCall = useApiCall(
    UserClient,
    (c, userId: number, scheduleId: number) =>
      c.deleteEmployeeSchedule(userId, scheduleId)
  );

  const defaultWeekScheduleResponse = useResponse(
    UserClient,
    async (c) => {
      if (user) {
        const response = await c.getDefaultEmployeeSchedule(user.userID);
        if (response.length > 0) {
          const errors = validateSchedule(response);

          if (errors?.some((e) => e.errors.length > 0)) {
            setScheduleErrors(errors);
          }
          setDefaultWeekSchedule(response);
        }
      }
    },
    [currentState.userID]
  );

  const businessUnitOptions = useMemo((): SelectOption<
    number | null,
    BusinessUnitModel | null
  >[] => {
    const options: SelectOption<number | null, BusinessUnitModel | null>[] =
      units.map((unit) => ({
        label: unit.name,
        value: unit.id,
        extra: unit,
      }));
    options.unshift({
      label: 'Välj enhet',
      value: null,
      extra: null,
    });
    return options;
  }, [units]);

  const companyDepartmentOptions = useMemo((): SelectOption<
    number | null,
    CompanyDepartmentModel | null
  >[] => {
    const options: SelectOption<
      number | null,
      CompanyDepartmentModel | null
    >[] =
      getCompanyDepartmentsCall.data?.map((unit) => ({
        label: unit.name,
        value: unit.id,
        extra: unit,
      })) ?? [];
    options.unshift({
      label: 'Välj avdelning',
      value: null,
      extra: null,
    });
    return options;
  }, [getCompanyDepartmentsCall.data]);

  const roleOptions = useMemo(
    (): Option<string>[] =>
      Object.entries(rolesById).map(([roleId, roleName]) => ({
        value: roleId,
        label: roleName,
      })),
    [rolesById]
  );

  const employmentStatusOptions = useMemo(
    (): Option<string>[] =>
      Object.entries(employmentStatuses).map(([status, name]) => {
        const option = {
          label: name as string,
          value: status,
        };
        return option;
      }),

    [employmentStatuses]
  );

  const speedCheckOptions = useMemo(() => {
    return Array.from({ length: 11 }, (_, index) => index).map((i) => {
      let label = `${i * 10} % av körningarna`;
      switch (i) {
        case 0:
          label = 'Aldrig';
          break;
        case 10:
          label = 'Alltid';
          break;
        default:
          break;
      }
      return (
        <option key={`${i / 10}`} value={i / 10}>
          {label}
        </option>
      );
    });
  }, []);

  const teamOptions = useMemo(() => {
    const options = teams.map((team) => {
      return (
        <option key={team.id} value={team.id}>
          {team.name}
        </option>
      );
    });
    options.unshift(
      <option key="välj team-option" value={undefined}>
        Välj team
      </option>
    );
    return options;
  }, [teams]);

  const mkInput = (
    name: UserFormField,
    label: string,
    placeholder?: string,
    width?: number
  ) => (
    <MyLabelWrap label={label} width={width}>
      <Input
        disabled={!canEdit}
        onChange={input(name)}
        placeholder={placeholder}
        value={state[name]}
      />
    </MyLabelWrap>
  );

  const mkTextArea = (
    name: UserFormField,
    label: string,
    placeholder?: string,
    width?: number
  ) => (
    <MyLabelWrap label={label} width={width}>
      <StyledTextArea
        disabled={!canEdit}
        onChange={(v) => onChange(name, v.target.value)}
        placeholder={placeholder}
        value={state[name]}
      />
    </MyLabelWrap>
  );

  const mkDateInput = (
    name: UserFormField,
    label: string,
    placeholder?: string
  ) => {
    const value = convertToDateOfBirthString(state[name]);
    return (
      <MyLabelWrap label={label}>
        <Input
          disabled={!canEdit}
          onChange={input(name)}
          placeholder={placeholder}
          value={value}
          warning={!isValidDateString(value)}
        />
      </MyLabelWrap>
    );
  };

  const mkCheckbox = (
    name: UserFormField,
    label: ReactNode,
    disabled?: boolean
  ) => (
    <MyLabelWrap key={name} disabled={disabled}>
      <Checkbox
        checked={state[name] === 'true'}
        disabled={!canEdit || disabled}
        onChange={checkbox(name)}
      >
        {label}
      </Checkbox>
    </MyLabelWrap>
  );

  const handleCreateClick = async () => {
    const [, error] = await createCall.run(userFormToUserModel(state));

    if (!error) {
      onSaved?.();
    }
  };

  const deleteSchedule = async (s: DefaultScheduleDto): Promise<boolean> => {
    if (!user || s.id === undefined) return false;

    const [, deleteError] = await deleteScheduleApiCall.run(user.userID, s.id);
    if (deleteError) {
      console.error(deleteError);
      return false;
    }
    return true;
  };

  const handleUpdateClick = async () => {
    if (!user) return;

    // validate schedule
    const errors = validateSchedule(defaultWeekSchedule);
    if (errors?.some((e) => e.errors.length > 0)) {
      setScheduleErrors(errors);
      return;
    }

    // delete any schedules that were removed
    const deletedWeekSchedules = defaultWeekSchedule.filter((s) => s.deleted);
    const undeletedWeekSchedules = defaultWeekSchedule.filter(
      (s) => !s.deleted
    );
    if (deletedWeekSchedules.length > 0) {
      const deleteResult = await Promise.all(
        deletedWeekSchedules.map(async (s) => deleteSchedule(s))
      );
      if (deleteResult.some((r) => !r)) {
        // something went wrong, abort mission
        return;
      }
    }

    // update the user and schedule
    const [, updateUserError] = await updateUserCall.run(
      UpdateDefaultScheduleRequest.fromJS({
        user: userFormToUserModel(state, user),
        schedules: cleanWeekSchedule(undeletedWeekSchedules),
      })
    );

    if (!updateUserError) {
      defaultWeekScheduleResponse.update();
      onSaved?.();
    }
  };

  const handleCancelClick = () => {
    // return any deleted schedules to the active list
    setDefaultWeekSchedule((prev) => [
      ...prev.map(
        (s) =>
          ({
            ...s,
            deleted: false,
          }) as DeletableDefaultScheduleDto
      ),
    ]);
    onCancel?.();
  };

  const toggleDeleteSchedule = async (
    scheduleIndex: number,
    deleted: boolean
  ) => {
    if (!user?.userID) throw new Error('Unable to find userID');
    setDefaultWeekSchedule((prev) => [
      ...prev.map(
        (s, i) =>
          ({
            ...s,
            deleted: i === scheduleIndex ? deleted : s.deleted, // toggle the deleted flag for the schedule index
          }) as DeletableDefaultScheduleDto
      ),
    ]);
  };

  const handleSetWeek = useCallback(
    (newWeek: DefaultScheduleDto, index: number) => {
      const scheduleToValidate = defaultWeekSchedule.reduce((acc, cur, i) => {
        if (i === index) {
          return [...acc, newWeek];
        }

        return [...acc, cur];
      }, [] as DefaultScheduleDto[]);

      if (scheduleErrors?.some((e) => e.errors.length > 0)) {
        const errors = validateSchedule(scheduleToValidate);

        setScheduleErrors(errors ?? []);
      }
      setDefaultWeekSchedule(scheduleToValidate);
    },
    [defaultWeekSchedule, scheduleErrors]
  );

  useEffect(() => {
    if (defaultWeekSchedule.length !== 0) {
      setSelectedScheduleIndex(defaultWeekSchedule.length - 1); // select the last schedule
    }
  }, [defaultWeekSchedule.length]);

  const saveStatus = createMode ? createCall.status : updateUserCall.status;
  const renderContent = () => {
    switch (saveStatus) {
      case RequestStatus.Idle:
      case RequestStatus.Fetched:
      case RequestStatus.Error:
        return (
          <>
            <GlobalFormWrap>
              <FormWrap maxWidth={270}>
                {mkInput(UserFormField.name, 'Namn')}
                {mkInput(UserFormField.employmentID, 'Anställnings-ID')}
                {mkInput(UserFormField.greenPlate, 'Grönskylt')}
                {mkDateInput(
                  UserFormField.dateOfBirth,
                  'Födelsedatum',
                  'ÅÅÅÅ-MM-DD'
                )}
                {mkInput(UserFormField.email, 'E-post')}
                {mkInput(UserFormField.phoneNumber, 'Telefon')}
                <HorizontalWrap>
                  {mkInput(UserFormField.postalCode, 'Postnr', undefined, 76)}
                  {mkInput(UserFormField.city, 'Ort')}
                </HorizontalWrap>
                <HorizontalWrap>
                  Område: {zipCodeLocalArea?.name ?? ''}
                </HorizontalWrap>
                <MyLabelWrap label="Enhet">
                  <Select
                    disabled={!canEdit}
                    onChange={(event) => {
                      onChange(
                        UserFormField.businessUnitID,
                        event.currentTarget.value
                      );
                    }}
                    value={state[UserFormField.businessUnitID]}
                  >
                    {businessUnitOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value ?? undefined}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </MyLabelWrap>
                <MyLabelWrap label="Avdelning">
                  <Select
                    disabled={!canEdit}
                    onChange={(event) => {
                      onChange(
                        UserFormField.companyDepartmentID,
                        event.currentTarget.value
                      );
                    }}
                    value={state[UserFormField.companyDepartmentID]}
                  >
                    {companyDepartmentOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value ?? undefined}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </MyLabelWrap>
                <MyLabelWrap label="Roller">
                  <MultiSelect
                    disabled={!canEdit}
                    onChange={(v) =>
                      onChange(UserFormField.roles, Array.from(v).join(','))
                    }
                    options={roleOptions}
                    value={
                      new Set(
                        state[UserFormField.roles].split(',').filter((v) => v)
                      )
                    }
                  />
                </MyLabelWrap>
                <MyLabelWrap label="Anställningsstatus">
                  <Select
                    disabled={!canEdit}
                    onChange={(event) => {
                      onChange(
                        UserFormField.employmentStatusID,
                        event.currentTarget.value
                      );
                    }}
                    value={state[UserFormField.employmentStatusID]}
                  >
                    {employmentStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </MyLabelWrap>
                <MyLabelWrap label="Grupp ">
                  <Select
                    disabled={!canEdit}
                    onChange={select(UserFormField.teamID)}
                    value={state[UserFormField.teamID]}
                  >
                    {teamOptions}
                  </Select>
                </MyLabelWrap>
                <MyLabelWrap
                  info={{
                    title: 'Automatisk fortkörningsavstämning',
                    content: (
                      <div>
                        <Paragraph>
                          Hur ofta (i snitt) som en förares körningar
                          automatiskt kollas för fortkörningar.
                        </Paragraph>
                        <Paragraph>
                          Det går annars att göra detta manuellt från
                          uppdragsvyn när uppdraget är slutfört.
                        </Paragraph>
                        <Paragraph>
                          <strong>
                            Obs! Inställningen sätts automatiskt till 20% efter
                            10 fläckfria körningar
                          </strong>
                        </Paragraph>
                      </div>
                    ),
                  }}
                  label="Automatisk fortkörningsavstämning "
                >
                  <Select
                    disabled={!canEdit}
                    onChange={select(UserFormField.autoFetchSpeedLimitRate)}
                    value={state[UserFormField.autoFetchSpeedLimitRate]}
                  >
                    {speedCheckOptions}
                  </Select>
                </MyLabelWrap>
                {mkCheckbox(
                  UserFormField.excludeFromInvoicing,
                  'Exkludera från fakturaunderlag'
                )}
                {mkCheckbox(
                  UserFormField.excludeFromSalaryReport,
                  'Exkludera från lönerapport'
                )}
              </FormWrap>
              <FormWrap compact>
                {mkCheckbox(UserFormField.isActive, 'Aktiv')}
                {mkCheckbox(
                  UserFormField.isAvailableForDriving,
                  'Tillgänglig för bokning'
                )}
                {mkCheckbox(
                  UserFormField.autoApproveAssignment,
                  'Godkänn uppdrag automatiskt'
                )}
                {mkCheckbox(
                  UserFormField.showInInvoicing,
                  'Visa i fakturaunderlag'
                )}
                {mkCheckbox(
                  UserFormField.isTruckTransport,
                  'Lastbilstransportör'
                )}
                {userPropsArray.map((key) => {
                  // filterable user fields
                  const field = Number(
                    key
                  ) as keyof typeof filterableUserFields;
                  return mkCheckbox(
                    field,
                    <UserProp>
                      {filterableUserFields[field].icon(true)}
                      {filterableUserFields[field].title}
                    </UserProp>
                  );
                })}
                {me?.roles.includes(Roles.SuperAdmin) &&
                  mkCheckbox(
                    UserFormField.isBetaTester,
                    <UserProp>
                      <FontAwesomeIcon icon={faFlask} /> Beta-testare
                    </UserProp>
                  )}
                {mkCheckbox(
                  UserFormField.shouldSeeTimeReportSectionInApp,
                  <UserProp>
                    <FontAwesomeIcon icon={faCalendarAlt} /> Visa
                    tidrapportering i appen
                  </UserProp>
                )}
                {mkCheckbox(
                  UserFormField.isAllowedToOverrideAssigneeWarnings,
                  <UserProp>
                    <FontAwesomeIcon icon={faWarning} /> Får överrida
                    FT-varningar vid tilldelning
                  </UserProp>,
                  !me?.roles.includes(Roles.SuperAdmin)
                )}
              </FormWrap>

              <DefaultScheduleSection>
                <SectionHeader>Tillgänglighet</SectionHeader>
                <>
                  {/* {mkCheckbox(
                    UserFormField.isAllowedToChangeAvailability,
                    <Paragraph>
                      Kan ändra tillgänglighet själv i appen
                    </Paragraph>,
                    defaultWeekSchedule.every((d) => d.deleted)
                    dölj tills vidare
                  )} */}
                  {defaultWeekSchedule.map((week, index) => (
                    <Fragment key={index}>
                      <ScheduleHeader
                        deleted={week.deleted}
                        onClick={() => {
                          setSelectedScheduleIndex((prev) =>
                            prev === index ? null : index
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            index === selectedScheduleIndex
                              ? faCaretDown
                              : faCaretRight
                          }
                        />
                        <span>{index === 0 ? 'Basschema' : 'Extraschema'}</span>
                        {week.deleted ? (
                          <MyTextButton
                            disabled={!canEdit}
                            inverted
                            onClick={() => {
                              if (
                                !defaultWeekSchedule[index].isBaseSchedule &&
                                defaultWeekSchedule.filter(
                                  (s) => s.isBaseSchedule && s.deleted
                                ).length === 1
                              ) {
                                // if this is an extra schedule and the base schedule is also, restore both schedules
                                defaultWeekSchedule.forEach((s, i) => {
                                  toggleDeleteSchedule(i, false);
                                });
                              } else {
                                // otherwise, restore only this one
                                toggleDeleteSchedule(index, false);
                              }
                            }}
                            title="Återställ"
                          >
                            Återställ{' '}
                            <FontAwesomeIcon icon={faTrashRestoreAlt} />
                          </MyTextButton>
                        ) : (
                          <MyTextButton
                            disabled={!canEdit}
                            onClick={() => {
                              if (
                                defaultWeekSchedule.filter((s) => !s.deleted)
                                  .length > 1 &&
                                index === 0
                              ) {
                                modalStack.push(
                                  <Modal
                                    buttons={[
                                      {
                                        label: 'Ok',
                                        onClick: () => {
                                          modalStack.pop();
                                        },
                                      },
                                    ]}
                                  >
                                    Du måste ta bort det andra schemat innan du
                                    kan ta bort basschemat.
                                  </Modal>
                                );
                              } else {
                                toggleDeleteSchedule(index, true);
                              }
                            }}
                            title="Ta bort"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </MyTextButton>
                        )}
                      </ScheduleHeader>
                      {defaultWeekSchedule.length === 0 ||
                        (selectedScheduleIndex === index && (
                          <WeeklySchedule
                            canEdit={canEdit}
                            errors={scheduleErrors[index]}
                            index={index}
                            key={week.id}
                            setWeek={(newWeek: DefaultScheduleDto) =>
                              handleSetWeek(newWeek, index)
                            }
                            week={week}
                          />
                        ))}
                    </Fragment>
                  ))}

                  <ButtonRow>
                    {defaultWeekSchedule.length < 2 && (
                      <Button
                        onClick={() => {
                          // if there is a base schedule that is not deleted, add the new schedule as an extra schedule (isBaseSchedule = false)
                          // if there is a deleted base schedule, prompt the user to restore it first
                          // if there is no base schedule, create a new base schedule (isBaseSchedule = true)
                          const baseSchedule = defaultWeekSchedule.find(
                            (s) => s.isBaseSchedule && !s.deleted
                          );
                          if (baseSchedule) {
                            setDefaultWeekSchedule((prev) => [
                              ...prev,
                              getEmptyDefaultScheduleDto(false),
                            ]);
                          } else if (
                            defaultWeekSchedule.find(
                              (s) => s.isBaseSchedule && s.deleted
                            )
                          ) {
                            modalStack.push(
                              <Modal
                                buttons={[
                                  {
                                    label: 'Ok',
                                    onClick: () => {
                                      modalStack.pop();
                                    },
                                  },
                                ]}
                              >
                                Du måste återställa det befintliga basschemat
                                innan du kan lägga till ett nytt.
                              </Modal>
                            );
                          } else {
                            setDefaultWeekSchedule((prev) => [
                              ...prev,
                              getEmptyDefaultScheduleDto(true),
                            ]);
                          }
                        }}
                      >
                        {`+ Lägg till ${
                          defaultWeekSchedule.filter((s) => s.isBaseSchedule)
                            .length > 0
                            ? 'extra'
                            : 'bas'
                        }schema`}
                      </Button>
                    )}
                  </ButtonRow>
                </>
              </DefaultScheduleSection>
            </GlobalFormWrap>
            {expenseUtils.allowCircleKSensitiveInfo(me) && user && (
              <SubFormWrap>
                <UserPaymentCardForm user={user} />
              </SubFormWrap>
            )}
            <SubFormWrap>
              <FormWrap>
                {mkTextArea(UserFormField.otherInfo, 'Anteckningar')}
              </FormWrap>
            </SubFormWrap>
          </>
        );

      case RequestStatus.Fetching:
      default:
        return <LoadingSpinner>Sparar...</LoadingSpinner>;
    }
  };

  return (
    <Modal
      buttons={
        saveStatus === RequestStatus.Fetching
          ? []
          : [
              ...arraySpreadIf(!!canEdit, {
                label: createMode ? 'Skapa användare' : 'Uppdatera användare',
                onClick: createMode ? handleCreateClick : handleUpdateClick,
              }),
              {
                label: canEdit ? 'Avbryt' : 'Stäng',
                onClick: handleCancelClick,
              },
            ]
      }
      onClose={handleCancelClick}
      title={
        title !== undefined
          ? title
          : createMode
            ? 'Skapa användare'
            : 'Redigera användare'
      }
    >
      {renderContent()}
    </Modal>
  );
};

export default UserFormModal;
