import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import {
  AbsenceStatus,
  AvailabilityDto,
  ITranslationLookups,
  UpdateUserAvailabilityRequest,
} from 'api';
import useTranslations from 'contexts/basicData/useTranslations';
import useUsers from 'contexts/basicData/useUsers';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Checkbox from 'components/inputs/Checkbox';
import styled from 'styled-components';
import {
  useUserGetUnHandledAbsenceRequests,
  useUserUpdateUserAvailability,
} from 'api/user/user';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import useModalStack from 'contexts/modal/useModalStack';
import AvailabilityFormModal from '../Availability/AvailabilityFormModal';
import AbsenceReasonIcon from '../Availability/AbsenceReasonIcon';
import { formatDateTime } from 'utils/date-helpers';
import { ColumnSetting } from 'components/Table/utils';
import Table from 'components/Table';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const FullSpan = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  white-space: break-spaces;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

type ItemInNeedOfHandling = {
  selected: boolean;
  isUpdating?: boolean;
  availability: AvailabilityDto;
};

const AbsenceRequests = () => {
  const [isBatchUpdating, setIsBatchUpdating] = useState<boolean>(false);
  const [items, setItems] = useState<ItemInNeedOfHandling[]>([]);
  const [search, setSearch] = useState<string>('');

  const { absenceReasons } = useTranslations();
  const modalStack = useModalStack();
  const users = useUsers();
  const me = useMe();

  const {
    data: unhandledAbsenceRequests,
    mutate: mutateUnhandledAbsenceRequests,
    status: unhandledAbsenceRequestsStatus,
  } = useUserGetUnHandledAbsenceRequests();

  const { mutateAsync: updateUserAvailability } =
    useUserUpdateUserAvailability();

  const amountChecked = useMemo(
    () => items.reduce((prev, curr) => prev + (curr.selected ? 1 : 0), 0),
    [items]
  );

  // matches on name and absenceStatus
  const filteredItems = useMemo(() => {
    return items.filter((i) => {
      if (search === '') {
        return true;
      }

      return (
        users[i.availability?.userID ?? -1]?.name
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        absenceReasons[i.availability?.absenceReason ?? -1]
          ?.toLowerCase()
          .includes(search.toLowerCase())
      );
    });
  }, [items, search, users, absenceReasons]);

  const handleBatchUpdate = async (newStatus: AbsenceStatus) => {
    setIsBatchUpdating(true);
    let errors = 0;

    // update each selected and shown availability in sequence, in order for the updates to be visible in the table as they run
    await Promise.all(
      filteredItems
        .filter((a) => a.selected && a.availability)
        .map(async (a) => {
          // set updating = true
          setItems((prev) => {
            return prev.map((a2) => {
              if (
                a2.availability &&
                a.availability &&
                a2.availability?.id === a.availability?.id
              ) {
                return {
                  ...a2,
                  isUpdating: true,
                } as ItemInNeedOfHandling;
              }
              return a2;
            });
          });

          await updateUserAvailability(
            {
              data: {
                availability: { ...a.availability, absenceStatus: newStatus },
                userId: a.availability?.userID,
              } as UpdateUserAvailabilityRequest,
            },
            {
              onSuccess() {
                setItems((prev) => {
                  return prev.map((a2) => {
                    if (a2.availability?.id === a.availability?.id) {
                      return {
                        ...a2,
                        absenceStatus: newStatus,
                        isUpdating: false,
                      } as ItemInNeedOfHandling;
                    }
                    return a2;
                  });
                });
              },
              onError() {
                errors += 1;
              },
            }
          );
        })
    );

    setIsBatchUpdating(false);
    mutateUnhandledAbsenceRequests();
    if (errors === 0) {
      // reset the now empty search
      setSearch('');
    }
  };

  const handleRowClick = useCallback(
    (a: ItemInNeedOfHandling) => {
      if (me?.roles.includes(Roles.Admin)) {
        modalStack.push(
          <AvailabilityFormModal
            availability={a.availability}
            name={users[a.availability.userID]?.name}
            onClose={() => modalStack.pop()}
            onSaved={() => {
              modalStack.pop();
              mutateUnhandledAbsenceRequests();
            }}
            showUserFields={false}
            userId={a.availability.userID}
          />
        );
      }
    },
    [me?.roles, modalStack, mutateUnhandledAbsenceRequests, users]
  );

  const columnSettings: ColumnSetting<
    ItemInNeedOfHandling,
    ITranslationLookups
  >[] = useMemo(() => {
    const columns: ColumnSetting<ItemInNeedOfHandling, ITranslationLookups>[] =
      [
        {
          head: (
            <Checkbox
              checked={
                amountChecked === items.filter((i) => !!i.availability).length
              }
              onChange={(e) => {
                // update all
                const checked = !!e.currentTarget?.checked;
                setItems((prev) => {
                  return prev.map((a) => {
                    return {
                      ...a,
                      selected: checked,
                    } as ItemInNeedOfHandling;
                  });
                });
              }}
            />
          ),
          render: (item) =>
            item.availability ? (
              <Checkbox
                checked={item.selected}
                onChange={(e) => {
                  const checked = !!e.currentTarget?.checked;

                  const updatedItems = items.map((a) => {
                    const isChangedItem =
                      a.availability?.id === item.availability?.id;
                    if (isChangedItem) {
                      const updatedItem = {
                        ...a,
                        selected: checked,
                      } as ItemInNeedOfHandling;
                      return updatedItem;
                    }

                    return {
                      ...a,
                    } as ItemInNeedOfHandling;
                  });
                  setItems(updatedItems);
                }}
              />
            ) : null,
          width: 40,
        },
        {
          head: 'Namn',
          render: (item) => (
            <FullSpan onClick={() => handleRowClick(item)}>
              {users[item.availability?.userID ?? -1]?.name ?? ''}
            </FullSpan>
          ),
          width: 150,
          sortFunction: (a, b) => {
            const nameA = users[a.availability?.userID ?? -1]?.name ?? '';
            const nameB = users[b.availability?.userID ?? -1]?.name ?? '';
            return nameA.localeCompare(nameB);
          },
        },
        {
          head: 'Datum',
          render: (item) => (
            <FullSpan onClick={() => handleRowClick(item)}>
              {item.availability.dateString}
            </FullSpan>
          ),
          width: 120,
          sortFunction: (a, b) =>
            a.availability?.dateString?.localeCompare(
              b.availability?.dateString ?? ''
            ) ?? 0,
        },
        {
          head: 'Anledning',
          render: (item) => (
            <Horizontal onClick={() => handleRowClick(item)}>
              <AbsenceReasonIcon
                absenceReason={item.availability?.absenceReason}
              />{' '}
              <FullSpan>
                {absenceReasons[item.availability?.absenceReason ?? -1]}
              </FullSpan>
            </Horizontal>
          ),
          width: 160,
          sortFunction: (a, b) =>
            `${a.availability.absenceReason}`.localeCompare(
              `${b.availability.absenceReason}`
            ),
        },
        {
          head: 'Kommentar',
          render: (item) => (
            <FullSpan onClick={() => handleRowClick(item)}>
              {item.availability?.comment}
            </FullSpan>
          ),
          width: 500,
        },
        {
          head: 'Bokade uppdrag',
          render: (item) => (
            <FullSpan onClick={() => handleRowClick(item)}>
              {item.availability?.bookedAssignments || 0}
            </FullSpan>
          ),
          width: 120,
        },
        {
          head: 'Inkommet',
          render: (item) => (
            <FullSpan onClick={() => handleRowClick(item)}>
              {item.availability?.createdAt
                ? formatDateTime(new Date(item.availability.createdAt))
                : ''}
            </FullSpan>
          ),
          width: 160,
          sortFunction: (a, b) =>
            (a.availability?.createdAt.getTime() ?? 0) -
            (b.availability?.createdAt.getTime() ?? 0),
        },
      ];
    return columns;
  }, [absenceReasons, amountChecked, handleRowClick, items, users]);

  useEffect(() => {
    const newData: ItemInNeedOfHandling[] = [];

    if (unhandledAbsenceRequests) {
      unhandledAbsenceRequests.map((uar) =>
        newData.push({
          selected: false,
          isUpdating: false,
          availability: uar,
        } as ItemInNeedOfHandling)
      );
    }

    newData
      .sort((a, b) => {
        if (b.availability?.dateString && b.availability.dateString) {
          if (a.availability?.dateString && a.availability.dateString) {
            return (
              new Date(Date.parse(b.availability.dateString)).getTime() -
              new Date(Date.parse(a.availability.dateString)).getTime()
            );
          }

          return 0;
        }

        return 0;
      })
      .reverse();

    setItems(newData);
  }, [unhandledAbsenceRequests]);

  useEffect(() => {
    mutateUnhandledAbsenceRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Wrapper>
        <Actions>
          <Button
            disabled={
              isBatchUpdating ||
              unhandledAbsenceRequestsStatus === 'loading' ||
              amountChecked === 0
            }
            icon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => {
              handleBatchUpdate(AbsenceStatus.DECLINED);
            }}
          >
            Avslå
          </Button>
          <Button
            disabled={
              isBatchUpdating ||
              unhandledAbsenceRequestsStatus === 'loading' ||
              amountChecked === 0
            }
            icon={<FontAwesomeIcon icon={faCheck} />}
            onClick={() => {
              handleBatchUpdate(AbsenceStatus.APPROVED);
            }}
          >
            Bevilja
          </Button>
          <Input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Filtrera på namn eller anledning"
            value={search}
            width={230}
          />
        </Actions>
        {isBatchUpdating && <LoadingSpinner>Laddar...</LoadingSpinner>}

        {unhandledAbsenceRequests ? (
          <Table
            columnSettings={columnSettings}
            // onRowClick={handleRowClick}
            rows={items}
          />
        ) : (
          <LoadingSpinner />
        )}
      </Wrapper>
    </Suspense>
  );
};

export default AbsenceRequests;
