import {
  DetailedStandardizedCommentDto,
  ExpenseType,
  StandardizedCommentDto,
} from 'api';

type UpdatedExpectedExpenseProps = {
  existingComments: StandardizedCommentDto[];
  existingExpectedExpenses: ExpenseType[];
  globalStandardizedComments: DetailedStandardizedCommentDto[];
  newComments: StandardizedCommentDto[];
};

export const getUpdatedExpectedExpensesList = ({
  existingComments,
  existingExpectedExpenses,
  globalStandardizedComments,
  newComments,
}: UpdatedExpectedExpenseProps): ExpenseType[] => {
  const existingPredefinedCommentWithExpenseTypeData = existingComments.reduce(
    (acc, cur) => {
      // find in global comments
      const globalComment = globalStandardizedComments.find(
        (gc) => gc.comment === cur.comment
      );

      if (globalComment) {
        return [...acc, globalComment];
      }
      // merge with local comment
      return [...acc];
    },
    [] as DetailedStandardizedCommentDto[]
  );

  const removedCommentsWithLinkedExpenseType =
    existingPredefinedCommentWithExpenseTypeData.filter(
      (c) => !newComments?.find((nc) => nc.comment === c.comment)
    );

  const addedCommentsWithLinkedExpenseType = newComments
    ?.filter(
      (nc) =>
        !existingPredefinedCommentWithExpenseTypeData.find(
          (c) => c.comment === nc.comment
        )
    )
    .map((c) => ({
      ...c,
      linkedExpenseType: globalStandardizedComments.find(
        (gc) => gc.comment === c.comment
      )?.linkedExpenseType,
    }))
    .filter(
      (c) => c.linkedExpenseType !== undefined && c.linkedExpenseType !== null
    );

  // update the expected expenses based on the added / removed above

  const newExpectedExpenses = existingExpectedExpenses.filter(
    (ee) =>
      !removedCommentsWithLinkedExpenseType.find(
        (c) => c.linkedExpenseType === ee
      )
  );
  const finalExpectedExpenses = [
    ...newExpectedExpenses,
    ...(addedCommentsWithLinkedExpenseType?.map((c) => c.linkedExpenseType) ??
      []),
  ];

  // remove duplicates
  const uniqueExpectedExpenses = Array.from(new Set(finalExpectedExpenses));

  console.log({
    existingComments,
    existingExpectedExpenses,
    globalStandardizedComments,
    newComments,
    existingPredefinedCommentWithExpenseTypeData,
    removedCommentsWithLinkedExpenseType,
    addedCommentsWithLinkedExpenseType,
    newExpectedExpenses,
    finalExpectedExpenses,
    uniqueExpectedExpenses,
  });

  return uniqueExpectedExpenses as ExpenseType[];
};
export default {
  getUpdatedExpectedExpensesList,
};
