import Table from 'components/Table';
import styled from 'styled-components';
import useTranslations from 'contexts/basicData/useTranslations';
import DateSpanPicker from 'pages/ExcelExports/DateSpanPicker';
import Button from 'components/inputs/Button';
import Input, { inputStyle } from 'components/inputs/Input';
import { ITranslationLookups, TimeReportModel } from 'api';
import useModalStack from 'contexts/modal/useModalStack';
import TimeReportModal from './TimeReportModal';
import { useEffect, useMemo, useState } from 'react';
import useUsers from 'contexts/basicData/useUsers';
import {
  getTimeReportGetTimeReportsQueryKey,
  useTimeReportGetTimeReports,
  useTimeReportUpdateTimeReport,
} from 'api/time-report/time-report';
import { ColumnSetting } from 'components/Table/utils';
import Checkbox from 'components/inputs/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { formatDateTime } from 'utils/date-helpers';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';
import { CompanyDepartmentModel, TimeReportStatus } from 'api/model';
import { useLocalStorage } from 'usehooks-ts';
import {
  makeDateComparator,
  makeNumberComparator,
  makeStringComparator,
} from 'utils/sorting';
import { useCompanyDepartmentGetCompanyDepartments } from 'api/company-department/company-department';
import { StyledMultiSelect } from 'pages/InternalDeliveryGroup/components/filterBars';
import LabelWrap from 'components/inputs/LabelWrap';

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 50px;
  padding: 10px;
  gap: 10px;
`;

const LeftSideActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
`;

const MyButton = styled(Button)`
  ${inputStyle}
  background-color: ${(props) => props.theme.colors.background.button};
  color: ${(props) => props.theme.colors.foreground.button};
  height: 30px;
  border-style: none;
`;

const firstDayOfThisMonth = new Date();
firstDayOfThisMonth.setDate(1);
firstDayOfThisMonth.setHours(0, 0, 0, 0);

const lastDayOfThisMonth = new Date();
lastDayOfThisMonth.setDate(0);
lastDayOfThisMonth.setMonth(lastDayOfThisMonth.getMonth() + 1);
lastDayOfThisMonth.setHours(23, 59, 59, 999);

const TimeReports = () => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
  const [dateParams, setDateParams] = useState<{ from: Date; to: Date }>({
    from: firstDayOfThisMonth,
    to: lastDayOfThisMonth,
  });
  const [selectableTimeReports, setSelectableTimeReports] = useState<
    SelectableTimeReport[]
  >([]);
  const [showOnlyUnhandled, setShowOnlyUnhandled] = useLocalStorage<boolean>(
    'showOnlyUnhandledTimeReports',
    false
  );
  const translations = useTranslations();
  const modalStack = useModalStack();
  const users = useUsers();

  const getCompanyDepartmentsCall = useCompanyDepartmentGetCompanyDepartments();
  const companyDepartments = useMemo((): Record<
    number,
    CompanyDepartmentModel
  > => {
    const departments = getCompanyDepartmentsCall.data;
    if (!departments) {
      return {};
    }
    return Object.fromEntries(departments.map((d) => [d.id, d]));
  }, [getCompanyDepartmentsCall.data]);
  const companyDepartmentOptions = useMemo(() => {
    return (
      getCompanyDepartmentsCall.data?.map((d) => ({
        label: d.name,
        value: d.id,
      })) ?? []
    );
  }, [getCompanyDepartmentsCall.data]);
  const timeReportsQuery = useTimeReportGetTimeReports<TimeReportModel[]>({
    from: dateParams.from,
    to: dateParams.to,
  });

  useEffect(() => {
    const updated: SelectableTimeReport[] =
      timeReportsQuery?.data?.map(
        (tr) =>
          ({
            ...tr,
            selected: false,
          }) as SelectableTimeReport
      ) ?? [];
    setSelectableTimeReports(updated);
  }, [timeReportsQuery.data]);

  // matches on name
  const filteredTimeReports: SelectableTimeReport[] = useMemo(() => {
    if (!selectableTimeReports) {
      return [];
    }
    let result = [...selectableTimeReports];
    if (showOnlyUnhandled) {
      result = result?.filter(
        (tr) => tr.reportStatusID === TimeReportStatus.Created
      );
    }
    if (selectedDepartments.length > 0) {
      result = result?.filter((tr) =>
        selectedDepartments.includes(tr.companyDepartmentID ?? -1)
      );
    }
    if (searchInput === '') {
      return result;
    }
    return result?.filter((tr) =>
      users[tr.userID ?? -1]?.name
        .toLowerCase()
        .includes(searchInput.toLowerCase())
    );
  }, [
    selectableTimeReports,
    showOnlyUnhandled,
    selectedDepartments,
    searchInput,
    users,
  ]);

  const queryClient = useQueryClient();
  const updateReport = useTimeReportUpdateTimeReport({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          getTimeReportGetTimeReportsQueryKey()
        );
        timeReportsQuery.refetch();
      },
    },
  });

  const handleBulkApprove = async (reports: SelectableTimeReport[]) => {
    if (reports.length === 0) {
      return;
    }
    await Promise.all(
      reports.map((tr) => {
        const updatedReport = {
          ...tr,
          reportStatusID: TimeReportStatus.Approved,
        } as unknown as TimeReportModel;

        return updateReport.mutateAsync({ data: updatedReport });
      })
    );
  };

  const handleBulkReject = async (reports: SelectableTimeReport[]) => {
    if (reports.length === 0) {
      return;
    }
    await Promise.all(
      reports.map((tr) => {
        const updatedReport = {
          ...tr,
          reportStatusID: TimeReportStatus.Declined,
        } as unknown as TimeReportModel;

        return updateReport.mutateAsync({ data: updatedReport });
      })
    );
  };

  interface SelectableTimeReport extends TimeReportModel {
    selected: boolean;
  }

  const timeReportColumns = useMemo((): ColumnSetting<
    SelectableTimeReport,
    ITranslationLookups
  >[] => {
    return [
      {
        head: 'Nr',
        render: (timeReport, props, focused, index) => index + 1,
        width: 15,
      },
      {
        focusable: true,
        head: (
          <Checkbox
            onChange={(e) => {
              if (!e.currentTarget.checked) {
                setSelectableTimeReports(
                  selectableTimeReports.map(
                    (tr) =>
                      ({
                        ...tr,
                        selected: false,
                      }) as SelectableTimeReport
                  )
                );
              } else {
                const updated = selectableTimeReports.map((tr) => {
                  // only select visible reports
                  if (filteredTimeReports.includes(tr)) {
                    return {
                      ...tr,
                      selected: true,
                    } as SelectableTimeReport;
                  }
                  return tr;
                });
                setSelectableTimeReports(updated);
              }
            }}
          />
        ),
        render: (timeReport) => (
          <Checkbox
            checked={timeReport.selected}
            onChange={(e) => {
              const updated = selectableTimeReports.map((tr) => {
                if (tr.id === timeReport.id) {
                  return {
                    ...tr,
                    selected: e.currentTarget.checked,
                  } as SelectableTimeReport;
                }

                return tr;
              });
              setSelectableTimeReports(updated);
            }}
          />
        ),
        width: 15,
      },
      {
        head: 'Anställd',
        render: (timeReport) =>
          users[timeReport.userID]?.name ?? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ),
        width: 250,
        sortFunction: (a, b) =>
          (users[a.userID]?.name ?? '').localeCompare(
            users[b.userID]?.name ?? ''
          ),
      },
      {
        head: 'Avdelning',
        render: (timeReport) =>
          timeReport.companyDepartmentID
            ? companyDepartments[timeReport.companyDepartmentID].name
            : '',
        width: 150,
        sortFunction: makeStringComparator((tr) => tr.title ?? ''),
      },
      {
        head: 'Benämning',
        render: (timeReport) => timeReport.title,
        width: 150,
        sortFunction: makeStringComparator((tr) => tr.title ?? ''),
      },
      {
        head: 'Starttid',
        render: (timeReport) =>
          timeReport.startTime ? formatDateTime(timeReport.startTime) : '',
        width: 150,
        sortFunction: makeDateComparator((tr) => tr.startTime ?? new Date()),
      },
      {
        head: 'Sluttid',
        render: (timeReport) =>
          timeReport.endTime ? formatDateTime(timeReport.endTime) : '',
        width: 150,
        sortFunction: makeDateComparator((tr) => tr.endTime ?? new Date()),
      },
      {
        head: 'Rast (minuter)',
        render: (timeReport) => timeReport.breakTime ?? 0,
        width: 80,
        sortFunction: makeNumberComparator((tr) => tr.breakTime ?? 0),
      },
      {
        head: 'Arbetade timmar',
        render: (timeReport) => {
          const duration = moment.duration(
            moment(timeReport.endTime).diff(timeReport.startTime)
          );
          let decimalHours = duration.asHours();
          decimalHours -= (timeReport.breakTime ?? 0) / 60;

          return decimalHours ? `${decimalHours.toFixed(2)}h ` : '-';
        },
        width: 130,
        sortFunction: makeNumberComparator((tr) => {
          const duration = moment.duration(
            moment(tr.endTime).diff(tr.startTime)
          );
          const decimalHours = duration.asHours();
          return decimalHours - (tr.breakTime ?? 0) / 60;
        }),
      },
      {
        head: 'Status',
        render: (timeReport, transl) =>
          transl.timeReportStatuses[timeReport.reportStatusID] ?? '',
        width: 160,
        sortFunction: makeStringComparator(
          (tr) => translations.timeReportStatuses[tr.reportStatusID] ?? ''
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, filteredTimeReports]);

  return (
    <>
      <Actions>
        <LeftSideActions>
          <DateSpanPicker
            autoRefetchOnChange
            loading={timeReportsQuery.isLoading}
            initialFrom={dateParams.from}
            initialTo={dateParams.to}
            okButtonLabel="Hämta rapporter"
            buttonWidth={150}
            onDatesSelected={(datespan) => {
              setDateParams(() => ({
                from: datespan.fromDate,
                to: datespan.toDate,
              }));
            }}
            populateHolidays
          />
          <Input
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Filtrera på namn"
            value={searchInput}
            width={250}
          />
          <LabelWrap label="Avdelning">
            <StyledMultiSelect
              onChange={(newVal) => {
                setSelectedDepartments(Array.from(newVal));
              }}
              options={companyDepartmentOptions}
              value={new Set(selectedDepartments)}
            />
          </LabelWrap>

          <MyButton
            label="Registrera ny tid"
            onClick={() => {
              modalStack.push(
                <TimeReportModal
                  onClose={() => modalStack.pop()}
                  timeReport={undefined}
                />
              );
            }}
          >
            + Registrera ny tid
          </MyButton>
          <Checkbox
            onChange={(e) => {
              setShowOnlyUnhandled(e.currentTarget.checked);
            }}
            checked={showOnlyUnhandled}
          >
            Visa endast ohanterade
          </Checkbox>
        </LeftSideActions>
      </Actions>
      <Actions>
        <LeftSideActions>
          <MyButton
            disabled={
              filteredTimeReports.filter((tr) => tr.selected).length === 0
            }
            label="Godkänn"
            onClick={() => {
              handleBulkApprove(
                filteredTimeReports.filter((tr) => tr.selected)
              );
            }}
          >
            Godkänn
          </MyButton>
          <MyButton
            disabled={
              filteredTimeReports.filter((tr) => tr.selected).length === 0
            }
            label="Avslå"
            onClick={() => {
              handleBulkReject(filteredTimeReports.filter((tr) => tr.selected));
            }}
          >
            Avslå
          </MyButton>
        </LeftSideActions>
      </Actions>
      <Table
        columnSettings={timeReportColumns}
        onRowClick={(report) => {
          modalStack.push(
            <TimeReportModal
              onClose={() => modalStack.pop()}
              timeReport={report}
            />
          );
        }}
        renderProps={translations}
        rows={filteredTimeReports}
        useColumnWidthAsFlexAmount
      />
    </>
  );
};
export default TimeReports;
