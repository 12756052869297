import { useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTime } from 'api/assignment/assignment';
import {
  AssignmentDataLookupRecord,
  AssignmentModel,
  AssignmentTypeEnum,
  AssignmentViewModel,
  CaseTypeEnum,
  DrivingPolicyReportDto,
} from 'api/model';
import { DrivingPolicyWarnings } from 'components/TooltipInfoWarning';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useAssignmentWarningsAndMultiplePurchases = (
  currentCaseType: CaseTypeEnum,
  assignment: AssignmentModel,
  isActive: boolean
) => {
  const [multiplePurchasesList, setMultiplePurchasesList] = useState<
    AssignmentViewModel[]
  >([]);
  const assignmentBookedToTimestamp = assignment.bookedTo?.getTime();

  const { mutateAsync: multipleCarPurchasesCall } =
    useAssignmentGetPurchaseAssignmentsForSameAddressAndBookedToTime();

  const updateMultiplePurchasesList = useCallback(async () => {
    console.log('updateMultiplePurchasesList', isActive);

    if (!isActive) {
      return;
    }
    const result = await multipleCarPurchasesCall({
      data: {
        assignmentID: assignment.assignmentID,
        assignedTo: assignment.assignedTo?.userID,
        bookedTo: assignment.bookedTo,
        fromAddress: assignment.fromAddress,
        fromCity: assignment.fromCity,
        fromZip: assignment.fromZip,
      } as AssignmentDataLookupRecord,
    });
    setMultiplePurchasesList(result ?? []);
  }, [isActive, assignment, multipleCarPurchasesCall]);

  useEffect(() => {
    if (
      !(
        currentCaseType === CaseTypeEnum.Purchase &&
        [AssignmentTypeEnum.FurtherTransport, AssignmentTypeEnum.WithTest].some(
          (type) => type === assignment.assignmentTypeID
        )
      )
    ) {
      setMultiplePurchasesList([]); // clear the multiple purchase list for any non-purchase case types
    } else if (assignment.assignmentID !== undefined) {
      updateMultiplePurchasesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCaseType,
    assignment.assignmentID,
    assignmentBookedToTimestamp,
    assignment.assignedTo?.userID,
    assignment.fromAddress,
    assignment.fromCity,
    assignment.fromZip,
  ]);

  const sameTimePurchaseWithEstimatedDuration = useMemo(
    () =>
      multiplePurchasesList.find(
        (m) =>
          // same assigned to
          m.assignment.assignedTo?.userID === assignment.assignedTo?.userID &&
          // same est. duration and start time
          !!m.assignment.estimatedDuration &&
          !!m.assignment.estimatedStartTime
      ),
    [multiplePurchasesList, assignment.assignedTo?.userID]
  );

  const thereAreMultiplePurchasesForAssignedUser = (userId: number) => {
    return (
      multiplePurchasesList.length > 0 &&
      multiplePurchasesList.some(
        (m) =>
          m.assignment.assignedTo?.userID === userId &&
          // has est. duration and start time
          !!m.assignment.estimatedDuration &&
          !!m.assignment.estimatedStartTime
      )
    );
  };

  const isViolatingDrivingPolicy = (
    drivingPolicyData: DrivingPolicyReportDto
  ) => {
    const hasDrivingPolicyWarnings = drivingPolicyData.warnings.some((w) =>
      DrivingPolicyWarnings.includes(w)
    );

    let hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime =
      false;
    const user = drivingPolicyData.fieldTesterId;
    if (user) {
      hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime =
        thereAreMultiplePurchasesForAssignedUser(user);
    }

    const isMultiplePurchase = multiplePurchasesList.length > 0;

    // Visa varnining om det finns körpolicy varningar och inte har flera köp,
    // men inte om fälttestare har syskon i formuläret, troligen alltså andra relaterade uppdrag (ev. "blivande flerbilsinköp"?).
    return (
      hasDrivingPolicyWarnings &&
      !isMultiplePurchase &&
      !hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime
    );
  };

  return {
    multiplePurchasesList,
    updateMultiplePurchasesList,
    sameTimePurchaseWithEstimatedDuration,
    thereAreMultiplePurchasesForAssignedUser,
    isViolatingDrivingPolicy,
  };
};

export default useAssignmentWarningsAndMultiplePurchases;
