/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AssignedTime,
  AvailabilityDto,
  AvailabilityResponse,
  ConsecutiveHoursOfRestResponse,
  DefaultScheduleDto,
  DrivingPolicyReport,
  DrivingPolicyReportDto,
  EmployeeScheduleResponse,
  EmployeeSchedulesRequest,
  GetHoursWorkedRequest,
  GetScheduledAvailabilityHasWeeklyRestRequest,
  SalaryHourData,
  UnhandledCountsDto,
  UpdateDefaultScheduleRequest,
  UpdateUserAvailabilityRequest,
  UserCheckIfOkToAddAssignmentParams,
  UserConfirmUserEmailParams,
  UserDeleteAvailabilityParams,
  UserDeleteEmployeeScheduleParams,
  UserDeleteMyScheduleParams,
  UserGetDefaultEmployeeScheduleParams,
  UserGetDrivingPolicyReportForUnPlannedAssignmentParams,
  UserGetEmployeeAvailabilitiesForDatesParams,
  UserGetEmployeeAvailabilityForDateParams,
  UserGetEmployeeAvailabilityParams,
  UserGetUsers200Five,
  UserGetUsers200Four,
  UserGetUsers200One,
  UserGetUsers200Three,
  UserGetUsers200Two,
  UserModel,
  UserModelBody,
  UserResetUserPasswordParams,
  UserWithDrivingPolicyReport,
} from '.././model';
import useUserGetUsersMutator from '.././mutator/custom-instance';
import useUserGetMeWithDrivingPolicyMutator from '.././mutator/custom-instance';
import useUserRefreshDrivingPolicyStorageMutator from '.././mutator/custom-instance';
import useUserGetDrivingPolicyReportForUnPlannedAssignmentMutator from '.././mutator/custom-instance';
import useUserCheckIfOkToAddAssignmentMutator from '.././mutator/custom-instance';
import useUserGetDrivingPolicyReportMutator from '.././mutator/custom-instance';
import useUserGetMeMutator from '.././mutator/custom-instance';
import useUserCreateUserMutator from '.././mutator/custom-instance';
import useUserUpdateUserAndScheduleMutator from '.././mutator/custom-instance';
import useUserUpdateUserMutator from '.././mutator/custom-instance';
import useUserUpdateMyScheduleMutator from '.././mutator/custom-instance';
import useUserConfirmUserEmailMutator from '.././mutator/custom-instance';
import useUserResetUserPasswordMutator from '.././mutator/custom-instance';
import useUserGetDefaultEmployeeScheduleMutator from '.././mutator/custom-instance';
import useUserGetEmployeeAvailabilitiesMutator from '.././mutator/custom-instance';
import useUserGetEmployeeAvailabilitiesForDatesMutator from '.././mutator/custom-instance';
import useUserGetEmployeeAvailabilityMutator from '.././mutator/custom-instance';
import useUserGetEmployeeAvailabilityForDateMutator from '.././mutator/custom-instance';
import useUserGetUnHandledAbsenceRequestsMutator from '.././mutator/custom-instance';
import useUserGetUnhandledCountMutator from '.././mutator/custom-instance';
import useUserDeleteEmployeeScheduleMutator from '.././mutator/custom-instance';
import useUserDeleteMyScheduleMutator from '.././mutator/custom-instance';
import useUserUpdateUserAvailabilityMutator from '.././mutator/custom-instance';
import useUserUpdateMyAvailabilityMutator from '.././mutator/custom-instance';
import useUserDeleteAvailabilityMutator from '.././mutator/custom-instance';
import useUserGetHoursWorkedMutator from '.././mutator/custom-instance';
import useUserGetAssignedTimesForUserMutator from '.././mutator/custom-instance';
import useUserGetApprovedHoursWorkedThisMonthMutator from '.././mutator/custom-instance';
import useUserGetScheduledAvailabilityHasWeeklyRestMutator from '.././mutator/custom-instance';

export const useUserGetUsersHook = () => {
  const userGetUsers = useUserGetUsersMutator<
    | UserGetUsers200One
    | UserGetUsers200Two
    | UserGetUsers200Three
    | UserGetUsers200Four
    | UserGetUsers200Five
  >();

  return (signal?: AbortSignal) => {
    return userGetUsers({ url: `/api/User/GetUsers`, method: 'GET', signal });
  };
};

export const getUserGetUsersQueryKey = () => {
  return [`/api/User/GetUsers`] as const;
};

export const useUserGetUsersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useUserGetUsersHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetUsersHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetUsersQueryKey();

  const userGetUsers = useUserGetUsersHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useUserGetUsersHook>>>
  > = ({ signal }) => userGetUsers(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetUsersHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetUsersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetUsersHook>>>
>;
export type UserGetUsersQueryError = unknown;

export const useUserGetUsers = <
  TData = Awaited<ReturnType<ReturnType<typeof useUserGetUsersHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetUsersHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUserGetUsersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useUserGetMeWithDrivingPolicyHook = () => {
  const userGetMeWithDrivingPolicy =
    useUserGetMeWithDrivingPolicyMutator<UserWithDrivingPolicyReport>();

  return (signal?: AbortSignal) => {
    return userGetMeWithDrivingPolicy({
      url: `/api/User/GetMeWithDrivingPolicy`,
      method: 'GET',
      signal,
    });
  };
};

export const getUserGetMeWithDrivingPolicyQueryKey = () => {
  return [`/api/User/GetMeWithDrivingPolicy`] as const;
};

export const useUserGetMeWithDrivingPolicyQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetMeWithDrivingPolicyHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetMeWithDrivingPolicyHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetMeWithDrivingPolicyQueryKey();

  const userGetMeWithDrivingPolicy = useUserGetMeWithDrivingPolicyHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useUserGetMeWithDrivingPolicyHook>>>
  > = ({ signal }) => userGetMeWithDrivingPolicy(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetMeWithDrivingPolicyHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetMeWithDrivingPolicyQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetMeWithDrivingPolicyHook>>>
>;
export type UserGetMeWithDrivingPolicyQueryError = unknown;

export const useUserGetMeWithDrivingPolicy = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetMeWithDrivingPolicyHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetMeWithDrivingPolicyHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUserGetMeWithDrivingPolicyQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useUserRefreshDrivingPolicyStorageHook = () => {
  const userRefreshDrivingPolicyStorage =
    useUserRefreshDrivingPolicyStorageMutator<void>();

  return () => {
    return userRefreshDrivingPolicyStorage({
      url: `/api/User/RefreshDrivingPolicyStorage`,
      method: 'POST',
    });
  };
};

export const useUserRefreshDrivingPolicyStorageMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserRefreshDrivingPolicyStorageHook>>
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useUserRefreshDrivingPolicyStorageHook>>
  >,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userRefreshDrivingPolicyStorage =
    useUserRefreshDrivingPolicyStorageHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useUserRefreshDrivingPolicyStorageHook>>
    >,
    void
  > = () => {
    return userRefreshDrivingPolicyStorage();
  };

  return { mutationFn, ...mutationOptions };
};

export type UserRefreshDrivingPolicyStorageMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserRefreshDrivingPolicyStorageHook>>>
>;

export type UserRefreshDrivingPolicyStorageMutationError = unknown;

export const useUserRefreshDrivingPolicyStorage = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserRefreshDrivingPolicyStorageHook>>
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useUserRefreshDrivingPolicyStorageHook>>
  >,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    useUserRefreshDrivingPolicyStorageMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserGetDrivingPolicyReportForUnPlannedAssignmentHook = () => {
  const userGetDrivingPolicyReportForUnPlannedAssignment =
    useUserGetDrivingPolicyReportForUnPlannedAssignmentMutator<DrivingPolicyReportDto>();

  return (
    params?: UserGetDrivingPolicyReportForUnPlannedAssignmentParams,
    signal?: AbortSignal
  ) => {
    return userGetDrivingPolicyReportForUnPlannedAssignment({
      url: `/api/User/GetDrivingPolicyReportForUnPlannedAssignment`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getUserGetDrivingPolicyReportForUnPlannedAssignmentQueryKey = (
  params?: UserGetDrivingPolicyReportForUnPlannedAssignmentParams
) => {
  return [
    `/api/User/GetDrivingPolicyReportForUnPlannedAssignment`,
    ...(params ? [params] : []),
  ] as const;
};

export const useUserGetDrivingPolicyReportForUnPlannedAssignmentQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useUserGetDrivingPolicyReportForUnPlannedAssignmentHook>
    >
  >,
  TError = unknown,
>(
  params?: UserGetDrivingPolicyReportForUnPlannedAssignmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useUserGetDrivingPolicyReportForUnPlannedAssignmentHook
          >
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserGetDrivingPolicyReportForUnPlannedAssignmentQueryKey(params);

  const userGetDrivingPolicyReportForUnPlannedAssignment =
    useUserGetDrivingPolicyReportForUnPlannedAssignmentHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useUserGetDrivingPolicyReportForUnPlannedAssignmentHook
        >
      >
    >
  > = ({ signal }) =>
    userGetDrivingPolicyReportForUnPlannedAssignment(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useUserGetDrivingPolicyReportForUnPlannedAssignmentHook
        >
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetDrivingPolicyReportForUnPlannedAssignmentQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useUserGetDrivingPolicyReportForUnPlannedAssignmentHook
        >
      >
    >
  >;
export type UserGetDrivingPolicyReportForUnPlannedAssignmentQueryError =
  unknown;

export const useUserGetDrivingPolicyReportForUnPlannedAssignment = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useUserGetDrivingPolicyReportForUnPlannedAssignmentHook>
    >
  >,
  TError = unknown,
>(
  params?: UserGetDrivingPolicyReportForUnPlannedAssignmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useUserGetDrivingPolicyReportForUnPlannedAssignmentHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useUserGetDrivingPolicyReportForUnPlannedAssignmentQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useUserCheckIfOkToAddAssignmentHook = () => {
  const userCheckIfOkToAddAssignment =
    useUserCheckIfOkToAddAssignmentMutator<DrivingPolicyReport>();

  return (params?: UserCheckIfOkToAddAssignmentParams) => {
    return userCheckIfOkToAddAssignment({
      url: `/api/User/CheckIfOkToAddAssignment`,
      method: 'POST',
      params,
    });
  };
};

export const useUserCheckIfOkToAddAssignmentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserCheckIfOkToAddAssignmentHook>>>,
    TError,
    { params?: UserCheckIfOkToAddAssignmentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserCheckIfOkToAddAssignmentHook>>>,
  TError,
  { params?: UserCheckIfOkToAddAssignmentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userCheckIfOkToAddAssignment = useUserCheckIfOkToAddAssignmentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserCheckIfOkToAddAssignmentHook>>>,
    { params?: UserCheckIfOkToAddAssignmentParams }
  > = (props) => {
    const { params } = props ?? {};

    return userCheckIfOkToAddAssignment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserCheckIfOkToAddAssignmentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserCheckIfOkToAddAssignmentHook>>>
>;

export type UserCheckIfOkToAddAssignmentMutationError = unknown;

export const useUserCheckIfOkToAddAssignment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserCheckIfOkToAddAssignmentHook>>>,
    TError,
    { params?: UserCheckIfOkToAddAssignmentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserCheckIfOkToAddAssignmentHook>>>,
  TError,
  { params?: UserCheckIfOkToAddAssignmentParams },
  TContext
> => {
  const mutationOptions =
    useUserCheckIfOkToAddAssignmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserGetDrivingPolicyReportHook = () => {
  const userGetDrivingPolicyReport =
    useUserGetDrivingPolicyReportMutator<UserWithDrivingPolicyReport>();

  return (signal?: AbortSignal) => {
    return userGetDrivingPolicyReport({
      url: `/api/User/GetDrivingPolicyReport`,
      method: 'GET',
      signal,
    });
  };
};

export const getUserGetDrivingPolicyReportQueryKey = () => {
  return [`/api/User/GetDrivingPolicyReport`] as const;
};

export const useUserGetDrivingPolicyReportQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetDrivingPolicyReportHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetDrivingPolicyReportHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetDrivingPolicyReportQueryKey();

  const userGetDrivingPolicyReport = useUserGetDrivingPolicyReportHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useUserGetDrivingPolicyReportHook>>>
  > = ({ signal }) => userGetDrivingPolicyReport(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetDrivingPolicyReportHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetDrivingPolicyReportQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetDrivingPolicyReportHook>>>
>;
export type UserGetDrivingPolicyReportQueryError = unknown;

export const useUserGetDrivingPolicyReport = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetDrivingPolicyReportHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetDrivingPolicyReportHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUserGetDrivingPolicyReportQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useUserGetMeHook = () => {
  const userGetMe = useUserGetMeMutator<UserModel>();

  return (signal?: AbortSignal) => {
    return userGetMe({ url: `/api/User/GetMe`, method: 'GET', signal });
  };
};

export const getUserGetMeQueryKey = () => {
  return [`/api/User/GetMe`] as const;
};

export const useUserGetMeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useUserGetMeHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetMeHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetMeQueryKey();

  const userGetMe = useUserGetMeHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useUserGetMeHook>>>
  > = ({ signal }) => userGetMe(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetMeHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetMeQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetMeHook>>>
>;
export type UserGetMeQueryError = unknown;

export const useUserGetMe = <
  TData = Awaited<ReturnType<ReturnType<typeof useUserGetMeHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetMeHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUserGetMeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useUserCreateUserHook = () => {
  const userCreateUser = useUserCreateUserMutator<void>();

  return (userModelBody: UserModelBody) => {
    return userCreateUser({
      url: `/api/User/CreateUser`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userModelBody,
    });
  };
};

export const useUserCreateUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserCreateUserHook>>>,
    TError,
    { data: UserModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserCreateUserHook>>>,
  TError,
  { data: UserModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userCreateUser = useUserCreateUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserCreateUserHook>>>,
    { data: UserModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return userCreateUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserCreateUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserCreateUserHook>>>
>;
export type UserCreateUserMutationBody = UserModelBody;
export type UserCreateUserMutationError = unknown;

export const useUserCreateUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserCreateUserHook>>>,
    TError,
    { data: UserModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserCreateUserHook>>>,
  TError,
  { data: UserModelBody },
  TContext
> => {
  const mutationOptions = useUserCreateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserUpdateUserAndScheduleHook = () => {
  const userUpdateUserAndSchedule = useUserUpdateUserAndScheduleMutator<void>();

  return (updateDefaultScheduleRequest: UpdateDefaultScheduleRequest) => {
    return userUpdateUserAndSchedule({
      url: `/api/User/UpdateUserAndSchedule`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateDefaultScheduleRequest,
    });
  };
};

export const useUserUpdateUserAndScheduleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAndScheduleHook>>>,
    TError,
    { data: UpdateDefaultScheduleRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAndScheduleHook>>>,
  TError,
  { data: UpdateDefaultScheduleRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userUpdateUserAndSchedule = useUserUpdateUserAndScheduleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAndScheduleHook>>>,
    { data: UpdateDefaultScheduleRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userUpdateUserAndSchedule(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserUpdateUserAndScheduleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAndScheduleHook>>>
>;
export type UserUpdateUserAndScheduleMutationBody =
  UpdateDefaultScheduleRequest;
export type UserUpdateUserAndScheduleMutationError = unknown;

export const useUserUpdateUserAndSchedule = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAndScheduleHook>>>,
    TError,
    { data: UpdateDefaultScheduleRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAndScheduleHook>>>,
  TError,
  { data: UpdateDefaultScheduleRequest },
  TContext
> => {
  const mutationOptions = useUserUpdateUserAndScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserUpdateUserHook = () => {
  const userUpdateUser = useUserUpdateUserMutator<void>();

  return (userModelBody: UserModelBody) => {
    return userUpdateUser({
      url: `/api/User/UpdateUser`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: userModelBody,
    });
  };
};

export const useUserUpdateUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateUserHook>>>,
    TError,
    { data: UserModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateUserHook>>>,
  TError,
  { data: UserModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userUpdateUser = useUserUpdateUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateUserHook>>>,
    { data: UserModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return userUpdateUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserUpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateUserHook>>>
>;
export type UserUpdateUserMutationBody = UserModelBody;
export type UserUpdateUserMutationError = unknown;

export const useUserUpdateUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateUserHook>>>,
    TError,
    { data: UserModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateUserHook>>>,
  TError,
  { data: UserModelBody },
  TContext
> => {
  const mutationOptions = useUserUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserUpdateMyScheduleHook = () => {
  const userUpdateMySchedule = useUserUpdateMyScheduleMutator<void>();

  return (defaultScheduleDto: DefaultScheduleDto) => {
    return userUpdateMySchedule({
      url: `/api/User/UpdateMySchedule`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: defaultScheduleDto,
    });
  };
};

export const useUserUpdateMyScheduleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateMyScheduleHook>>>,
    TError,
    { data: DefaultScheduleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateMyScheduleHook>>>,
  TError,
  { data: DefaultScheduleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userUpdateMySchedule = useUserUpdateMyScheduleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateMyScheduleHook>>>,
    { data: DefaultScheduleDto }
  > = (props) => {
    const { data } = props ?? {};

    return userUpdateMySchedule(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserUpdateMyScheduleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateMyScheduleHook>>>
>;
export type UserUpdateMyScheduleMutationBody = DefaultScheduleDto;
export type UserUpdateMyScheduleMutationError = unknown;

export const useUserUpdateMySchedule = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateMyScheduleHook>>>,
    TError,
    { data: DefaultScheduleDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateMyScheduleHook>>>,
  TError,
  { data: DefaultScheduleDto },
  TContext
> => {
  const mutationOptions = useUserUpdateMyScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserConfirmUserEmailHook = () => {
  const userConfirmUserEmail = useUserConfirmUserEmailMutator<void>();

  return (params?: UserConfirmUserEmailParams) => {
    return userConfirmUserEmail({
      url: `/api/User/ConfirmUserEmail`,
      method: 'POST',
      params,
    });
  };
};

export const useUserConfirmUserEmailMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserConfirmUserEmailHook>>>,
    TError,
    { params?: UserConfirmUserEmailParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserConfirmUserEmailHook>>>,
  TError,
  { params?: UserConfirmUserEmailParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userConfirmUserEmail = useUserConfirmUserEmailHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserConfirmUserEmailHook>>>,
    { params?: UserConfirmUserEmailParams }
  > = (props) => {
    const { params } = props ?? {};

    return userConfirmUserEmail(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserConfirmUserEmailMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserConfirmUserEmailHook>>>
>;

export type UserConfirmUserEmailMutationError = unknown;

export const useUserConfirmUserEmail = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserConfirmUserEmailHook>>>,
    TError,
    { params?: UserConfirmUserEmailParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserConfirmUserEmailHook>>>,
  TError,
  { params?: UserConfirmUserEmailParams },
  TContext
> => {
  const mutationOptions = useUserConfirmUserEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserResetUserPasswordHook = () => {
  const userResetUserPassword = useUserResetUserPasswordMutator<void>();

  return (params?: UserResetUserPasswordParams) => {
    return userResetUserPassword({
      url: `/api/User/ResetUserPassword`,
      method: 'POST',
      params,
    });
  };
};

export const useUserResetUserPasswordMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserResetUserPasswordHook>>>,
    TError,
    { params?: UserResetUserPasswordParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserResetUserPasswordHook>>>,
  TError,
  { params?: UserResetUserPasswordParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userResetUserPassword = useUserResetUserPasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserResetUserPasswordHook>>>,
    { params?: UserResetUserPasswordParams }
  > = (props) => {
    const { params } = props ?? {};

    return userResetUserPassword(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserResetUserPasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserResetUserPasswordHook>>>
>;

export type UserResetUserPasswordMutationError = unknown;

export const useUserResetUserPassword = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserResetUserPasswordHook>>>,
    TError,
    { params?: UserResetUserPasswordParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserResetUserPasswordHook>>>,
  TError,
  { params?: UserResetUserPasswordParams },
  TContext
> => {
  const mutationOptions = useUserResetUserPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserGetDefaultEmployeeScheduleHook = () => {
  const userGetDefaultEmployeeSchedule =
    useUserGetDefaultEmployeeScheduleMutator<DefaultScheduleDto[]>();

  return (params?: UserGetDefaultEmployeeScheduleParams) => {
    return userGetDefaultEmployeeSchedule({
      url: `/api/User/GetDefaultEmployeeSchedule`,
      method: 'POST',
      params,
    });
  };
};

export const useUserGetDefaultEmployeeScheduleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetDefaultEmployeeScheduleHook>>
    >,
    TError,
    { params?: UserGetDefaultEmployeeScheduleParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserGetDefaultEmployeeScheduleHook>>>,
  TError,
  { params?: UserGetDefaultEmployeeScheduleParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userGetDefaultEmployeeSchedule =
    useUserGetDefaultEmployeeScheduleHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetDefaultEmployeeScheduleHook>>
    >,
    { params?: UserGetDefaultEmployeeScheduleParams }
  > = (props) => {
    const { params } = props ?? {};

    return userGetDefaultEmployeeSchedule(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserGetDefaultEmployeeScheduleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetDefaultEmployeeScheduleHook>>>
>;

export type UserGetDefaultEmployeeScheduleMutationError = unknown;

export const useUserGetDefaultEmployeeSchedule = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetDefaultEmployeeScheduleHook>>
    >,
    TError,
    { params?: UserGetDefaultEmployeeScheduleParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserGetDefaultEmployeeScheduleHook>>>,
  TError,
  { params?: UserGetDefaultEmployeeScheduleParams },
  TContext
> => {
  const mutationOptions =
    useUserGetDefaultEmployeeScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserGetEmployeeAvailabilitiesHook = () => {
  const userGetEmployeeAvailabilities =
    useUserGetEmployeeAvailabilitiesMutator<EmployeeScheduleResponse[]>();

  return (employeeSchedulesRequest: EmployeeSchedulesRequest) => {
    return userGetEmployeeAvailabilities({
      url: `/api/User/GetEmployeeAvailabilities`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: employeeSchedulesRequest,
    });
  };
};

export const useUserGetEmployeeAvailabilitiesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilitiesHook>>
    >,
    TError,
    { data: EmployeeSchedulesRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilitiesHook>>>,
  TError,
  { data: EmployeeSchedulesRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userGetEmployeeAvailabilities = useUserGetEmployeeAvailabilitiesHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilitiesHook>>
    >,
    { data: EmployeeSchedulesRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userGetEmployeeAvailabilities(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserGetEmployeeAvailabilitiesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilitiesHook>>>
>;
export type UserGetEmployeeAvailabilitiesMutationBody =
  EmployeeSchedulesRequest;
export type UserGetEmployeeAvailabilitiesMutationError = unknown;

export const useUserGetEmployeeAvailabilities = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilitiesHook>>
    >,
    TError,
    { data: EmployeeSchedulesRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilitiesHook>>>,
  TError,
  { data: EmployeeSchedulesRequest },
  TContext
> => {
  const mutationOptions =
    useUserGetEmployeeAvailabilitiesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserGetEmployeeAvailabilitiesForDatesHook = () => {
  const userGetEmployeeAvailabilitiesForDates =
    useUserGetEmployeeAvailabilitiesForDatesMutator<AvailabilityResponse>();

  return (params?: UserGetEmployeeAvailabilitiesForDatesParams) => {
    return userGetEmployeeAvailabilitiesForDates({
      url: `/api/User/GetEmployeeAvailabilitiesForDates`,
      method: 'POST',
      params,
    });
  };
};

export const useUserGetEmployeeAvailabilitiesForDatesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useUserGetEmployeeAvailabilitiesForDatesHook>
      >
    >,
    TError,
    { params?: UserGetEmployeeAvailabilitiesForDatesParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilitiesForDatesHook>>
  >,
  TError,
  { params?: UserGetEmployeeAvailabilitiesForDatesParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userGetEmployeeAvailabilitiesForDates =
    useUserGetEmployeeAvailabilitiesForDatesHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useUserGetEmployeeAvailabilitiesForDatesHook>
      >
    >,
    { params?: UserGetEmployeeAvailabilitiesForDatesParams }
  > = (props) => {
    const { params } = props ?? {};

    return userGetEmployeeAvailabilitiesForDates(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserGetEmployeeAvailabilitiesForDatesMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilitiesForDatesHook>>
  >
>;

export type UserGetEmployeeAvailabilitiesForDatesMutationError = unknown;

export const useUserGetEmployeeAvailabilitiesForDates = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useUserGetEmployeeAvailabilitiesForDatesHook>
      >
    >,
    TError,
    { params?: UserGetEmployeeAvailabilitiesForDatesParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilitiesForDatesHook>>
  >,
  TError,
  { params?: UserGetEmployeeAvailabilitiesForDatesParams },
  TContext
> => {
  const mutationOptions =
    useUserGetEmployeeAvailabilitiesForDatesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserGetEmployeeAvailabilityHook = () => {
  const userGetEmployeeAvailability =
    useUserGetEmployeeAvailabilityMutator<AvailabilityDto[]>();

  return (params?: UserGetEmployeeAvailabilityParams, signal?: AbortSignal) => {
    return userGetEmployeeAvailability({
      url: `/api/User/GetEmployeeAvailability`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getUserGetEmployeeAvailabilityQueryKey = (
  params?: UserGetEmployeeAvailabilityParams
) => {
  return [
    `/api/User/GetEmployeeAvailability`,
    ...(params ? [params] : []),
  ] as const;
};

export const useUserGetEmployeeAvailabilityQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityHook>>
  >,
  TError = unknown,
>(
  params?: UserGetEmployeeAvailabilityParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetEmployeeAvailabilityQueryKey(params);

  const userGetEmployeeAvailability = useUserGetEmployeeAvailabilityHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityHook>>>
  > = ({ signal }) => userGetEmployeeAvailability(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetEmployeeAvailabilityQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityHook>>>
>;
export type UserGetEmployeeAvailabilityQueryError = unknown;

export const useUserGetEmployeeAvailability = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityHook>>
  >,
  TError = unknown,
>(
  params?: UserGetEmployeeAvailabilityParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUserGetEmployeeAvailabilityQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useUserGetEmployeeAvailabilityForDateHook = () => {
  const userGetEmployeeAvailabilityForDate =
    useUserGetEmployeeAvailabilityForDateMutator<AvailabilityDto>();

  return (
    params?: UserGetEmployeeAvailabilityForDateParams,
    signal?: AbortSignal
  ) => {
    return userGetEmployeeAvailabilityForDate({
      url: `/api/User/GetEmployeeAvailabilityForDate`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getUserGetEmployeeAvailabilityForDateQueryKey = (
  params?: UserGetEmployeeAvailabilityForDateParams
) => {
  return [
    `/api/User/GetEmployeeAvailabilityForDate`,
    ...(params ? [params] : []),
  ] as const;
};

export const useUserGetEmployeeAvailabilityForDateQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityForDateHook>>
  >,
  TError = unknown,
>(
  params?: UserGetEmployeeAvailabilityForDateParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityForDateHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserGetEmployeeAvailabilityForDateQueryKey(params);

  const userGetEmployeeAvailabilityForDate =
    useUserGetEmployeeAvailabilityForDateHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityForDateHook>>
    >
  > = ({ signal }) => userGetEmployeeAvailabilityForDate(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityForDateHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetEmployeeAvailabilityForDateQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityForDateHook>>
  >
>;
export type UserGetEmployeeAvailabilityForDateQueryError = unknown;

export const useUserGetEmployeeAvailabilityForDate = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityForDateHook>>
  >,
  TError = unknown,
>(
  params?: UserGetEmployeeAvailabilityForDateParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useUserGetEmployeeAvailabilityForDateHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUserGetEmployeeAvailabilityForDateQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useUserGetUnHandledAbsenceRequestsHook = () => {
  const userGetUnHandledAbsenceRequests =
    useUserGetUnHandledAbsenceRequestsMutator<AvailabilityDto[]>();

  return () => {
    return userGetUnHandledAbsenceRequests({
      url: `/api/User/GetUnHandledAbsenceRequests`,
      method: 'POST',
    });
  };
};

export const useUserGetUnHandledAbsenceRequestsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetUnHandledAbsenceRequestsHook>>
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useUserGetUnHandledAbsenceRequestsHook>>
  >,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userGetUnHandledAbsenceRequests =
    useUserGetUnHandledAbsenceRequestsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetUnHandledAbsenceRequestsHook>>
    >,
    void
  > = () => {
    return userGetUnHandledAbsenceRequests();
  };

  return { mutationFn, ...mutationOptions };
};

export type UserGetUnHandledAbsenceRequestsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetUnHandledAbsenceRequestsHook>>>
>;

export type UserGetUnHandledAbsenceRequestsMutationError = unknown;

export const useUserGetUnHandledAbsenceRequests = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetUnHandledAbsenceRequestsHook>>
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useUserGetUnHandledAbsenceRequestsHook>>
  >,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    useUserGetUnHandledAbsenceRequestsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserGetUnhandledCountHook = () => {
  const userGetUnhandledCount =
    useUserGetUnhandledCountMutator<UnhandledCountsDto>();

  return (signal?: AbortSignal) => {
    return userGetUnhandledCount({
      url: `/api/User/GetUnhandledCount`,
      method: 'GET',
      signal,
    });
  };
};

export const getUserGetUnhandledCountQueryKey = () => {
  return [`/api/User/GetUnhandledCount`] as const;
};

export const useUserGetUnhandledCountQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useUserGetUnhandledCountHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetUnhandledCountHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserGetUnhandledCountQueryKey();

  const userGetUnhandledCount = useUserGetUnhandledCountHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useUserGetUnhandledCountHook>>>
  > = ({ signal }) => userGetUnhandledCount(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetUnhandledCountHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetUnhandledCountQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetUnhandledCountHook>>>
>;
export type UserGetUnhandledCountQueryError = unknown;

export const useUserGetUnhandledCount = <
  TData = Awaited<ReturnType<ReturnType<typeof useUserGetUnhandledCountHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetUnhandledCountHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUserGetUnhandledCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useUserDeleteEmployeeScheduleHook = () => {
  const userDeleteEmployeeSchedule =
    useUserDeleteEmployeeScheduleMutator<boolean>();

  return (params?: UserDeleteEmployeeScheduleParams) => {
    return userDeleteEmployeeSchedule({
      url: `/api/User/DeleteEmployeeSchedule`,
      method: 'DELETE',
      params,
    });
  };
};

export const useUserDeleteEmployeeScheduleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserDeleteEmployeeScheduleHook>>>,
    TError,
    { params?: UserDeleteEmployeeScheduleParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserDeleteEmployeeScheduleHook>>>,
  TError,
  { params?: UserDeleteEmployeeScheduleParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userDeleteEmployeeSchedule = useUserDeleteEmployeeScheduleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserDeleteEmployeeScheduleHook>>>,
    { params?: UserDeleteEmployeeScheduleParams }
  > = (props) => {
    const { params } = props ?? {};

    return userDeleteEmployeeSchedule(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserDeleteEmployeeScheduleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserDeleteEmployeeScheduleHook>>>
>;

export type UserDeleteEmployeeScheduleMutationError = unknown;

export const useUserDeleteEmployeeSchedule = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserDeleteEmployeeScheduleHook>>>,
    TError,
    { params?: UserDeleteEmployeeScheduleParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserDeleteEmployeeScheduleHook>>>,
  TError,
  { params?: UserDeleteEmployeeScheduleParams },
  TContext
> => {
  const mutationOptions = useUserDeleteEmployeeScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserDeleteMyScheduleHook = () => {
  const userDeleteMySchedule = useUserDeleteMyScheduleMutator<boolean>();

  return (params?: UserDeleteMyScheduleParams) => {
    return userDeleteMySchedule({
      url: `/api/User/DeleteMySchedule`,
      method: 'DELETE',
      params,
    });
  };
};

export const useUserDeleteMyScheduleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserDeleteMyScheduleHook>>>,
    TError,
    { params?: UserDeleteMyScheduleParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserDeleteMyScheduleHook>>>,
  TError,
  { params?: UserDeleteMyScheduleParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userDeleteMySchedule = useUserDeleteMyScheduleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserDeleteMyScheduleHook>>>,
    { params?: UserDeleteMyScheduleParams }
  > = (props) => {
    const { params } = props ?? {};

    return userDeleteMySchedule(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserDeleteMyScheduleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserDeleteMyScheduleHook>>>
>;

export type UserDeleteMyScheduleMutationError = unknown;

export const useUserDeleteMySchedule = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserDeleteMyScheduleHook>>>,
    TError,
    { params?: UserDeleteMyScheduleParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserDeleteMyScheduleHook>>>,
  TError,
  { params?: UserDeleteMyScheduleParams },
  TContext
> => {
  const mutationOptions = useUserDeleteMyScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserUpdateUserAvailabilityHook = () => {
  const userUpdateUserAvailability =
    useUserUpdateUserAvailabilityMutator<void>();

  return (updateUserAvailabilityRequest: UpdateUserAvailabilityRequest) => {
    return userUpdateUserAvailability({
      url: `/api/User/UpdateUserAvailability`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: updateUserAvailabilityRequest,
    });
  };
};

export const useUserUpdateUserAvailabilityMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAvailabilityHook>>>,
    TError,
    { data: UpdateUserAvailabilityRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAvailabilityHook>>>,
  TError,
  { data: UpdateUserAvailabilityRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userUpdateUserAvailability = useUserUpdateUserAvailabilityHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAvailabilityHook>>>,
    { data: UpdateUserAvailabilityRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userUpdateUserAvailability(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserUpdateUserAvailabilityMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAvailabilityHook>>>
>;
export type UserUpdateUserAvailabilityMutationBody =
  UpdateUserAvailabilityRequest;
export type UserUpdateUserAvailabilityMutationError = unknown;

export const useUserUpdateUserAvailability = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAvailabilityHook>>>,
    TError,
    { data: UpdateUserAvailabilityRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateUserAvailabilityHook>>>,
  TError,
  { data: UpdateUserAvailabilityRequest },
  TContext
> => {
  const mutationOptions = useUserUpdateUserAvailabilityMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserUpdateMyAvailabilityHook = () => {
  const userUpdateMyAvailability = useUserUpdateMyAvailabilityMutator<void>();

  return (availabilityDto: AvailabilityDto) => {
    return userUpdateMyAvailability({
      url: `/api/User/UpdateMyAvailability`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: availabilityDto,
    });
  };
};

export const useUserUpdateMyAvailabilityMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateMyAvailabilityHook>>>,
    TError,
    { data: AvailabilityDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateMyAvailabilityHook>>>,
  TError,
  { data: AvailabilityDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userUpdateMyAvailability = useUserUpdateMyAvailabilityHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateMyAvailabilityHook>>>,
    { data: AvailabilityDto }
  > = (props) => {
    const { data } = props ?? {};

    return userUpdateMyAvailability(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserUpdateMyAvailabilityMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateMyAvailabilityHook>>>
>;
export type UserUpdateMyAvailabilityMutationBody = AvailabilityDto;
export type UserUpdateMyAvailabilityMutationError = unknown;

export const useUserUpdateMyAvailability = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserUpdateMyAvailabilityHook>>>,
    TError,
    { data: AvailabilityDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserUpdateMyAvailabilityHook>>>,
  TError,
  { data: AvailabilityDto },
  TContext
> => {
  const mutationOptions = useUserUpdateMyAvailabilityMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserDeleteAvailabilityHook = () => {
  const userDeleteAvailability = useUserDeleteAvailabilityMutator<void>();

  return (params?: UserDeleteAvailabilityParams) => {
    return userDeleteAvailability({
      url: `/api/User/DeleteAvailability`,
      method: 'POST',
      params,
    });
  };
};

export const useUserDeleteAvailabilityMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserDeleteAvailabilityHook>>>,
    TError,
    { params?: UserDeleteAvailabilityParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserDeleteAvailabilityHook>>>,
  TError,
  { params?: UserDeleteAvailabilityParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userDeleteAvailability = useUserDeleteAvailabilityHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserDeleteAvailabilityHook>>>,
    { params?: UserDeleteAvailabilityParams }
  > = (props) => {
    const { params } = props ?? {};

    return userDeleteAvailability(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserDeleteAvailabilityMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserDeleteAvailabilityHook>>>
>;

export type UserDeleteAvailabilityMutationError = unknown;

export const useUserDeleteAvailability = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserDeleteAvailabilityHook>>>,
    TError,
    { params?: UserDeleteAvailabilityParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserDeleteAvailabilityHook>>>,
  TError,
  { params?: UserDeleteAvailabilityParams },
  TContext
> => {
  const mutationOptions = useUserDeleteAvailabilityMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserGetHoursWorkedHook = () => {
  const userGetHoursWorked = useUserGetHoursWorkedMutator<number>();

  return (getHoursWorkedRequest: GetHoursWorkedRequest) => {
    return userGetHoursWorked({
      url: `/api/User/GetHoursWorked`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getHoursWorkedRequest,
    });
  };
};

export const useUserGetHoursWorkedMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetHoursWorkedHook>>>,
    TError,
    { data: GetHoursWorkedRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUserGetHoursWorkedHook>>>,
  TError,
  { data: GetHoursWorkedRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userGetHoursWorked = useUserGetHoursWorkedHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUserGetHoursWorkedHook>>>,
    { data: GetHoursWorkedRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userGetHoursWorked(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserGetHoursWorkedMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetHoursWorkedHook>>>
>;
export type UserGetHoursWorkedMutationBody = GetHoursWorkedRequest;
export type UserGetHoursWorkedMutationError = unknown;

export const useUserGetHoursWorked = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetHoursWorkedHook>>>,
    TError,
    { data: GetHoursWorkedRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useUserGetHoursWorkedHook>>>,
  TError,
  { data: GetHoursWorkedRequest },
  TContext
> => {
  const mutationOptions = useUserGetHoursWorkedMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useUserGetAssignedTimesForUserHook = () => {
  const userGetAssignedTimesForUser =
    useUserGetAssignedTimesForUserMutator<AssignedTime[]>();

  return (signal?: AbortSignal) => {
    return userGetAssignedTimesForUser({
      url: `/api/User/GetAssignedTimesForUser`,
      method: 'GET',
      signal,
    });
  };
};

export const getUserGetAssignedTimesForUserQueryKey = () => {
  return [`/api/User/GetAssignedTimesForUser`] as const;
};

export const useUserGetAssignedTimesForUserQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetAssignedTimesForUserHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetAssignedTimesForUserHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetAssignedTimesForUserQueryKey();

  const userGetAssignedTimesForUser = useUserGetAssignedTimesForUserHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useUserGetAssignedTimesForUserHook>>>
  > = ({ signal }) => userGetAssignedTimesForUser(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetAssignedTimesForUserHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetAssignedTimesForUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUserGetAssignedTimesForUserHook>>>
>;
export type UserGetAssignedTimesForUserQueryError = unknown;

export const useUserGetAssignedTimesForUser = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetAssignedTimesForUserHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useUserGetAssignedTimesForUserHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useUserGetAssignedTimesForUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useUserGetApprovedHoursWorkedThisMonthHook = () => {
  const userGetApprovedHoursWorkedThisMonth =
    useUserGetApprovedHoursWorkedThisMonthMutator<SalaryHourData>();

  return (signal?: AbortSignal) => {
    return userGetApprovedHoursWorkedThisMonth({
      url: `/api/User/GetApprovedHoursWorkedThisMonth`,
      method: 'GET',
      signal,
    });
  };
};

export const getUserGetApprovedHoursWorkedThisMonthQueryKey = () => {
  return [`/api/User/GetApprovedHoursWorkedThisMonth`] as const;
};

export const useUserGetApprovedHoursWorkedThisMonthQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetApprovedHoursWorkedThisMonthHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetApprovedHoursWorkedThisMonthHook>>
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserGetApprovedHoursWorkedThisMonthQueryKey();

  const userGetApprovedHoursWorkedThisMonth =
    useUserGetApprovedHoursWorkedThisMonthHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetApprovedHoursWorkedThisMonthHook>>
    >
  > = ({ signal }) => userGetApprovedHoursWorkedThisMonth(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetApprovedHoursWorkedThisMonthHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserGetApprovedHoursWorkedThisMonthQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useUserGetApprovedHoursWorkedThisMonthHook>>
  >
>;
export type UserGetApprovedHoursWorkedThisMonthQueryError = unknown;

export const useUserGetApprovedHoursWorkedThisMonth = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useUserGetApprovedHoursWorkedThisMonthHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useUserGetApprovedHoursWorkedThisMonthHook>>
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useUserGetApprovedHoursWorkedThisMonthQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useUserGetScheduledAvailabilityHasWeeklyRestHook = () => {
  const userGetScheduledAvailabilityHasWeeklyRest =
    useUserGetScheduledAvailabilityHasWeeklyRestMutator<ConsecutiveHoursOfRestResponse>();

  return (
    getScheduledAvailabilityHasWeeklyRestRequest: GetScheduledAvailabilityHasWeeklyRestRequest
  ) => {
    return userGetScheduledAvailabilityHasWeeklyRest({
      url: `/api/User/GetScheduledAvailabilityHasWeeklyRest`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getScheduledAvailabilityHasWeeklyRestRequest,
    });
  };
};

export const useUserGetScheduledAvailabilityHasWeeklyRestMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useUserGetScheduledAvailabilityHasWeeklyRestHook>
      >
    >,
    TError,
    { data: GetScheduledAvailabilityHasWeeklyRestRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useUserGetScheduledAvailabilityHasWeeklyRestHook>
    >
  >,
  TError,
  { data: GetScheduledAvailabilityHasWeeklyRestRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const userGetScheduledAvailabilityHasWeeklyRest =
    useUserGetScheduledAvailabilityHasWeeklyRestHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useUserGetScheduledAvailabilityHasWeeklyRestHook>
      >
    >,
    { data: GetScheduledAvailabilityHasWeeklyRestRequest }
  > = (props) => {
    const { data } = props ?? {};

    return userGetScheduledAvailabilityHasWeeklyRest(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserGetScheduledAvailabilityHasWeeklyRestMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useUserGetScheduledAvailabilityHasWeeklyRestHook>
      >
    >
  >;
export type UserGetScheduledAvailabilityHasWeeklyRestMutationBody =
  GetScheduledAvailabilityHasWeeklyRestRequest;
export type UserGetScheduledAvailabilityHasWeeklyRestMutationError = unknown;

export const useUserGetScheduledAvailabilityHasWeeklyRest = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useUserGetScheduledAvailabilityHasWeeklyRestHook>
      >
    >,
    TError,
    { data: GetScheduledAvailabilityHasWeeklyRestRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useUserGetScheduledAvailabilityHasWeeklyRestHook>
    >
  >,
  TError,
  { data: GetScheduledAvailabilityHasWeeklyRestRequest },
  TContext
> => {
  const mutationOptions =
    useUserGetScheduledAvailabilityHasWeeklyRestMutationOptions(options);

  return useMutation(mutationOptions);
};
