/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-cycle */
import Button from 'components/inputs/Button';
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css, useTheme } from 'styled-components';
import useUsers, { useRefreshUsersCall } from 'contexts/basicData/useUsers';
import TooltipInfo from 'components/TooltipInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleRight,
  faAngleDown,
  faCaretLeft,
  faCaretRight,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import useTranslations from 'contexts/basicData/useTranslations';
import AbsenceReasonIcon from './AbsenceReasonIcon';
import Input from 'components/inputs/Input';
import DiagonalLine from 'components/DiagonalLine';
import MultiSelect, { Option } from 'components/inputs/MultiSelect';
import useBusinessUnits from 'contexts/basicData/useBusinessUnits';
import { useLocalStorage } from 'usehooks-ts';
import useAbsenceRequests from '../useUnhandledCount';
import AvailabilityTable from './AvailabilityTable';
import Checkbox from 'components/inputs/Checkbox';
import useZipCodeAreas from 'hooks/useZipCodeAreas';
import { EmployeeWithCounty } from './types';
import { useAvailabilityRangeOptions } from './useAvailabilityRange';
import ContextMenu from 'components/ContextMenu';
import { useIdle } from 'contexts/IdleContext/useIdle';
import {
  useUserGetEmployeeAvailabilities,
  useUserGetEmployeeAvailabilitiesForDates,
} from 'api/user/user';
import { AvailabilityStatus, EmployeeScheduleResponse } from 'api/model';
import { issueWarning } from './availability-helper';
import { date } from 'yup';
import { getStartAndEndOfWeek } from 'utils/date-helpers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoDot = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.foreground.tint};
  box-shadow: 0px 0px 3px 0px white;
`;

const PaddedDiv = styled.div`
  padding: 2px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;
`;

const DateBoxMeta = styled.div`
  position: absolute;
  flex: 1;
  display: flex;
  z-index: 1;
`;

const Box = styled.div`
  border: 1px solid black;
  border-radius: 3px;
  height: 22px;
  margin: 2px;
  & > div {
    position: relative;
    height: 20px;
    display: flex;
    flex: 1;
  }
`;

const InfoGrid = styled.div<{ columns: (number | string)[] }>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns.join(' ')};
  grid-template-rows: auto;
  gap: 2px;
  justify-content: center;
  align-items: center;
`;

export const Badge = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.foreground.primary};
  background-color: ${({ theme, background }) =>
    background || theme.colors.background.button};
  color: ${({ theme }) => theme.colors.foreground.button};
  text-align: center;
  padding: 1px;
  font-size: 0.7em;
`;

const WeeklyAvailability = styled.div`
  display: flex;
  gap: 10px;

  margin-left: auto;
`;

const WeeklyAvailabilityDay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: default;
  min-width: 40px;
  align-items: center;
`;

const WeekDayBox = styled(Box)<{
  colorCode?: string;
}>`
  border: none;
  background-color: ${({ colorCode }) => colorCode || 'transparent'};
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
`;

const DateBox = styled(Box)<{
  flex: number;
  colorCode?: string;
  isPast?: boolean;
  isUnspecified?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex: ${({ flex }) => flex};
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bold;
  background-color: ${({ colorCode }) => colorCode || 'transparent'};
  color: ${({ theme }) => theme.colors.foreground.primary};

  &:hover {
    box-shadow: 0 0 0 2px inset
      ${({ theme }) => theme.colors.background.selection};
  }
  opacity: ${({ isPast }) => (isPast ? 0.5 : 1)};
`;

const StyledButton = styled(Button)`
  padding: 8px;
  white-space: nowrap;
` as typeof Button;

const StyledMultiSelect = styled(MultiSelect)`
  padding: 6px;
  ${({ value, theme }) =>
    value.size > 0 &&
    css`
      background: ${theme.colors.background.highlight};
    `}
` as typeof MultiSelect;

const StyledInput = styled(Input)`
  height: 100%;
` as typeof Input;

const now = new Date();
now.setHours(0, 0, 0, 0);

const Availability = () => {
  const { isIdle } = useIdle();
  const users = useUsers();
  const {
    rangeOptions,
    currentRange: currentRangeOption,
    setCurrentOption,
  } = useAvailabilityRangeOptions();
  const [dateRange, setDateRange] = useState<[Date, Date] | undefined>(
    currentRangeOption?.getDefaultRange
  );

  const { zipCodeAreaFromZipCode } = useZipCodeAreas();

  const { refetchAbsenceRequests } = useAbsenceRequests();
  const theme = useTheme();
  const { availabilityStatuses, absenceReasons, availabilityStatusColorCodes } =
    useTranslations();
  const { unitsById } = useBusinessUnits();

  const businessUnitOptions = useMemo((): Option<number>[] => {
    return Array.from(unitsById.values()).map((unit) => ({
      label: unit.name,
      value: unit.id,
    }));
  }, [unitsById]);

  const [visibleBusinessUnits, setVisibleBusinessUnits] = useLocalStorage<
    number[]
  >('availability.visibleBUs', []);
  const [showOnlyAvailable, setShowOnlyAvailable] = useLocalStorage<boolean>(
    'availability.showOnlyAvailable',
    false
  );
  useEffect(() => {
    setShowOnlyAvailable(!!currentRangeOption?.showShowOnlyAvailableOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRangeOption]);
  const [search, setSearch] = useState<string>('');

  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const refreshUsersCall = useRefreshUsersCall();

  const employeeAvailabilityCall = useUserGetEmployeeAvailabilities();
  const weeklyAvailabilitiesCall = useUserGetEmployeeAvailabilitiesForDates();
  const weeklyAvailabilitiesCallTimeout = useRef<NodeJS.Timeout | null>(null);

  const currentWeekNumber = useMemo(() => {
    const currentDate = dateRange?.[0] ?? now;
    const onejan = new Date(now.getFullYear(), 0, 1);
    return Math.ceil(
      ((currentDate.getTime() - onejan.getTime()) / 86400000 +
        onejan.getDay() +
        1) /
        7
    );
  }, [dateRange]);

  const weekdays = ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'];

  // Only fetch once
  useEffect(() => {
    const fetchWeeklyAvailabilities = async () => {
      if (weeklyAvailabilitiesCallTimeout.current) {
        clearTimeout(weeklyAvailabilitiesCallTimeout.current);
      }

      weeklyAvailabilitiesCallTimeout.current = setTimeout(() => {
        const [startOfWeek, endOfWeek] = getStartAndEndOfWeek(now);
        weeklyAvailabilitiesCall.mutateAsync({
          params: {
            start: startOfWeek,
            end: endOfWeek,
          },
        });
      }, 500);
    };
    fetchWeeklyAvailabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeekNumber]);

  const [employeeAvailabilityResponse, setEmployeeAvailabilityResponse] =
    useState<EmployeeScheduleResponse[]>([]);

  const fetchAvailaiblities = useCallback(async () => {
    if (!dateRange) return;
    const [startDate, endDate] = dateRange as [Date, Date];
    try {
      const result = await employeeAvailabilityCall.mutateAsync({
        data: {
          businessUnitIds: visibleBusinessUnits,
          onlyShowAvailable: showOnlyAvailable,
          searchString: search,
          start: startDate,
          end: endDate,
        },
      });
      setEmployeeAvailabilityResponse(result);
    } catch (error) {
      console.log('error', error);
    }
  }, [
    dateRange,
    employeeAvailabilityCall,
    visibleBusinessUnits,
    showOnlyAvailable,
    search,
  ]);

  // fetch availabilities when search, visibleBusinessUnits or showOnlyAvailable changes
  useEffect(() => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    if (!currentRangeOption?.forceManualSearch) {
      // only auto-trigger search if not in manual mode
      searchTimeoutRef.current = setTimeout(() => {
        fetchAvailaiblities();
      }, 1000);
    } else {
      // simply clean the previous result
      setEmployeeAvailabilityResponse([]);
    }
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, visibleBusinessUnits, showOnlyAvailable, dateRange]);

  const handleUsersUpdated = async () => {
    fetchAvailaiblities();
    refreshUsersCall.run();
    refetchAbsenceRequests();
  };

  const enrichedEmployeeAvailabilityResponse = useMemo(() => {
    // map in zipCodeArea
    const result: EmployeeWithCounty[] =
      employeeAvailabilityResponse.map(
        (e) =>
          ({
            ...e,
            zipCodeArea: zipCodeAreaFromZipCode(e.postalCode),
            unavailabilityWarning: e.schedule.some((s) => {
              return issueWarning(s);
            }),
          }) as EmployeeWithCounty
      ) ?? [];
    return result;
  }, [employeeAvailabilityResponse, zipCodeAreaFromZipCode]);

  const isSingleDay = useMemo(() => {
    // return true if start and end date are the same day
    if (!dateRange) return false;
    return (
      dateRange[0] &&
      dateRange[1] &&
      dateRange[0].toDateString() === dateRange[1].toDateString()
    );
  }, [dateRange]);

  const getWeelyAmountStatusColor = (day: number, amount: number) => {
    switch (day) {
      case 0: // Monday
        if (amount <= 140) {
          return theme.colors.foreground.error;
        }
        if (amount <= 169) {
          return theme.colors.foreground.warning;
        }
        if (amount >= 170) {
          return theme.colors.foreground.good;
        }
        break;

      case 1: // Tuesday
      case 2: // Wednesday
      case 3: // Thursday
      case 4: // Friday
        if (amount <= 160) {
          return theme.colors.foreground.error;
        }
        if (amount <= 209) {
          return theme.colors.foreground.warning;
        }
        if (amount >= 210) {
          return theme.colors.foreground.good;
        }
        break;

      case 5: // Saturday
      case 6: // Sunday
        if (amount <= 120) {
          return theme.colors.foreground.error;
        }
        if (amount <= 139) {
          return theme.colors.foreground.warning;
        }
        if (amount >= 140) {
          return theme.colors.foreground.good;
        }
        break;

      default:
        return '';
    }
    return '';
  };

  return (
    <Suspense fallback="Laddar...">
      <Container>
        <Actions>
          <StyledButton
            icon={<FontAwesomeIcon icon={faCaretLeft} />}
            iconPlacement="left"
            onClick={() => {
              if (dateRange) {
                setDateRange(currentRangeOption?.prevFn(dateRange));
              }
            }}
          >
            Föregående
          </StyledButton>
          <ContextMenu
            options={rangeOptions.map((ro) => ({
              label: ro.label,
              onClick: () => {
                setCurrentOption(ro.type);
                setDateRange(ro.getDefaultRange());
              },
            }))}
            position="left"
          >
            <StyledButton>
              {currentRangeOption?.label ?? 'Välj spann'}{' '}
              <FontAwesomeIcon icon={faAngleDown} />
            </StyledButton>
          </ContextMenu>
          <StyledButton
            icon={<FontAwesomeIcon icon={faCaretRight} />}
            iconPlacement="right"
            onClick={() => {
              if (dateRange) {
                setDateRange(currentRangeOption?.nextFn(dateRange));
              }
            }}
          >
            Nästa
          </StyledButton>
          <StyledInput
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Filtrera"
            value={search}
            width={300}
          />
          <StyledMultiSelect
            onChange={(units) => setVisibleBusinessUnits(Array.from(units))}
            options={businessUnitOptions}
            placeholder="Välj enhet"
            value={new Set(visibleBusinessUnits)}
          />
          {currentRangeOption?.showShowOnlyAvailableOption && (
            <Checkbox
              checked={showOnlyAvailable}
              onChange={(e) => setShowOnlyAvailable(e.currentTarget.checked)}
            >
              Visa bara tillgängliga
            </Checkbox>
          )}
          {currentRangeOption?.forceManualSearch && (
            <Button
              disabled={employeeAvailabilityCall.status === 'loading'}
              onClick={fetchAvailaiblities}
            >
              Sök manuellt
            </Button>
          )}
          <div>
            <TooltipInfo
              hoverable
              info={
                <InfoGrid columns={['40px', '250px']}>
                  {Object.entries(availabilityStatuses).map(([key, value]) => (
                    <Fragment key={key}>
                      <DateBox
                        colorCode={
                          availabilityStatusColorCodes[
                            key as keyof typeof AvailabilityStatus
                          ]
                        }
                        flex={1}
                      >
                        {Number(key) === AvailabilityStatus.UNAVAILABLE && (
                          <DiagonalLine />
                        )}
                      </DateBox>
                      <span>{value}</span>
                    </Fragment>
                  ))}
                  <DateBox colorCode="grey" flex={1} />
                  <span>Ej tillgänglig för bokning</span>
                  <Box>
                    <DateBoxMeta>
                      <InfoDot />
                    </DateBoxMeta>
                  </Box>
                  <span>Hanterad frånvaro</span>
                  <PaddedDiv>
                    <Badge background={theme.colors.background.selection}>
                      1
                    </Badge>
                  </PaddedDiv>
                  <span>Antal bokade uppdrag</span>
                  <PaddedDiv>
                    <DateBoxMeta>
                      <Badge background={theme.colors.foreground.warning}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </Badge>
                    </DateBoxMeta>
                  </PaddedDiv>
                  <span>Endast tillgänglig för vidaretransport</span>
                  {Object.entries(absenceReasons).map(([key, value]) => (
                    <Fragment key={key}>
                      <PaddedDiv>
                        <AbsenceReasonIcon absenceReason={Number(key)} />
                      </PaddedDiv>
                      <span>{value}</span>
                    </Fragment>
                  ))}
                </InfoGrid>
              }
            />
          </div>
          {employeeAvailabilityCall.status === 'loading' && (
            <FontAwesomeIcon icon={faSpinner} spin />
          )}
          {currentRangeOption?.forceManualSearch && (
            <span>Längre tidsperiod än 1 dag kräver manuellt sök</span>
          )}
          {isSingleDay && (
            <WeeklyAvailability>
              {weeklyAvailabilitiesCall.isLoading && (
                <FontAwesomeIcon icon={faSpinner} spin />
              )}
              {weeklyAvailabilitiesCall.data &&
                weeklyAvailabilitiesCall.data.availableEmployees.map(
                  (amountAvailable, index) => (
                    <WeeklyAvailabilityDay
                      key={index}
                      title={`Antal tillgängliga innevarande vecka på ${weekdays[index]}dag.`}
                    >
                      <div>{weekdays[index]}</div>
                      <WeekDayBox
                        colorCode={getWeelyAmountStatusColor(
                          index,
                          amountAvailable
                        )}
                      >
                        {amountAvailable}
                      </WeekDayBox>
                    </WeeklyAvailabilityDay>
                  )
                )}
            </WeeklyAvailability>
          )}
        </Actions>
        {dateRange && (
          <AvailabilityTable
            employees={enrichedEmployeeAvailabilityResponse}
            endDate={dateRange[1]}
            onUpdate={handleUsersUpdated}
            startDate={dateRange[0]}
          />
        )}
      </Container>
    </Suspense>
  );
};

export default Availability;
