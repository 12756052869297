/** All routes are kept absolute to allow direct navigation to a route, without chaining route-segments together. */
const Routes = {
  index: '/',
  account: {
    confirm: '/account/confirm',
    reconfirm: '/account/reconfirm',
    login: '/account/login',
    profile: '/account/profile',
  },

  purchases: { index: '/inkop', case: '/inkop/:id' },
  extrajobs: { index: '/extrajobb', case: '/extrajobb/:id' },
  homeDeliveries: { index: '/hemleveranser', case: '/hemleveranser/:id' },
  internalDeliveries: {
    index: '/annons',
    case: '/annons/:id',
  },
  internalDeliverySales: {
    index: '/annons-salj',
    case: '/annons-salj/:id',
  },
  facilityToFacility: {
    index: '/butik-till-butik',
    case: '/butik-till-butik/:id',
  },
  extraCostExternalWorkshopOut: {
    index: '/pakostnad-extern-verkstad-ut',
    case: '/pakostnad-extern-verkstad-ut/:id',
  },
  extraCostExternalWorkshopReturn: {
    index: '/pakostnad-extern-verkstad-retur',
    case: '/pakostnad-extern-verkstad-retur/:id',
  },
  facilityExchange: {
    index: '/anlaggningsinbyte',
    case: '/anlaggningsinbyte/:id',
  },
  rmOther: { index: '/rm-other', case: '/rm-other/:id' },
  swamp: { index: '/sumpen', case: '/sumpen/:id' },

  pendingAssignments: {
    index: '/mellanlandningar',
    case: '/mellanlandningar/:id',
  },

  rideUpdates: {
    index: '/uppdateringar',
    case: '/uppdateringar/:id',
  },

  forgot: { index: '/forgot' },
  resetpassword: { index: '/resetpassword' },
  caseAssignmentRequests: { index: '/requests' },

  excelExports: {
    index: '/underlag',
    exports: '/underlag',
    history: '/underlag/historik',
    receipts: '/underlag/kvitton',
    lineItems: '/underlag/kvittorader',
    lineItem: '/underlag/kvittorader/:id',
  },
  employees: {
    index: '/anstallda',
    mobile: '/anstallda/mobil',
    timeReports: '/anstallda/tidrapporter',
    teams: '/anstallda/team',
    availability: '/anstallda/tillganglighet',
    absenceRequests: '/anstallda/tillganglighet/att-hantera',
    drivingPolicyWarnings: '/anstallda/tillganglighet/varningar',
    drivingPolicy: '/anstallda/tillganglighet/kortidpolicy',
    paymentCards: '/anstallda/betalkort',
  },
  companies: { index: '/addressbok' },
  search: { index: '/sok', case: '/sok/:id' },

  map: { index: '/map' },

  test: { index: '/test' },

  appInstall: { index: '/app-install' },

  privacy: { index: '/integritetspolicy' },

  settings: {
    index: '/settings',
    assignmentDurations: '/settings/assignmentDurations',
    businessUnits: '/settings/affarsomraden',
    holidays: '/settings/holidays',
    zipCodeAreas: '/settings/postnummeromraden',
    beta: '/settings/beta',
    standardizedComments: '/settings/kommentarer',
    globalSettings: '/settings/globala-installningar',
    productExpenseTypeMappings: '/settings/produkt-kostnadstyper',
    companyDepartments: '/settings/avdelningar',
  },

  internalDeliveryGroup: {
    // Vy för annons-uppdragen, otilldelade, planerade, utdelade, inkomna från Riddermark
    index: '/annons-v2',
    assignments: {
      index: '/annons-v2/uppdrag',
      case: '/annons-v2/uppdrag/:id',
    },
    // Vy för att hantera inkomna annonsbegäranden/äskningar
    requests: {
      index: '/annons-v2/forfragningar',
      request: '/annons-v2/forfragningar/:id',
    },
    // Vy för att hantera och följa upp budget
    overview: '/annons-v2/overview',
    // Vy för budget-/annonsinställningar
    budget: '/annons-v2/budget',
  },

  tests: { testMessageGenerator: '/tests/testMessageGenerator' },
} as const;

export default Routes;
