import React, { FC, useEffect } from 'react';
import Modal from 'components/Modal';
import {
  CompanyForm,
  companyFormToCompanyModel,
  createCompanyForm,
} from './companyFormConverters';
import styled from 'styled-components';
import Select from 'components/inputs/Select';
import LabelWrap from 'components/inputs/LabelWrap';
import Input from 'components/inputs/Input';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import Checkbox from 'components/inputs/Checkbox';
import { useForm, useWatch } from 'react-hook-form';
import CompanyTransportInput from './CompanyTransportInput';
import SectionHeader from 'components/SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { arraySpreadIf } from 'utils/spreading';
import ColorSelector from 'components/ColorSelector';
import PositionSelector from 'components/map/PositionSelector';
import {
  useCompanyCreateCompany,
  useCompanyGetCompany,
  useCompanyUpdateCompany,
} from 'api/company/company';

const FormWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
`;

const MyLabelWrap = styled(LabelWrap)`
  & > *:last-child {
    width: auto;
  }
`;

const CompanyTransportPrices = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
`;

const CompanyTransportPriceSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface Props {
  companyId?: number;
  onCancel(): void;
  onSaved(): void;
}

const CompanyFormModal: FC<Props> = ({ companyId, onCancel, onSaved }) => {
  const companyCallById = useCompanyGetCompany(
    { companyId: companyId ?? -1 },
    {
      query: {
        enabled: companyId !== undefined,
      },
    }
  );
  const { register, handleSubmit, control, formState, reset, watch, setValue } =
    useForm<CompanyForm>({
      defaultValues: createCompanyForm(companyCallById.data),
    });

  useEffect(() => {
    if (companyCallById.data !== undefined) {
      reset(createCompanyForm(companyCallById.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCallById.data]);

  const watched = watch();

  useEffect(() => {
    console.log('change', watched.isRiddermark);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watched.isRiddermark]);

  const nameFieldValue = useWatch({ control, name: 'name' });

  const { isDirty } = formState;

  const createMode = companyId === undefined;

  const createCall = useCompanyCreateCompany();
  const updateCall = useCompanyUpdateCompany();

  const mkInput = (
    name: keyof CompanyForm,
    label: string,
    type?: React.HTMLInputTypeAttribute
  ) => (
    <MyLabelWrap label={label}>
      <Input type={type} {...register(name)} />
    </MyLabelWrap>
  );

  const mkCheckbox = (name: keyof CompanyForm, label: string) => (
    <MyLabelWrap>
      <Checkbox {...register(name)}>{label}</Checkbox>
    </MyLabelWrap>
  );

  const handleSaveClick = async (form: CompanyForm) => {
    try {
      if (createMode) {
        await createCall.mutateAsync({
          data: companyFormToCompanyModel(form),
        });
        onSaved();
      } else {
        if (companyCallById.data === undefined) return;
        await updateCall.mutateAsync({
          data: companyFormToCompanyModel(form, companyCallById.data),
        });

        onSaved();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleCancelClick = () => {
    onCancel();
  };

  const saveStatus = createMode ? createCall.status : updateCall.status;
  const renderContent = () => {
    switch (saveStatus) {
      case 'idle':
      case 'success':
      case 'error':
        return (
          <>
            <FormWrap>
              {mkCheckbox('active', 'Aktiv')}
              {companyCallById.data?.companyID !== undefined && (
                <MyLabelWrap
                  label={
                    companyCallById.data.latitude !== null
                      ? `Position: ${companyCallById.data.latitude ?? ''}, ${companyCallById.data.longitude ?? ''}`
                      : 'Position saknas'
                  }
                >
                  <PositionSelector
                    companyId={companyCallById.data?.companyID}
                    longitude={companyCallById.data?.longitude}
                    latitude={companyCallById.data?.latitude}
                    address={`${watched.address}, ${watched.zip} ${watched.city}`}
                    onUpdated={async () => {
                      // onSaved();
                      await companyCallById.refetch();
                    }}
                    title={`Välj position för ${nameFieldValue}`}
                  />
                </MyLabelWrap>
              )}

              {mkInput('name', 'Namn')}
              {mkInput('address', 'Adress')}
              {mkInput('zip', 'Postkod')}
              {mkInput('city', 'Stad')}

              <MyLabelWrap label="Riddermark">
                <Select
                  {...register('isRiddermark', {
                    value: watched.isRiddermark,
                    onChange: (e) => {
                      setValue('isRiddermark', e.target.value === 'true');
                    },
                  })}
                >
                  <option value="false">Nej</option>
                  <option value="true">Ja</option>
                </Select>
              </MyLabelWrap>

              {mkInput('rideMappingID', 'Ride-ID', 'number')}

              <MyLabelWrap label="Kortnamn (max 5 tecken)">
                <Input
                  maxLength={5}
                  type="text"
                  {...register('shortName', {
                    setValueAs: (value: string) => value.slice(0, 5),
                  })}
                />
              </MyLabelWrap>

              {mkCheckbox(
                'autoApproveAssignment',
                'Godkänn uppdrag automatiskt'
              )}

              {mkCheckbox('isLogisticsCenter', 'Är Logistik-address')}
              {mkCheckbox('isExternalWorkshop', 'Är extern verkstad')}

              {mkCheckbox('showInApp', 'Visa i appen')}
              {mkCheckbox(
                'showInInternalDeliveryOverview',
                'Visa i Annons-överblicken'
              )}
              <MyLabelWrap>
                <ColorSelector
                  label="Listfärg: "
                  color={watch('color')}
                  onChange={(color) => {
                    setValue('color', color, { shouldDirty: true });
                  }}
                />
              </MyLabelWrap>
            </FormWrap>

            {companyCallById.data && (
              <CompanyTransportPrices>
                {/* <CompanyTransportPriceSection>
                  <SectionHeader>
                    Fasta priser till {nameFieldValue}
                  </SectionHeader>
                  <CompanyTransportInput
                    companyId={company.companyID}
                    control={control}
                    name="pricesToHere"
                  />
                </CompanyTransportPriceSection> */}

                <CompanyTransportPriceSection>
                  <SectionHeader>
                    Fasta tider för uppdrag från {nameFieldValue}
                  </SectionHeader>
                  <CompanyTransportInput
                    companyId={companyCallById.data.companyID}
                    control={control}
                    name="pricesFromHere"
                    register={register}
                  />
                </CompanyTransportPriceSection>
              </CompanyTransportPrices>
            )}
          </>
        );

      case 'loading':
      default:
        return <LoadingSpinner>Sparar...</LoadingSpinner>;
    }
  };

  return (
    <Modal
      buttons={
        saveStatus === 'loading'
          ? []
          : [
              {
                label: createMode ? 'Skapa adress' : 'Uppdatera adress',
                icon: <FontAwesomeIcon icon={faSave} />,
                disabled: !isDirty,
                onClick: handleSubmit(handleSaveClick),
              },
              {
                label: 'Avbryt',
                onClick: handleCancelClick,
              },
              ...arraySpreadIf(isDirty, {
                label: 'Återställ',
                icon: <FontAwesomeIcon icon={faArrowRotateLeft} />,
                onClick: () => reset(),
              }),
            ]
      }
      onClose={handleCancelClick}
      title={createMode ? 'Skapa adress' : 'Redigera adress'}
    >
      {renderContent()}
    </Modal>
  );
};

export default CompanyFormModal;
