import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  CustomMarker,
  MapIcons,
  useGoogleMapsContext,
} from './GoogleMapsContext';
import Modal from 'components/Modal';
import {
  useCompanyDeletePointForCompany,
  useCompanyGetPointByAddress,
  useCompanyUpdatePointForCompany,
} from 'api/company/company';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import Button from 'components/inputs/Button';
import { swedenCenterCoords } from 'constants/AppConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const MapContainer = styled.div<{ height: number }>`
  height: ${({ height }) => (height === -1 ? '100%' : `${height}px`)};
  width: ${({ height }) => (height === -1 ? '100%' : `${height}px`)};
  user-select: none;
  cursor: pointer;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-bottom: 10px;
`;

const clearLatLongVal = (newVal: string) => {
  let val = newVal.replace(/[^0-9.]/g, '');
  // remove multiple dots
  val = val.replace(/\.{2,}/g, '.');
  return val;
};

interface Props {
  initialCenter?: { latitude: number; longitude: number };
  address: string;
  companyId: number;
  containerHeight: number;
  zoomControl?: boolean;
  onClose(): void;
  onUpdated(): void;
  title?: string;
}

const PositionSelectorModal: React.FC<Props> = ({
  initialCenter,
  companyId,
  address,
  containerHeight,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  zoomControl,
  onClose,
  onUpdated,
  title,
}) => {
  const updateCompanyPositionCall = useCompanyUpdatePointForCompany();
  const deleteCompanyPositionCall = useCompanyDeletePointForCompany();
  const {
    scriptLoaded,
    createMap,
    fitBounds,
    addCustomMarkers,
    clearMarkers,
    map,
  } = useGoogleMapsContext();
  const getPositionByAddressCall = useCompanyGetPointByAddress();
  const [currentLat, setCurrentLat] = useState<string>(
    (initialCenter ?? swedenCenterCoords).latitude.toString()
  );
  const [currentLong, setCurrentLong] = useState<string>(
    (initialCenter ?? swedenCenterCoords).longitude.toString()
  );

  const hasChanged = useMemo(
    () =>
      initialCenter &&
      (currentLat !== initialCenter.latitude.toString() ||
        currentLong !== initialCenter.longitude.toString()),
    [initialCenter, currentLat, currentLong]
  );

  useEffect(() => {
    const getPositionByAddressAsync = async () => {
      try {
        const result = await getPositionByAddressCall.mutateAsync({
          params: {
            address,
          },
        });
        if (result) {
          //   setCurrentPosition(result);
          setCurrentLat(result.latitude.toString());
          setCurrentLong(result.longitude.toString());
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (address && !initialCenter) {
      getPositionByAddressAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const mapRef = useRef<google.maps.Map | undefined>(undefined);

  const handleClose = () => {
    onClose();
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    clearMarkers();
    // addMarkers([posMarker];
    addCustomMarkers(
      [
        {
          position: {
            lat: parseFloat(currentLat),
            lng: parseFloat(currentLong),
          },
          label: `${currentLat}, ${currentLong}`,
          icon: MapIcons.locationDot,

          //   link: `sok/${l.caseId}`,
          //   timestamp: l.timestamp,
        } as CustomMarker,
      ],
      false
    );
    fitBounds();
    mapRef.current?.setZoom(1);
  }, [
    addCustomMarkers,
    clearMarkers,
    currentLat,
    currentLong,
    fitBounds,
    initialCenter,
    map,
  ]);

  const handleNewLat = (newLat: string) => {
    // clear of any non-numeric characters, and only keep one dot
    const val = clearLatLongVal(newLat);
    setCurrentLat(val);
  };
  const handleNewLong = (val: string) => {
    clearLatLongVal(val);
    setCurrentLong(val);
  };

  useEffect(() => {
    if (
      scriptLoaded &&
      ref.current &&
      currentLat !== undefined &&
      currentLong !== undefined
    ) {
      mapRef.current = createMap(
        ref.current,
        8,
        new google.maps.LatLng(parseFloat(currentLat), parseFloat(currentLong)),
        zoomControl
      );

      mapRef.current?.addListener(
        'click',
        (mapsMouseEvent: google.maps.MapMouseEvent) => {
          const lat = mapsMouseEvent.latLng?.lat();
          const lng = mapsMouseEvent.latLng?.lng();
          if (lat && lng) {
            setCurrentLat(lat.toString());
            setCurrentLong(lng.toString());
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded, ref, createMap]);

  useEffect(() => {
    // fitBounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCenter]);

  return scriptLoaded ? (
    <Modal
      closeOnOutsideClick
      onClose={handleClose}
      title={title ?? 'Välj position'}
    >
      <Horizontal>
        <LabelWrap label="Lat">
          <Input
            placeholder="Latitud"
            value={currentLat}
            onChange={(eve) => {
              handleNewLat(eve.target.value);
            }}
          />
        </LabelWrap>
        <LabelWrap label="Long">
          <Input
            placeholder="Longitud"
            value={currentLong}
            onChange={(eve) => {
              handleNewLong(eve.target.value);
            }}
          />
        </LabelWrap>
        <LabelWrap>
          <Button
            disabled={updateCompanyPositionCall.isLoading}
            loading={updateCompanyPositionCall.isLoading}
            onClick={async () => {
              await updateCompanyPositionCall.mutateAsync({
                data: {
                  latitude: parseFloat(currentLat),
                  longitude: parseFloat(currentLong),
                },
                params: {
                  companyId,
                },
              });
              onUpdated();
              onClose();
            }}
          >
            Spara
          </Button>
        </LabelWrap>
        {hasChanged && initialCenter && (
          <LabelWrap>
            <Button onClick={onClose}>Avbryt</Button>
          </LabelWrap>
        )}
        {initialCenter && (
          <LabelWrap>
            <Button
              onClick={async () => {
                await deleteCompanyPositionCall.mutateAsync({
                  params: { companyId },
                });
                onUpdated();
                onClose();
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </LabelWrap>
        )}
      </Horizontal>
      <MapContainer height={containerHeight} ref={ref} />
    </Modal>
  ) : (
    <h1>Laddar...</h1>
  );
};

export default PositionSelectorModal;
